const es = {
  translation: {
    orderSuccessView: {
      status: {
        error: 'Error',
        cancelled: 'Orden cancelada',
        archived: '¡Gracias y disfruta!',
        inprogress: 'Orden en progreso',
        new: 'Orden enviada',
        ready: '¡Lo tengo!',
      },
      error: 'Error al procesar la orden',
      cancelled: 'Orden cancelada',
      newShort: 'Enviada',
      preOrdered: 'Orden enviada',
      orderCancelledUnableToFullfil: 'no pudo aceptar tu orden.',
      orderSentTo: 'La orden ha sido enviada a ',
      readyDesc: 'Recoge tu orden en el mostrador',
      orderNumber: 'Número de orden',
      deliveryEst: 'Tiempo estimado de entrega',
      yourOrderNumberIs: 'Tu número de orden es #',
      cancelledShort: 'Cancelada',
      orderCancelled: 'Tu orden fue enviada, pero lamentablemente ',
      archivedShort: 'Completada',
      ready: '¡Tu orden está lista!',
      orderCancelledRefunded: 'Tu dinero está siendo reembolsado',
      archived: 'Orden completada',
      errorShort: 'Error',
      new: '¡Tu orden se realizó con éxito!',
      orderInProgress: 'Estamos trabajando en tu orden - ',
      saveReceipt: 'Guardar recibo',
      inprogress: 'Tu orden estará lista en',
    },
    orderView: {
      dinein: 'Comer aquí',
      notePlaceholder: 'Agregar solicitudes especiales, alergias, etc...',
      reccomendations: 'Recomendaciones',
      addButton: 'Agregar',
      takeaway: 'Para llevar',
      preorder: 'Preordenar a la habitación',
      roomorder: 'Orden a la habitación',
      tableorder: 'Orden a la mesa',
      roomOrderPlaceholder: 'Número de habitación',
      tableOrderPlaceholder: 'Número de mesa',
      delivery: 'Entrega',
      consumerName: 'Nombre (opcional)',
      consumerNameForced: 'Por favor, escribe tu nombre...',
      gotoCheckout: 'Ir a la caja',
      title: 'Tu orden',
      alcoholError: 'Lo sentimos, la ley finlandesa no permite alcohol para llevar 😔',
      couponCode: 'Código de cupón',
      pickTime: 'Elegir una hora',
      today: 'Hoy',
      tomorrow: 'Mañana',
      required: 'Requerido',
    },
    paymentView: {
      phoneNumber: 'Número de teléfono',
      useCoinDesc: 'Utiliza tus {{symbol}} Coins para pagar tu orden',
      payButtonLoading: 'Pagando...',
      errorNoPhone: 'Por favor, ingresa tu número de teléfono',
      balance: 'Saldo',
      marketingConsent:
        'Autorizo a {{company}} a contactarme con ofertas especiales y otras comunicaciones de marketing.',
      tosConsent: 'Al ingresar mi número de teléfono, acepto los <1>términos de servicio</1>',
      useCoin: 'Usar coins',
      payButton: 'Confirmar orden',
      getCoin: 'Obtener coins',
      payNow: 'Pagar ahora',
      payNowDesc: 'Pagar con tarjeta / Apple Pay / Google Pay',
      smartum: 'Smartum',
      title: 'Pago',
      getCoinDesc: 'Obtén el {{percentage}}% del valor de tu orden como {{symbol}} Coin',
      alcoholPopUptitle: 'Artículos con restricciones de edad',
      alcoholPopUpAgeRestrict:
        'Estás a punto de comprar un producto con restricciones de edad. Prepárate para mostrar tu identificación al personal',
      alcoholPopUpNoRefund:
        'No reembolsaremos tu compra en caso de que no puedas mostrar tu identificación al personal.',
      alcoholPopUpConfirm: 'Por favor, confirma que tienes permiso legal para comprar estos artículos.',
      alcoholPopUpButtonYesAllowed: 'Sí, tengo permiso',
      alcoholPopUpButtonCancel: 'Cancelar',
      paying: 'Pagando...',
      confirmPayment: 'Confirmar pago',
    },
    menuView: {
      title: 'Menú',
      viewOrder: 'Ver orden',
      restaurantClosed: 'Cerrado hoy',
      addToOrder: 'Agregar a la orden',
    },
    menuItemView: {
      preOrderChip: 'Solo preorden',
      preOrderExplanation:
        'Esta comida solo se puede preordenar. Puedes seleccionar la hora de entrega durante la confirmación de la orden',
      extras: 'Extras:',
      alcoholWarning:
        'Ten en cuenta que el alcohol solo se puede vender a personas mayores de 18 años. Prepárate para mostrar tu identificación.',
    },
    general: {
      coins: 'Coins',
      coin: 'Coin',
      update: {
        updateTitle: 'Actualización necesaria',
        updateText:
          'Estás utilizando una versión anterior de Mynt, por favor presiona el botón de abajo para actualizar',
        updateButton: 'Actualizar',
      },
      downloadApp: {
        downloadTitle: 'Disponible solo en Mynt',
        downloadText: 'Para recibir esta recompensa, debes usar la aplicación Mynt 🙂',
        downloadButton: 'Descargar la aplicación',
      },
    },
    couponView: {
      checkingCode: 'Verificando tu código 🤟',
      errorTitle: 'Oh no 🤨',
      errorText1: 'Algo salió mal, tal vez ya recibiste esta oferta',
      errorText2: 'Si no lo has hecho, reinicia la aplicación Mynt e intenta nuevamente',
      successTitle: '¡Acabas de obtener Myntd!',
      explainerText:
        'Cuando ordenas tu comida a través de Mynt, puedes recibir un 10% de reembolso en forma de coins. Acabamos de mejorar la forma en que gastas tu dinero',
      openMenuButton: 'Abrir menú',
      closeButton: 'Cerrar',
      td: {
        youGot: '¡Has recibido',
        coinName: 'TD Coins!',
        bannerText: 'Akseli Herlevi & Iman Gharagozlu',
      },
    },
  },
  Any: 'Cualquier',
  'Not empty': 'No vacío',
  '1-4 digits': '1-4 dígitos',
  'One digit and numbers': 'Un dígito y números',
  'Two digits and numbers': 'Dos dígitos y números',
}

export default es
