import React, { useEffect } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 * @param {*} ref - The element to monitor if clicks are outside
 * @param {*} functionCall  - The function to call if clicked outside of the element
 */
export function OutsideClickHook(ref, functionCall) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        functionCall() // Function to call when clicked
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
