import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration'
import { useCartContext } from '../../Contexts/CartContext'

const UpdateModal = () => {
  const { t, i18n } = useTranslation()
  const [worker, setWorker] = useState()
  const [needUpdate, setNeedUpdate] = useState(false)
  const cartContext = useCartContext()
  let handleUpdate = useCallback(() => {
    setNeedUpdate(false)
    worker.waiting?.postMessage({ type: 'SKIP_WAITING' })
    worker.waiting?.addEventListener('statechange', e => {
      if (e?.target?.state === 'activated') {
        const url = {
          "https://pepi-ordering-test.web.app": "https://pepi-ordering-test.web.app/",
          "https://mynt-ordering-internal-test.web.app": "https://mynt-ordering-internal-test.web.app/",
          "https://pepi-ordering.web.app": "https://ordering.myntapp.io/",
        }?.[process.env.PUBLIC_URL] || "https://ordering.myntapp.io/"
        caches.delete(`workbox-precache-v2-${url}`).then(() => {
          window.location.reload()
        }).catch((error) => {
          window.location.reload()
        })
      }
    })
  }, [worker])

  useEffect(() => {
    console.log('subscribed to app update.')
    serviceWorkerRegistration.register({
      onUpdate: worker => {
        setWorker(worker)
        setNeedUpdate(true)
      },
    })
    return () => {}
  }, [])

  useEffect(() => {
    let pid
    if (needUpdate) {
      console.log('updating app content.')
      pid = setInterval(() => {
        if (cartContext.inSession) {
        } else {
          handleUpdate()
        }
      }, 5000)
    }

    return () => {
      if (pid) {
        clearInterval(pid)
      }
    }
  }, [needUpdate, handleUpdate, cartContext.inSession])

  // if (!needUpdate) {
  //     return null;
  // }

  return (
    <></>
    // <div className='updatemodal-background'>
    //     <div className='update-modal'>
    //         <h3>{t("general.update.updateTitle")}</h3>
    //         <p>{t("general.update.updateText")}</p>
    //         <button onClick={handleUpdate}>{t("general.update.updateButton")}</button>
    //     </div>
    // </div>
  )
}

export default UpdateModal
