import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CartProvider } from './Contexts/CartContext'
import MenuView from './Views/MenuView'
import OrderView from './Views/OrderView'
import PaymentView from './Views/PaymentView/PaymentView'
import PaymentSuccessView from './Views/PaymentSuccessView'
import PaymentCompletedView from './Views/PaymentCompletedView'
import ErrorView from './Views/ErrorView'
import PairingView from './Views/PairingView'
import RedeemCode from './Views/RedeemCodeView'
import { AuthProvider } from './Contexts/AuthContext'
import './i18n'
import UpdateModal from './Components/UpdateModal/UpdateModal'
import { ErrorProvider } from './Contexts/ErrorContext'
import OrderStatusView from './Views/OrderStatusView/OrderStatusView'
import { SubMenuProvider } from './Contexts/SubMenuContext'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const App = () => {
  const { i18n } = useTranslation()

  useEffect(() => {
    document.dir = i18n.dir()
  }, [i18n.language])

  return (
    <BrowserRouter>
      <ErrorProvider>
        <SubMenuProvider>
          <AuthProvider>
            <CartProvider>
              <Routes>
                <Route path="/" element={<MenuView />} />
                <Route path="/order" element={<OrderView />} />
                <Route path="/payment" element={<PaymentView />} />
                <Route path="/payment-success" element={<PaymentSuccessView />} />
                <Route path="/payment-completed" element={<PaymentCompletedView />} />
                <Route path="/order-status/:restaurantId" element={<OrderStatusView />} />

                <Route path="/redeem/:code" element={<RedeemCode />} />

                <Route path="/pair-device" element={<PairingView />} />
                <Route path="/error" element={<ErrorView />} />
                <Route path="*" element={<MenuView />} />
              </Routes>
              <UpdateModal />
            </CartProvider>
          </AuthProvider>
        </SubMenuProvider>
      </ErrorProvider>
    </BrowserRouter>
  )
}

export default App
