import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'

import PageLayout from '../Components/Layout/PageLayout'
import { redeemCoupon } from '../Api/CouponApi'
import { useAuthContext } from '../Contexts/AuthContext'
import Carousel from '../Components/Carousel/Carousel'
import { onCallBack } from '../Utils/FlutterChannel'
import * as Rudder from '../Utils/Rudder'
import DownloadAppModal from '../Components/DownloadAppModal/DownloadAppModal'
import { useTranslation } from 'react-i18next'

const RedeemCodeView = () => {
  const { loggedIn } = useAuthContext()
  const { code } = useParams()
  const [errorMessage, setErrorMessage] = useState()
  const [payload, setPayload] = useState()
  const [loading, setLoading] = useState(true)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (loggedIn) {
      setLoading(true)
      Rudder.track('start_redeem_coupon')
      redeemCoupon(code)
        .then(resp => {
          const { success, data, error } = resp
          console.log('data: ', data)
          if (success) {
            setPayload(data)
            Rudder.track('redeem_coupon_succeed')
          } else {
            Rudder.track('redeem_coupon_error')
            setErrorMessage(error)
          }
        })
        .catch(error => {
          console.log('error: ', error)
          setErrorMessage(error.message)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      console.log('not logged in')
    }
  }, [loggedIn])

  console.log(payload?.coupon)

  return (
    <>
      {!loggedIn && <DownloadAppModal />}
      <div className="mynt-container">
        <div className="header-wrapper">
          <div className="header">
            <Carousel />
          </div>
          <div className="sub-header">
            <p>{t('couponView.td.bannerText')}</p>
          </div>
        </div>

        <div className="body">
          {loading ? (
            <h1>{t('couponView.checkingCode')}</h1>
          ) : (
            <>
              <h1>{errorMessage ? t('couponView.errorTitle') : t('couponView.successTitle')}</h1>

              {errorMessage && (
                <div className="error">
                  <p>{t('couponView.errorText1')}</p>
                  <p>{t('couponView.errorText2')}</p>
                </div>
              )}
              <div className="message">
                <div className={errorMessage ? '' : 'highlight'}>
                  {errorMessage
                    ? errorMessage
                    : t('couponView.td.youGot') + ` ${payload?.coupon?.rewardAmount} ` + t('couponView.td.coinName')}
                </div>

                {!errorMessage && (
                  <div className="explainer">
                    <p> {t('couponView.explainerText')}</p>
                    <h2>
                      Eat, Earn, <b>Repeat.</b>
                    </h2>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="footer">
          <div style={{ width: '90%', margin: '0 auto' }}>
            {!loading && payload?.token?.symbol && (
              <btn
                style={{ cursor: 'pointer' }}
                className="btn-open-menu"
                onClick={() => {
                  if (payload?.token?.symbol) {
                    onCallBack({
                      action: 'open_token',
                      data: {
                        id: payload?.coupon?.tokenId,
                        symbol: payload?.token?.symbol,
                      },
                    })
                  }
                }}
              >
                {t('couponView.openMenuButton')}
              </btn>
            )}
            <btn
              style={{ cursor: 'pointer' }}
              className="btn-close"
              onClick={() => {
                onCallBack({
                  action: 'back',
                  data: {},
                })
              }}
            >
              {t('couponView.closeButton')}
            </btn>
          </div>
        </div>
      </div>
    </>
  )
}

export default RedeemCodeView
