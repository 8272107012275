import React, { useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import IsiPhone from '../../Utils/IsIphone'
import { IconCoinSmall } from '../Icons/Icons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useSubmenuContext } from '../../Contexts/SubMenuContext'
import CalculateAvailability, { returnIsAvailableNow } from '../../Utils/CalculateAvailability'
import { ToLocalizedString } from '../../Utils/ToLocalizedString'
import { useAuthContext } from '../../Contexts/AuthContext'
import useCurrency from '../../Hooks/useCurrency'

const MenuItemView = ({ item, onChange, allSaleTo, returnItembyId }) => {
  const [extras, setExtras] = React.useState([])
  const [options, setOptions] = React.useState([])
  const [isAvailableNow, setIsAvailableNow] = React.useState(true)
  const { withCurrencySymbol } = useCurrency()
  const { t } = useTranslation()

  const { shouldAcceptPreOrder } = useSubmenuContext()

  React.useEffect(() => {
    onChange(extras, options)
  }, [extras, options])

  useEffect(() => {
    const filteredOptions = item.options.filter(o => o.optionName && o.optionList && o.optionList.length > 0)
    if (filteredOptions.length > 0) {
      for (let i = 0; i < filteredOptions.length; i++) {
        let optionListToAdd = []
        const optionChoiceLimit = Number(filteredOptions[i]?.optionChoiceLimit || 1)
        if (optionChoiceLimit === 1) {
          optionListToAdd.push(filteredOptions[i]?.optionList?.[0])
        }

        let optionAddJson = {
          optionName: filteredOptions[i].optionName,
          optionList: optionListToAdd,
          translations: filteredOptions[i]?.translations,
        }
        let newOptions = options
        newOptions.push(optionAddJson)
        setOptions(newOptions)
      }
    }

    let avail = CalculateAvailability(item.specialAvailability)
    setIsAvailableNow(avail)
  }, [])

  return (
    <>
      <div className={IsiPhone() ? 'popup-item ios-margin' : 'popup-item'}>
        {/* Disabled for now since we dont have this in CMS, lets revisit this in a design revision  <div className='item-props'>
          <br />
          <br />
           <div className='prop'>
            <div className='icon'>
              <img src='/img/ic1.png' alt='' />
            </div>
            <div className='text'>No lacoste</div>
          </div>
          <div className='prop'>
            <div className='icon'>
              <img src='/img/ic2.png' alt='' />
            </div>
            <div className='text'>Low lacoste</div>
          </div>
          <div className='prop'>
            <div className='icon'>
              <img src='/img/ic3.png' alt='' />
            </div>
            <div className='text'>Gluten free</div>
          </div>
          <div className='prop'>
            <div className='icon'>
              <img src='/img/ic4.png' alt='' />
            </div>
            <div className='text'>Lacoste free</div>
          </div>
        </div>*/}

        <div className="item-img">
          <img src={item.image} alt="" />
        </div>
        <div className="item-name">
          <h3 className="headline-1 name">{ToLocalizedString(item, 'title')}</h3>
          {item?.rewardingCoins && (
            <a className="coin-bonus md">
              <IconCoinSmall /> <span>+3</span>
            </a>
          )}
        </div>
        {item.isAlcohol && <div className="alcohol-warning">{t('menuItemView.alcoholWarning')}</div>}

        <div className="item-price">
          {withCurrencySymbol(allSaleTo !== null && typeof allSaleTo !== 'undefined' ? allSaleTo : item.price)}
        </div>
        <div className="item-desc">{ToLocalizedString(item, 'description')}</div>
        {!isAvailableNow && shouldAcceptPreOrder && (
          <div className="pre-order">{t('menuItemView.preOrderExplanation')}</div>
        )}
        {item.options
          .filter(o => o.optionName && o.optionList && o.optionList.length > 0)
          .map((option, optionIndex) => (
            <div className="item-options" key={optionIndex}>
              <div className="option-title">{ToLocalizedString(option, 'optionName')}</div>
              {option.optionChoiceLimit > 1 && (
                <div className="info-box">
                  You can choose{' '}
                  {option.optionChoiceLimit > 100 ? 'any' : <div className="number">{option.optionChoiceLimit}</div>}{' '}
                  options
                </div>
              )}

              <div className="radio-list">
                {(option.optionList || []).map((optionListItem, optionListItemIndex) => {
                  if (optionListItem.name.trim().length === 0) {
                    return
                  }
                  const _currentOptionIndex = options.findIndex(o => o.optionName === option.optionName)

                  let checkboxCss = 'checkbox'
                  if (
                    option.optionChoiceLimit > 1 &&
                    option.optionChoiceLimit == options[_currentOptionIndex]?.optionList.length
                  ) {
                    checkboxCss += ' disabled'
                  }

                  return (
                    <div className={option.optionChoiceLimit > 1 ? checkboxCss : 'radio'} key={optionListItemIndex}>
                      <input
                        type={option.optionChoiceLimit > 1 ? 'checkbox' : 'radio'}
                        name={`option-${optionIndex}`}
                        defaultChecked={optionListItemIndex == 0 && Number(option.optionChoiceLimit || 1) == 1}
                        onChange={$event => {
                          const _newOptions = [...options]
                          if ($event.target.checked) {
                            if (_currentOptionIndex >= 0) {
                              if (option.optionChoiceLimit > 1) {
                                if (option.optionChoiceLimit == _newOptions[_currentOptionIndex].optionList.length) {
                                  $event.target.checked = false
                                  return
                                }
                                _newOptions[_currentOptionIndex].optionList.push(optionListItem)
                              } else {
                                _newOptions[_currentOptionIndex] = {
                                  optionName: option.optionName,
                                  optionList: [
                                    // ...(_currentOption?.optionList || []),
                                    optionListItem,
                                  ],
                                  translations: option?.translations,
                                }
                              }
                            }
                            setOptions([..._newOptions])
                          } else {
                            if (_currentOptionIndex >= 0) {
                              _newOptions[_currentOptionIndex] = {
                                optionName: option.optionName,
                                optionList: [
                                  ...(_newOptions[_currentOptionIndex]?.optionList || []).filter(
                                    o => o.name !== optionListItem.name
                                  ),
                                ],
                                translations: option?.translations,
                              }
                            }
                            setOptions([..._newOptions])
                          }
                        }}
                        id={`option-${optionIndex}-${optionListItemIndex}`}
                      />
                      <label htmlFor={`option-${optionIndex}-${optionListItemIndex}`}>
                        {ToLocalizedString(optionListItem, 'name')}{' '}
                        {optionListItem?.price > 0
                          ? `(+ ${withCurrencySymbol(
                              allSaleTo !== null && typeof allSaleTo !== 'undefined' ? allSaleTo : optionListItem.price
                            )})`
                          : ''}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          ))}
        <div className="item-options" style={{ display: item.extras.length > 0 ? 'block' : 'none' }}>
          <div className="option-title">{t('menuItemView.extras')}</div>
          <div className="radio-list">
            {item.extras.map((optionListItem, optionListItemIndex) => {
              if (optionListItem.type == 'otherItem' || optionListItem.type == 'menuItem') {
                let itemData = returnItembyId(optionListItem.id)

                const hiddenTime = itemData?.hiddenTime ? itemData.hiddenTime : 0
                const hiddenFrom = itemData?.hiddenFrom
                if (hiddenTime === -1) {
                  return // This option hidden
                } else if (hiddenTime === 0) {
                } else {
                  if (hiddenFrom) {
                    if (moment.unix(hiddenFrom).add(hiddenTime, 'hour').isSameOrBefore(moment())) {
                      // not hidden
                    } else {
                      return
                    }
                  } else {
                    return
                  }
                }

                if (false) {
                  //if(itemData.specialAvailability) {

                  // Override for now since scandic will always allow you to do this
                  let avail = returnIsAvailableNow(itemData.specialAvailability)

                  if (!avail) {
                    return
                  }
                  console.log('AVAIL: ', avail)
                }

                return (
                  <div className="checkbox" key={optionListItemIndex}>
                    <input
                      type="checkbox"
                      name="extra"
                      onChange={$event => {
                        if ($event.target.checked) {
                          setExtras([...extras, Object.assign(optionListItem, returnItembyId(optionListItem.id))])
                        } else {
                          setExtras([...extras.filter(o => o.id !== optionListItem.id)])
                        }
                      }}
                      checked={extras.filter(o => o.id === optionListItem.id).length > 0}
                      id={`extra-${optionListItemIndex}`}
                    />
                    <label htmlFor={`extra-${optionListItemIndex}`}>
                      {ToLocalizedString(itemData, 'title')}{' '}
                      {itemData?.price > 0
                        ? `(+ ${withCurrencySymbol(
                            allSaleTo !== null && typeof allSaleTo !== 'undefined' ? allSaleTo : itemData.price
                          )})`
                        : ''}
                    </label>
                  </div>
                )
              }

              return (
                <div className="checkbox" key={optionListItemIndex}>
                  <input
                    type="checkbox"
                    name="extra"
                    onChange={$event => {
                      if ($event.target.checked) {
                        setExtras([...extras, optionListItem])
                      } else {
                        setExtras([...extras.filter(o => o.name !== optionListItem.name)])
                      }
                    }}
                    checked={extras.filter(o => o.name === optionListItem.name).length > 0}
                    id={`extra-${optionListItemIndex}`}
                  />
                  <label htmlFor={`extra-${optionListItemIndex}`}>
                    {ToLocalizedString(optionListItem, 'name')}{' '}
                    {optionListItem?.price > 0
                      ? `(+ ${withCurrencySymbol(
                          allSaleTo !== null && typeof allSaleTo !== 'undefined' ? allSaleTo : optionListItem.price
                        )})`
                      : ''}
                  </label>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default MenuItemView
