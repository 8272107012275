import React, { useRef } from 'react'
import { OutsideClickHook } from '../../Hooks/OutsideClick'
import { IconTrash } from '../Icons/Icons'

const CartAmount = (props, { onChange }) => {
  const [show, setShow] = React.useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const [value, setValue] = React.useState(props.quantity)
  const clickRef = useRef(null)

  const onSetValue = React.useCallback(
    newValue => {
      setValue(newValue)
    },
    [setValue, onChange]
  )

  let hideInputAmount = () => {
    setShow(false)
    setShowDelete(false)
  }

  let reduceAmount = () => {
    console.log('reduce')
    if (value > 1) {
      onSetValue(value - 1)
      props.fnAdjustAmount(props.index, value - 1)
    } else {
      setShowDelete(true)
    }
  }

  let increaseAmount = () => {
    setShowDelete(false)
    onSetValue(value + 1)
    props.fnAdjustAmount(props.index, value + 1)
  }
  OutsideClickHook(clickRef, hideInputAmount)

  return (
    <div className={show ? 'input-amount show' : 'input-amount'} onClick={() => setShow(true)} ref={clickRef}>
      {showDelete ? (
        <div className="remove" onClick={() => props.remove()}>
          <IconTrash />{' '}
        </div>
      ) : null}
      <a className="btn-adjust" onClick={() => reduceAmount()}>
        <i className="fa-solid fa-minus"></i>
      </a>
      <input type="number" name="" id="" value={value} readOnly={true} />
      <a className="btn-adjust" onClick={() => increaseAmount()}>
        <i className="fa-solid fa-plus"></i>
      </a>
    </div>
  )
}

export default CartAmount
