const et = {
  translation: {
    orderSuccessView: {
      status: {
        error: 'Viga',
        cancelled: 'Tellimus on tühistatud',
        archived: 'Aitäh ja head nautimist!',
        inprogress: 'Tellimus on töös',
        new: 'Tellimus saadetud',
        ready: 'Sain selle kätte!',
      },
      error: 'Tellimuse töötlemine ebaõnnestus',
      cancelled: 'Tellimus on tühistatud',
      newShort: 'Saadetud',
      preOrdered: 'Tellimus saadetud',
      orderCancelledUnableToFullfil: 'ei suutnud teie tellimust vastu võtta.',
      orderSentTo: 'Tellimus on saadetud ',
      readyDesc: 'Võta oma tellimus letist',
      orderNumber: 'Tellimuse number',
      deliveryEst: 'Eeldatav valmimisaeg',
      yourOrderNumberIs: 'Sinu tellimuse number on #',
      cancelledShort: 'Tühistatud',
      orderCancelled: 'Sinu tellimus saadeti, kuid kahjuks ',
      archivedShort: 'Lõpetatud',
      ready: 'Sinu tellimus on valmis!',
      orderCancelledRefunded: 'Sinu raha tagastatakse',
      archived: 'Tellimus on lõpetatud',
      errorShort: 'Viga',
      new: 'Sinu tellimus on edukas!',
      orderInProgress: 'Töötame sinu tellimuse kallal - ',
      saveReceipt: 'Salvesta kviitung',
      inprogress: 'Sinu tellimus on valmimisel',
    },
    orderView: {
      dinein: 'Söö siin',
      notePlaceholder: 'Lisa erisoove, allergiaid jne...',
      reccomendations: 'Soovitused',
      addButton: 'Lisa',
      takeaway: 'Kaasa',
      preorder: 'Eeltellimus tuppa',
      roomorder: 'Tellimus tuppa',
      tableorder: 'Tellimus lauda',
      roomOrderPlaceholder: 'Toanumber',
      tableOrderPlaceholder: 'Lauanumber',
      delivery: 'Kohaletoimetamine',
      consumerName: 'Eesnimi (valikuline)',
      consumerNameForced: 'Palun sisesta oma nimi...',
      gotoCheckout: 'Mine maksmisele',
      title: 'Sinu tellimus',
      alcoholError: 'Vabandame, Soome seadus ei luba alkoholi kaasa müüa 😔',
      couponCode: 'Kupongi kood',
      pickTime: 'Vali aeg',
      today: 'Täna',
      tomorrow: 'Homme',
      required: 'Nõutud',
    },
    paymentView: {
      phoneNumber: 'Telefoninumber',
      useCoinDesc: 'Kasuta oma {{symbol}} münte tellimuse eest tasumiseks',
      payButtonLoading: 'Makstakse...',
      errorNoPhone: 'Palun sisesta oma telefoninumber',
      balance: 'Saldo',
      marketingConsent:
        'Nõustun, et {{company}} võib minuga ühendust võtta eripakkumiste ja muu turunduskommunikatsiooni jaoks.',
      tosConsent: 'Sisestades oma telefoninumbri, nõustun <1>teenusetingimustega</1>',
      useCoin: 'Kasuta münte',
      payButton: 'Kinnita tellimus',
      getCoin: 'Hanki münte',
      payNow: 'Maksa kohe',
      payNowDesc: 'Maksa kaardiga / Apple Pay / Google Pay',
      smartum: 'Smartum',
      title: 'Makse',
      getCoinDesc: 'Saa {{percentage}}% tellimuse väärtusest tagasi kui {{symbol}} münti',
      alcoholPopUptitle: 'Vanusepiiranguga tooted',
      alcoholPopUpAgeRestrict:
        'Olete ostmas vanusepiiranguga toodet. Olge valmis näitama töötajale oma isikutunnistust',
      alcoholPopUpNoRefund: 'Ostut tagasi ei osteta, kui te ei suuda töötajale oma isikutunnistust näidata.',
      alcoholPopUpConfirm: 'Palun kinnitage, et teil on seaduslik luba nende toodete ostmiseks.',
      alcoholPopUpButtonYesAllowed: 'Jah, mul on luba',
      alcoholPopUpButtonCancel: 'Tühista',
      paying: 'Makstakse...',
      confirmPayment: 'Kinnita makse',
    },
    menuView: {
      title: 'Menüü',
      viewOrder: 'Vaata tellimust',
      restaurantClosed: 'Täna suletud',
      addToOrder: 'Lisa tellimusse',
    },
    menuItemView: {
      preOrderChip: 'Eel tellimus',
      preOrderExplanation:
        'Seda toitu saab tellida ainult eelnevalt. Saate tellimuse esitamisel kassas valida, millal soovite selle kätte saada.',
      extras: 'Lisandid:',
      alcoholWarning:
        'Pange tähele, et alkoholi võib müüa ainult 18-aastastele ja vanematele isikutele. Olge valmis näitama oma isikutunnistust.',
    },
    general: {
      coins: 'Mündid',
      coin: 'Münt',
      update: {
        updateTitle: 'Vajalik uuendus',
        updateText: 'Kasutate vanemat versiooni Myntist. Palun vajutage allpool olevat nuppu, et uuendada',
        updateButton: 'Uuenda',
      },
      downloadApp: {
        downloadTitle: 'Saadaval ainult Myntis',
        downloadText: 'Selle soodustuse saamiseks peate kasutama Mynti rakendust 🙂',
        downloadButton: 'Laadi rakendus alla',
      },
    },
    couponView: {
      checkingCode: 'Koodi kontrollimine 🤟',
      errorTitle: 'Oi ei 🤨',
      errorText1: 'Midagi läks valesti, võib-olla olete juba selle pakkumise saanud',
      errorText2: 'Kui te pole seda saanud, palun taaskäivitage Mynti rakendus ja proovige uuesti',
      successTitle: 'Said Myntd!',
      explainerText:
        'Kui tellite oma eine Mynti kaudu, saate 10% tagasi müntidena. Uuendasime just viisi, kuidas oma raha kulutate',
      openMenuButton: 'Ava menüü',
      closeButton: 'Sulge',
      td: {
        youGot: 'Said',
        coinName: 'TD münti!',
        bannerText: 'Akseli Herlevi & Iman Gharagozlu',
      },
    },
  },
  Any: 'Muu',
  'Not empty': 'Ei tohi olla tühi',
  '1-4 digits': '1-4 numbrit',
  'One digit and numbers': 'Üks number ja numbrid',
  'Two digits and numbers': 'Kaks numbrit ja numbrid',
}

export default et
