import { useAuthContext } from '../Contexts/AuthContext'

// This is done, because sweedish krone goes to the back of price
// But euro and dollar in front of it
const useCurrency = () => {
  const { restaurant }: any = useAuthContext()
  const currency = restaurant?.config?.currency
  const currencySymbol: string | undefined = currency?.symbol

  return {
    withCurrencySymbol: (price: string | number | undefined | null) => {
      if (price === undefined || price === null) return ''
      if (typeof price === 'number') price = price.toString()

      if (currencySymbol === undefined) return price

      if (currencySymbol === 'kr') return `${price} ${currencySymbol}`
      else return `${currencySymbol}${price}`
    },
    currencyCode: currency?.code as string,
  }
}

export default useCurrency
