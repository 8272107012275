import React from 'react'
import { IconCoin } from '../Icons/Icons'

const TotalCoin = ({ token }) => {
  return (
    <div className="coin-amount">
      <label htmlFor="">
        <div className="icon">
          <IconCoin />
        </div>
        <div className="text">{token.symbol} Coins</div>
      </label>
      <div className="value">{token.balance}</div>
    </div>
  )
}

export default TotalCoin
