import React from 'react'
import CalculateAvailability from '../../Utils/CalculateAvailability'
import { ToLocalizedString } from '../../Utils/ToLocalizedString'

const EditableText = ({ item, i, menu }) => {
  let shouldHide = true

  for (let index = i + 1; index < menu.length; index++) {
    const element = menu[index]
    if (element.type == 'editableText') {
      break
    }

    if (!CalculateAvailability(element?.specialAvailability)) {
      if (!element?.specialAvailability?.hideWhenUnavailable) {
        shouldHide = false
        break
      }
    } else {
      shouldHide = false
      break
    }
  }

  if (shouldHide) {
    return
  }

  return (
    <div className="menu-type-container" id={item.title.replace(/\s/g, '')} key={i}>
      <div className="menu-type">{ToLocalizedString(item, 'title')}</div>
      {item.description ? <div className="menu-type-description">{ToLocalizedString(item, 'description')}</div> : null}
    </div>
  )
}

export default EditableText
