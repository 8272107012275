import Select from 'react-select'
import { useTranslation } from 'react-i18next'

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const greenBulb = {
  backgroundColor: '#26A69A',
  borderRadius: '100%',
  width: '12px',
  height: '12px',
  display: 'inline-block',
}

const redBulb = {
  backgroundColor: '#fb867d',
  borderRadius: '100%',
  width: '12px',
  height: '12px',
  display: 'inline-block',
}

const formatGroupLabel = data => {
  const allIrrelevant = data.options.every(({ isRelevant }) => !isRelevant)

  return (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={allIrrelevant ? redBulb : greenBulb}></span>
    </div>
  )
}

function TimePicker({ today, tomorrow, ...rest }) {
  const { t } = useTranslation()

  const timeOptions = [
    {
      label: t('orderView.today'),
      options: today,
    },
    {
      label: t('orderView.tomorrow'),
      options: tomorrow,
    },
  ]

  // I don't know why, but without isOptionSelected={() => false} all items are selected
  return (
    <Select
      options={timeOptions}
      formatGroupLabel={formatGroupLabel}
      placeholder={t('orderView.pickTime')}
      isOptionSelected={() => false}
      isOptionDisabled={({ isRelevant }) => !isRelevant}
      styles={{
        menu: (baseStyles, state) => ({
          ...baseStyles,
          zIndex: 1000,
        }),
      }}
      {...rest}
    />
  )
}

export default TimePicker
