import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconCoinSmall, IconTrash } from '../Icons/Icons'
import InputAmount from '../Input/InputAmount'
import CartAmount from './CartAmount'
import { ToLocalizedString } from '../../Utils/ToLocalizedString'
import { useAuthContext } from '../../Contexts/AuthContext'
import useCurrency from '../../Hooks/useCurrency'

const CartItem = props => {
  const itemRef = React.createRef()
  const { withCurrencySymbol } = useCurrency()

  let isSurCharge = props.data?.surchargeKey == 'Surcharge'

  return (
    <div
      ref={itemRef}
      className={'menu-item' + (props?.data?.selected ? ' selected' : '') + (isSurCharge ? ' surcharge' : '')}
      onClick={props?.onClick}
    >
      {props?.data?.image ? (
        <div className="item-img">
          <img src={props?.data?.image} alt="" />
        </div>
      ) : (
        <div className="no-image-spacer" />
      )}
      <div className="item-caption">
        <h3 className="item-name">{ToLocalizedString(props?.data, 'title')}</h3>
        <div className="item-desc">{ToLocalizedString(props?.data, 'description')}</div>
        <div className="item-price">
          <div className="amount">
            {props?.data?.discountedPrice ? (
              <>
                <span className="old">{withCurrencySymbol(props?.data?.price)}</span>
                {withCurrencySymbol(props?.data?.discountedPrice)}
              </>
            ) : (
              <>
                {props?.data?.oldPrice && <span className="old">{withCurrencySymbol(props?.data?.oldPrice)}</span>}
                {withCurrencySymbol(props?.data?.price)}
              </>
            )}
          </div>
          <div className="order">
            {props?.data?.coin && !isSurCharge && (
              <a className="coin-bonus">
                <IconCoinSmall /> <span>+{props?.data?.coin}</span>
              </a>
            )}
          </div>
        </div>
      </div>
      {!isSurCharge && (
        <CartAmount
          index={props?.index}
          quantity={props?.data.quantity}
          remove={props?.fnRemove}
          fnAdjustAmount={props?.fnAdjustAmount}
        />
      )}
    </div>
  )
}

CartItem.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.string,
    oldPrice: PropTypes.string,
    status: PropTypes.number,
    coin: PropTypes.number,
    selected: PropTypes.bool,
  }),
  fnRemove: PropTypes.func,
}

export default CartItem
