import React, { createContext, useState, useContext, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export const SubMenuContext = createContext()

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function SubMenuProvider(props) {
  const [surcharge, setSurcharge] = useState('')
  const [shouldAcceptPreOrder, setShouldAcceptPreOrder] = useState(false)
  const [orderingMode, setOrderingMode] = useState('no-special-ordering')
  const [nameOrdering, setNameOrdering] = useState(false)
  const [forcedNameOrdering, setForcedNameOrdering] = useState(false)
  const [usingSubmenu, setUsingSubmenu] = useState(false)
  const [subMenu, setSubMenu] = useState()

  let query = useQuery()

  const HandleUsingSubmenu = setting => {
    if (setting) {
      setUsingSubmenu(true)
      setSubMenu(setting)
      setShouldAcceptPreOrder(setting.shouldAcceptPreOrder)
      setOrderingMode(setting.orderingMode || 'no-special-ordering')
      setSurcharge(setting.surcharge)
      setForcedNameOrdering(setting.forcedNameOrdering)
      setNameOrdering(setting.nameOrdering)
    } else {
      setUsingSubmenu(false)
    }
  }

  const value = {
    HandleUsingSubmenu,
    setSubMenu,
    shouldAcceptPreOrder,
    orderingMode,
    surcharge,
    nameOrdering,
    forcedNameOrdering,
    subMenu,
  }

  return <SubMenuContext.Provider value={value} {...props} />
}

export function useSubmenuContext() {
  return useContext(SubMenuContext)
}
