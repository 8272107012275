import Big from 'big.js'
import React, { createContext, useContext, useState, useMemo, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as cache from '../Utils/Cache'
import CalculateAvailability from '../Utils/CalculateAvailability'
import { useSubmenuContext } from './SubMenuContext'
import { useAuthContext } from './AuthContext'

export const CartContext = createContext({})

export function CartProvider(props) {
  const { restaurant } = useAuthContext()
  const [cartList, setCartList] = useState([])
  const [couponCode, setCouponCode] = useState('')
  const [metadata, setMetadata] = useState({})
  const [allSaleTo, setAllSaleTo] = useState(null)
  const [preordersIncart, setPreordersIncart] = useState(false)
  const [alcoholInCart, setAlcoholInCart] = useState(false)
  const [takeAwayAvailable, setTakeAwayAvailable] = useState(false)
  const [nonPreOrdersIncart, setNonPreOrdersIncart] = useState(false)
  const [tableNumber, setTableNumber] = useState('')

  const { surcharge, shouldAcceptPreOrder } = useSubmenuContext()
  const [tagAlong, setTagAlong] = useState({
    requestType: 'EAT HERE',
    note: '',
    customerName: '',
    plannedAt: null,
  })
  const [inSession, setInSession] = useState(false)

  const totalPrice = useMemo(() => {
    return cartList
      .reduce(
        (acc, curr) =>
          acc.plus(new Big(curr.quantity).mul(curr.item.discountedPrice ? curr.item.discountedPrice : curr.item.price)),
        new Big('0')
      )
      .toFixed(2)
  }, [cartList])

  const _prepareCart = (cart, quantity, item) => {
    const newList = [...cart]
    let otherItems = []
    otherItems = item.chosenExtras?.filter(e => e.type == 'menuItem')

    item.chosenExtras = item.chosenExtras?.filter(e => e.type != 'menuItem')
    newList.push({
      item: item,
      quantity: quantity,
    })

    for (let i = 0; i < otherItems.length; i++) {
      // newList.push()

      if (item.isIncludeSurcharge) {
        otherItems[i].isIncludeSurchargeOverride = item.isIncludeSurcharge
      }
      otherItems[i].chosenExtras = []
      otherItems[i].chosenOptions = []

      newList.push({
        item: otherItems[i],
        quantity: quantity,
      })
    }

    return newList
  }

  const addToCart = (quantity, item) => {
    const newList = _prepareCart([...cartList], quantity, item)
    setCartList(newList)
  }

  const addItemsToCart = items => {
    let newList = [...cartList]
    for (let i = 0; i < items.length; i++) {
      newList = _prepareCart(newList, items[i].quantity, items[i].item)
    }
    setCartList(newList)
  }

  const removeFromCart = index => {
    if (cartList?.[index]?.item?.surchargeKey === 'Surcharge') {
      return
    }
    let newList = [...cartList]

    newList.splice(index, 1)
    if (newList.length == 1) {
      // Special case, if surcharge delete it as well
      if (newList[0].item?.surchargeKey === 'Surcharge') {
        newList.splice(0, 1)
      }
    }

    if (newList.length > 0) {
      // Special case we remove items

      let surchargeindex = newList.findIndex(e => e.surchargeKey === 'Surcharge')

      if (surchargeindex == -1) {
        // Case we dont have surcharge in vcart, do we need to add surcharge?

        let itemsWithOverride = newList.filter(e => e.item.isIncludeSurchargeOverride != null)

        if (itemsWithOverride.length == newList.length) {
          // Add surcharge because all your items used to be extras to a item with surcharge

          newList.push({
            item: {
              id: uuidv4(),
              surchargeKey: 'Surcharge',
              title: 'Surcharge Fee',
              translations: {
                title: {
                  en: 'Service charge',
                  fi: 'Toimitusmaksu',
                  sv: 'Leveranskostnad',
                  es: 'Gastos de envío',
                  ee: 'Tarnehind',
                },
              },
              image: null,
              price: surcharge,
              isAlcohol: false,
              vat: '0.24',
              chosenExtras: [],
              chosenOptions: [],
            },
            quantity: 1,
          })
        }
      }
    }

    setCartList(newList)
  }

  const adjustAmount = (index, newQuantity) => {
    let newList = [...cartList]
    newList[index].quantity = cartList?.[index]?.item?.surchargeKey === 'Surcharge' ? 1 : newQuantity
    setCartList(newList)
  }

  // persist cart
  useEffect(() => {
    cache.set(
      'cart',
      JSON.stringify({
        cartList,
        tagAlong,
      })
    )

    // if (checkIfPreOrder()) {
    //   setPreordersIncart(true)
    //   setNonPreOrdersIncart(false)
    // } else if (cartList.length > 0) {
    //   setPreordersIncart(false)
    //   setNonPreOrdersIncart(true)
    // } else {
    //   setPreordersIncart(false)
    //   setNonPreOrdersIncart(false)
    // }

    setPreordersIncart(checkIfPreOrder())
    setAlcoholInCart(checkIfAlcohol())
    setTakeAwayAvailable(checkIfTakeAway())

    return () => {
      cache.del('cart')
    }
  }, [cartList, tagAlong])

  /**
   * @returns true/false if there are are preorders in cartlist
   */
  const checkIfPreOrder = () => {
    for (let i = 0; i < cartList.length; i++) {
      const element = cartList[i]
      const isAvailableNow = CalculateAvailability(element.item.specialAvailability)
      if (shouldAcceptPreOrder && !isAvailableNow) {
        return true;
      }
    }
    return false
  }

  /**
   * @returns true/false if there are are alcohol in cartlist
   */
  const checkIfAlcohol = () => {
    let result = false
    cartList.forEach(element => {
      if (element.item.isAlcohol) result = true
    })

    return result
  }

  /**
   * @returns
   *  true - take away available
   *  false - no take away unavailable
   */
  const checkIfTakeAway = () => {
    let result = true

    // Restaurant config check
    if (!restaurant?.config?.allowedRequestTypes.includes('TAKEAWAY')) result = false

    // Item config check and alcohol check
    cartList.forEach(element => {
      if (element.item.noTakeAway) result = false
      if (element.item.isAlcohol) result = false
    })

    return result
  }

  useEffect(() => {
    const initCartFromCache = async () => {
      const cachedRaw = cache.get('cart')
      if (cachedRaw && JSON.parse(cachedRaw)) {
        const { cartList: cachedCartList, tagAlong: cachedTagAlong } = JSON.parse(cachedRaw)
        setCartList(cachedCartList)
        setTagAlong(cachedTagAlong)
      }
    }
    initCartFromCache()
  }, [])

  const clearCache = async () => {
    setCartList([])
    setTagAlong({
      requestType: 'EAT HERE',
      note: '',
    })
    ;(await cache.get('cart')) && cache.del('cart')
  }

  return (
    <CartContext.Provider
      value={{
        cartList,
        setCartList,
        addToCart,
        removeFromCart,
        totalPrice,
        tagAlong,
        adjustAmount,
        setTagAlong,
        clearCache,
        tableNumber,
        setTableNumber,
        allSaleTo,
        setAllSaleTo,
        inSession,
        setInSession,
        takeAwayAvailable,
        alcoholInCart,
        preordersIncart,
        nonPreOrdersIncart,
        addItemsToCart,
        metadata,
        setMetadata,
        couponCode,
        setCouponCode,
      }}
      {...props}
    />
  )
}

export function useCartContext() {
  return useContext(CartContext)
}
