const fi = {
  translation: {
    couponView: {
      td: {
        bannerText: 'Akseli Herlevi & Iman Gharagozlu',
        youGot: 'Ansaitsit juuri',
        coinName: 'TD-Kolikoita!',
      },
      errorText1: 'Jotain meni pieleen. Ehkä olet jo lunastanut tämän tarjouksen?',
      errorText2: 'Jos et ole lunastanut, sulje ja avaa Mynt ja kokeile uudelleen.',
      checkingCode: 'Koodiasi tarkistetaan 🤟',
      successTitle: 'You just got Myntd!',
      closeButton: 'Sulje',
      openMenuButton: 'Avaa Menu',
      errorTitle: 'Voi ei 🤨',
      explainerText:
        'Kun tilaat ruokasi Myntin kautta, saat 10% tilauksesi arvosta takaisin kolikoina. Kolikot voit käyttää seuraavassa tilauksessasi!',
    },
    orderSuccessView: {
      orderNumber: 'Tilausnumero',
      archivedShort: 'Valmis',
      newShort: 'Lähetetty',
      status: {
        inprogress: 'Tilaustasi valmistetaan',
        new: 'Tilaus lähetetty',
        archived: 'Kiitos ja nauti!',
        ready: 'Sain tilaukseni',
        cancelled: 'Tilaus peruttu',
        error: 'Virhe',
      },
      inprogress: 'Arvioitu valmistusaika:',
      orderCancelledRefunded: 'Maksusi palautetaan',
      orderCancelledUnableToFullfil: 'ravintola ei voinut hyväksyä tilaustasi.',
      deliveryEst: 'Arvioitu valmistumisaika on',
      errorShort: 'Virhe',
      new: 'Tilauksesi onnistui!',
      error: 'Tilauksen käsittely epäonnistui',
      readyDesc: 'Hae tilauksesi tiskiltä',
      orderSentTo: 'Tilaus lähetetty ravintolalle ',
      orderByCash: 'Mene kassalle maksaaksesi tilauksesi.',
      saveReceipt: 'Tallenna kuitti',
      cancelledShort: 'Peruttu',
      cancelled: 'Tilauksesi on peruttu',
      archived: 'Tilauksesi on valmis',
      ready: 'Tilauksesi on valmis!',
      orderInProgress: 'Käsittelemme tilaustasi -',
      orderCancelled: 'Tilauksesi lähetettiin, mutta valitettavasti',
      yourOrderNumberIs: 'Tilausnumerosi on #',
    },
    orderView: {
      alcoholError: 'Valitettavasti Suomen laki kieltää hauskanpidon. Alkoholia ei voi tilata mukaan 😔',
      title: 'Tilauksesi',
      notePlaceholder: 'Kerro erityistoiveista, allergioista jne...',
      reccomendations: 'Suosittelemme',
      takeaway: 'Otan mukaan',
      preorder: 'Pre order',
      delivery: 'Kuljetus',
      dinein: 'Paikan päällä',
      consumerName: 'Etunimi (Valinnainen)',
      consumerNameForced: 'Kirjoita nimesi...',
      gotoCheckout: 'Siirry maksamaan',
      addButton: 'Lisää',
      roomorder: 'Tilaa huoneeseen',
      tableorder: 'Tilaa pöytään',
      roomOrderPlaceholder: 'Huoneen numero',
      tableOrderPlaceholder: 'Pöydän numero',
      tableFormatError: 'Pöydän numero tarvitaan.',
      roomFormatError: 'Huoneen numero tarvitaan.',
      forExample: 'esim.',
      pickTime: 'Valitse aika',
      today: 'Tänään',
      tomorrow: 'Huomenna',
      required: 'Pakollinen',
    },
    paymentView: {
      errorNoPhone: 'Syötä puhelinnumerosi',
      payButtonLoading: 'Maksetaan...',
      marketingConsent: '{{company}} saa lähettää minulle tarjouksia ja markkinointiviestejä.',
      tosConsent: 'Syöttämällä puhelinnumeroni hyväksyn Myntin <1>käyttöehdot</1>',
      phoneNumber: 'Puhelinnumero',
      useCoinDesc: 'Käytä {{symbol}} -kolikoita alennukseen tilauksestasi',
      alcoholPopUpNoRefund: 'Emme palauta maksua, jos et voi todistaa henkilöllisyyttäsi henkilökunnalle.',
      payButton: 'Vahvista tilaus',
      alcoholPopUptitle: 'Ikärajoitetut tuotteet',
      useCoin: 'Käytä kolikoita',
      balance: 'Saldo',
      alcoholPopUpButtonCancel: 'Peruuta',
      getCoin: 'Hanki kolikoita',
      payNow: 'Maksa nyt',
      payNowDesc: 'Maksa kortilla / Apple pay / Google Pay',
      alcoholPopUpButtonYesAllowed: 'Kyllä, saan tilata alkoholia',
      smartum: 'Smartum',
      alcoholPopUpAgeRestrict: 'Olet tilaamassa alkoholia. Valmistaudu näyttämään henkilöllisyystodistuksesi',
      getCoinDesc: 'Saat {{percentage}}% tilauksesi arvosta takaisin {{symbol}} -kolikoina',
      alcoholPopUpConfirm: 'Vahvista, että olet täysi-ikäinen ja saat tilata alkoholia.',
      title: 'Maksu',
      paying: 'Maksamassa...',
      confirmPayment: 'Siirry maksamaan',
      paymentMethods: {
        cash: {
          title: 'Maksu kassalle',
          description: 'Maksu kassalle',
        },
      },
    },
    general: {
      coin: 'Kolikko',
      downloadApp: {
        downloadTitle: 'Saatavilla vain Myntissä',
        downloadButton: 'Lataa Appi',
        downloadText: 'Tarvitset Mynt-appin etusi lunastamiseen 🙂',
      },
      update: {
        updateText: 'Käytät vanhempaa versiota Myntistä. Paina allaolevaa nappia päivittääksesi',
        updateButton: 'Päivitä',
        updateTitle: 'Päivitys tarvitaan',
      },
      coins: 'Kolikot',
    },
    menuItemView: {
      preOrderChip: 'Vain ennakkotilaukset',
      preOrderExplanation: 'Tämän tuotteen voi ennakkotilata. Voit valita haluamasi toimitusajan tilauksen yhteydessä.',
      extras: 'Extrat:',
      alcoholWarning:
        'Huom! Alkoholia voi ostaa vain 18 vuotta täyttäneet. Valmistaudu näyttämään henkilöllisyystodistuksesi.',
    },
    menuView: {
      viewOrder: 'Näytä tilaus',
      title: 'Valikko',
      restaurantClosed: 'Suljettu tänään',
      addToOrder: 'Lisää tilaukseen',
    },
  },
  Any: 'Any',
  'Not empty': 'Not empty',
  '1-4 digits': '1-4 digits',
  'One digit and numbers': 'One digit and numbers',
  'Two digits and numbers': 'Two digits and numbers',
}

export default fi
