import 'whatwg-fetch'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'react-loading-skeleton/dist/skeleton.css'
import './Styles/styles.scss'

import reportWebVitals from './reportWebVitals'

import 'react-datepicker/dist/react-datepicker.css'
import App from './App'

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
} else {
  // production
  Sentry.init({
    dsn: "https://ba21994c2e96890bd87081aba69737d4@o4507532232163328.ingest.de.sentry.io/4507532332499024",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_COMMIT_HASH,
    environment: process.env.REACT_APP_FIREBASE_ENV || 'unknown',
  })

  // Rudder.initialize()
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Sentry.ErrorBoundary
    fallback={({ error, componentStack, resetError }) => (
      <>
        <h2>Something went wrong</h2>
        <div>You have encountered an error</div>
        <div>{error.toString()}</div>
        <div>{componentStack}</div>
        <h3>Reloading page in 10s...</h3>
      </>
    )}
    onError={(e, s) => setTimeout(() => window.location.reload(), 10000)}
  >
    <App />
  </Sentry.ErrorBoundary>
)

if (document.getElementById('loading-app')) {
  document.getElementById('loading-app')?.remove()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
