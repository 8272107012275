import { request } from './base'
// import * as cache from "../Utils/Cache";

export async function getRestaurantMenu(id) {
  let resp
  // const serialNumber = await cache.get("sn");
  try {
    resp = await request(`/restaurants/getDeviceLinkedMenu`, 'get', {
      params: {
        id,
      },
    })
  } catch (error) {
    console.error(error)
    throw new Error('Failed to load menu with the QR code')
    // if (serialNumber && serialNumber === id) {
    //   resp = await request(
    //     `/getMenuListByUser`,
    //     "get",
    //     {
    //       params: {
    //         id,
    //       },
    //     }
    //   );
    // }
  }
  return resp?.data
}

// body: {
//   "phoneNumber": "",
//   "restaurantId": "",
//   "tokenId": "",
//   "rewardingToken": 10,
//   "amount": 88,
//   "requestType": "DINEIN/TAKEWAY",
//   "note": "Add a specical requests, allergis etc...",
//   "menu": [{
//         "id": "",
//         "quantity": 2
//   }],
//   "successUrl": "",
//   "cancelUrl": ""
// }
export async function startPayment(payload) {
  const resp = await request(`/checkoutByAnonymous`, 'post', {
    payload: {
      ...payload,
      successUrl: `${window.location.origin}/payment-success`,
      cancelUrl: `${window.location.origin}/error`,
    },
  })
  return resp?.data
}

export async function startPaymentAsUser(payload) {
  const resp = await request(`/checkout`, 'post', {
    payload: {
      ...payload,
      successUrl: `${window.location.origin}/payment-success`,
      cancelUrl: `${window.location.origin}/error`,
    },
  })
  return resp?.data
}

export async function getTransactionByToken(token) {
  const resp = await request(`/transactions/userOrderDetailByToken/${token}`, 'get', {
    params: {},
  })
  return resp?.data
}

export async function getReceipt(id) {
  const resp = await request(`/getReceipt`, 'get', {
    params: {
      id,
      // receiptType: "consumer",
    },
  })
  return resp?.data
}

export async function listTokens() {
  const resp = await request(`/listTokens`, 'get')
  return resp?.data
}

export async function listTokensByAnonymous() {
  const resp = await request(`/listTokensByAnonymous`, 'get')
  return resp?.data
}

export async function getToken(id) {
  const resp = await request(`/tokens/${id}`, 'get')
  return resp?.data
}

// export function getRestaurantMenu() {
//     // Note: This should give all data about every restaurant, Info + Menu.
//     return fetch("http://localhost:3333/MenuList")
//         .then((data) => data.json())
//         .catch((error) => error);
// }
