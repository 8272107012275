import RecommendItem from './RecommendedItem'
import { t } from 'i18next'

function Recommendations({ recommendations, onAdd }) {
  return (
    <div className="recommendations">
      <h2 className="headline-3">{t('orderView.reccomendations')}</h2>
      <div className="recommend-list">
        {recommendations.map((item, i) => (
          <RecommendItem key={i} data={item} index={i} onAdd={onAdd} />
        ))}
      </div>
    </div>
  )
}

export default Recommendations
