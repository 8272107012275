import * as rudderanalytics from 'rudder-sdk-js'

export const enableTracking = String(process.env.REACT_APP_ENABLE_TRACKING)
export let trackingClient = null

export const initialize = () => {
  rudderanalytics.load('2FWrCzvQ7sm1ofpb6ttowx7xvPP', 'https://sterlyducer.dataplane.rudderstack.com')
  rudderanalytics.ready(() => {
    console.log('We are all set!!!')
  })
  trackingClient = rudderanalytics
}

export const identify = (id, phone) => {
  if (!enableTracking) return
  try {
    trackingClient.identify(id, phone)
  } catch (error) {
    return undefined
  }
}

export const track = (event, properties) => {
  if (!enableTracking) return
  try {
    trackingClient.track(event, properties)
  } catch (error) {
    console.error(error)
  }
}
