class ArgumentError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ArgumentError'
  }
}

interface AcceptParams {
  retries?: number
  retryDelay?: number
  retryOn?:
    | number[]
    | ((attempt: number, err: Error | null, resp: Response | null) => {})
  timeout?: number
}

interface DefaultParams {
  retries: number
  retryDelay: number
  retryOn:
    | number[]
    | ((attempt: number, err: Error | null, resp: Response | null) => {})
  timeout?: number
}

export default function fetchRetry(
  fetch: (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>,
  _defaults?: AcceptParams
) {
  const checkDefaults = _defaults || {}

  if (
    checkDefaults.retries !== undefined &&
    !isPositiveInteger(checkDefaults.retries)
  ) {
    throw new ArgumentError('retries must be a positive integer')
  }

  if (
    checkDefaults.retryDelay !== undefined &&
    !isPositiveInteger(checkDefaults.retryDelay)
  ) {
    throw new ArgumentError('retryDelay must be a positive integer')
  }

  if (
    checkDefaults.retryOn !== undefined &&
    !Array.isArray(checkDefaults.retryOn) &&
    typeof checkDefaults.retryOn !== 'function'
  ) {
    throw new ArgumentError('retryOn property expects an array or function')
  }

  var baseDefaults = {
    retries: 3,
    retryDelay: 1000,
    retryOn: [],
    timeout: undefined,
  }

  return function fetchRetry(input: any, init: any): Promise<Response> {
    const defaults: DefaultParams = Object.assign(
      {},
      baseDefaults,
      checkDefaults,
      init?.fetchOptions || {}
    )
    console.log('request options', defaults)
    var retries = defaults.retries
    var retryDelay = defaults.retryDelay
    var retryOn = defaults.retryOn
    var timeout = defaults.timeout

    if (init && init.retries !== undefined) {
      if (isPositiveInteger(init.retries)) {
        retries = init.retries
      } else {
        throw new ArgumentError('retries must be a positive integer')
      }
    }

    if (init && init.retryDelay !== undefined) {
      if (
        isPositiveInteger(init.retryDelay) ||
        typeof init.retryDelay === 'function'
      ) {
        retryDelay = init.retryDelay
      } else {
        throw new ArgumentError(
          'retryDelay must be a positive integer or a function returning a positive integer'
        )
      }
    }

    if (init && init.retryOn) {
      if (Array.isArray(init.retryOn) || typeof init.retryOn === 'function') {
        retryOn = init.retryOn
      } else {
        throw new ArgumentError('retryOn property expects an array or function')
      }
    }

    // eslint-disable-next-line no-undef
    return new Promise(function (resolve, reject) {
      var wrappedFetch = function (attempt: number) {
        // As of node 18, this is no longer needed since node comes with native support for fetch:
        /* istanbul ignore next */
        var _input =
          typeof Request !== 'undefined' && input instanceof Request
            ? input.clone()
            : input
        var signalTimeout = timeout
          ? (function (ms) {
              var ctrl = new window.AbortController()
              setTimeout(function () {
                console.log('timedout request')
                ctrl.abort()
              }, ms)
              return ctrl.signal
            })(timeout)
          : null
        console.log('timeout', timeout)
        fetch(
          _input,
          Object.assign(
            {},
            init,
            signalTimeout ? { signal: signalTimeout } : {}
          )
        )
          .then(function (response) {
            if (
              Array.isArray(retryOn) &&
              retryOn.indexOf(response.status) === -1
            ) {
              resolve(response)
            } else if (typeof retryOn === 'function') {
              try {
                // eslint-disable-next-line no-undef
                return Promise.resolve(retryOn(attempt, null, response))
                  .then(function (retryOnResponse) {
                    if (retryOnResponse) {
                      retry(attempt, null, response)
                    } else {
                      resolve(response)
                    }
                  })
                  .catch(reject)
              } catch (error) {
                reject(error)
              }
            } else {
              if (attempt < retries) {
                retry(attempt, null, response)
              } else {
                resolve(response)
              }
            }
          })
          .catch(function (error) {
            if (typeof retryOn === 'function') {
              try {
                // eslint-disable-next-line no-undef
                Promise.resolve(retryOn(attempt, error, null))
                  .then(function (retryOnResponse) {
                    if (retryOnResponse) {
                      retry(attempt, error, null)
                    } else {
                      reject(error)
                    }
                  })
                  .catch(function (error) {
                    reject(error)
                  })
              } catch (error) {
                reject(error)
              }
            } else if (attempt < retries) {
              retry(attempt, error, null)
            } else {
              reject(error)
            }
          })
      }

      function retry(
        attempt: number,
        error: Error | null,
        response: Response | null
      ) {
        var delay = retryDelay
        setTimeout(function () {
          wrappedFetch(++attempt)
        }, delay)
      }

      wrappedFetch(0)
    })
  }
}

function isPositiveInteger(value: number) {
  return Number.isInteger(value) && value >= 0
}
