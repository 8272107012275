import './modal.scss'

type ModalProps = {
  show: boolean
  onClose: (...args: any) => any
  children: React.ReactNode
}

function Modal({ show, onClose, children }: ModalProps) {
  const handleClose = (e: any) => {
    onClose(e)
  }

  if (!show) return null
  return (
    <div className="orderstatus-modal-wrapper" onClick={handleClose}>
      <div className="orderstatus-modal" onClick={e => e.stopPropagation()}>
        <div className="orderstatus-modal-close" onClick={handleClose}>
          <img src="/img/close.png" />
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal
