/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import InApp from 'detect-inapp'
import { v4 as uuidv4 } from 'uuid'
import { Link, useNavigate, useSearchParams, generatePath } from 'react-router-dom'
import TotalCoin from '../Components/Coin/TotalCoin'
import { getRestaurantMenu } from '../Api/MenuApi'
import { IconClose, IconMenu, IconSearch } from '../Components/Icons/Icons'
import InputAmount from '../Components/Input/InputAmount'
import PageLayout from '../Components/Layout/PageLayout'
import MenuItem from '../Components/Menu/MenuItem'
import SearchBox from '../Components/Search/SearchBox'
import CategoriesBox from '../Components/CategoriesBox/CategoriesBox'
import StoreItem from '../Components/Store/StoreItem'
import MenuItemView from '../Components/Menu/MenuItemView'
import { useCartContext } from '../Contexts/CartContext'
import { useAuthContext } from '../Contexts/AuthContext'
import { getRestaurantInfo } from '../Api/RestaurantApi'
import { listTokens } from '../Api/MenuApi'
import Big from 'big.js'
import { onCallBack } from '../Utils/FlutterChannel'
import Loading from '../Components/Loading/Loading'
import { useTranslation } from 'react-i18next'
import IsiPhone from '../Utils/IsIphone'
import EditableText from '../Components/Menu/EditableText'
import { useSubmenuContext } from '../Contexts/SubMenuContext'
import CalculateAvailability, { createAvailabilityJson } from '../Utils/CalculateAvailability'
import useCurrency from '../Hooks/useCurrency'

const MenuView = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [show, setShow] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [menu, setMenu] = useState([])
  const [currentItem, setCurrentItem] = useState(null)
  const {
    cartList,
    setCartList,
    addToCart,
    addItemsToCart,
    allSaleTo,
    setAllSaleTo,
    preordersIncart,
    nonPreOrdersIncart,
    ...cartContext
  } = useCartContext()
  const { restaurant, restaurantId, loggedIn, token } = useAuthContext()
  const [setting, setSetting] = useState({})
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [coupons, setCoupons] = useState([])

  const { HandleUsingSubmenu, shouldAcceptPreOrder } = useSubmenuContext()
  const { withCurrencySymbol } = useCurrency()

  const returnItembyId = id => {
    let extraJson = menu.filter(e => e.id == id)
    if (extraJson.length > 0) {
      extraJson = extraJson[0]
      return extraJson
    }
    // item no exits?
  }

  useEffect(() => {
    if (restaurantId) {
      const refreshRestaurantData = async () => {
        const restaurantMenuResp = await getRestaurantMenu(restaurantId)
        setMenu(restaurantMenuResp?.data || [])
        setSetting(restaurantMenuResp?.setting || {})
        setCoupons(restaurantMenuResp?.coupons || [])
        HandleUsingSubmenu({
          ...restaurantMenuResp?.setting,
          nameOrdering: restaurantMenuResp?.deviceSetting?.nameOrdering,
          forcedNameOrdering: restaurantMenuResp?.deviceSetting?.forcedNameOrdering,
        })
        if (!error) {
          setLoaded(true)
        }
      }
      refreshRestaurantData().catch(error => {
        setError(error)
      })
      // getRestaurantMenu(restaurantId).then((resp) => {
      //   setMenu(resp?.data || [])
      //   setAllSaleTo(resp?.allSaleTo);
      // });
      cartContext.setInSession(true)
    }
  }, [restaurantId])

  const totalPricePerItem = useMemo(() => {
    if (!currentItem) return 0
    console.log('currentItem', currentItem)
    const _price = new Big(
      allSaleTo !== null && typeof allSaleTo !== 'undefined'
        ? allSaleTo
        : currentItem.discountedPrice || currentItem.price
    )
      .plus(
        currentItem?.chosenExtras && currentItem.chosenExtras.length > 0
          ? currentItem.chosenExtras.reduce(
              (acc, curr) =>
                acc.plus(
                  allSaleTo !== null && typeof allSaleTo !== 'undefined'
                    ? allSaleTo
                    : curr.discountedPrice || curr.price || '0'
                ),
              new Big('0')
            )
          : 0
      )
      .plus(
        (currentItem?.chosenOptions || []).reduce(
          (acc, curr) =>
            acc.plus(
              (curr?.optionList || []).reduce(
                (oAcc, oCurr) =>
                  oAcc.plus(
                    allSaleTo !== null && typeof allSaleTo !== 'undefined'
                      ? allSaleTo
                      : oCurr?.discountedPrice || oCurr?.price || '0'
                  ),
                new Big('0')
              )
            ),
          new Big('0')
        )
      )
      .toFixed(2)
    return _price
  }, [currentItem])

  const totalPriceOfItemsAsExtra = useMemo(() => {
    if (!currentItem) return Big(0)

    return (currentItem?.chosenExtras || [])
      .filter(el => el.type !== 'extraItem')
      .reduce(
        (acc, curr) =>
          acc.plus(
            allSaleTo !== null && typeof allSaleTo !== 'undefined'
              ? allSaleTo
              : curr.discountedPrice || curr.price || '0'
          ),
        new Big(0)
      )
      .toFixed(2)
  }, [currentItem])

  const addItemToCart = useCallback(async () => {
    const extraText = (currentItem?.chosenExtras || []).map(ex => ex.name).join('\n  ')
    const note = `${
      // (currentItem?.chosenOptions || []).map((op) => `${op.optionName}- ${op.optionList.map((o) => o.name).join(', ')}`).join(' \n')
      (currentItem?.chosenOptions || [])
        .map(op => `- ${op.optionList.map(o => o.name.replace(' - ', '\n  ')).join(', ')}`)
        .join(' \n')
    }${extraText ? `\n- ${extraText}\n` : ''}`

    let availabilityJson = {}

    if (currentItem.specialAvailability) {
      availabilityJson = createAvailabilityJson(currentItem.specialAvailability)
    }

    console.log('currentItem', currentItem.chosenExtras)

    addItemsToCart([
      {
        quantity: currentItem.quantity,
        item: {
          ...currentItem,
          id: currentItem.id,
          title: currentItem.title,
          titleFI: currentItem.titleFI, // deprecraing
          titleSE: currentItem.titleSE, // deprecraing
          translations: currentItem.translations,
          image: currentItem.image,
          noTakeAway: currentItem.noTakeAway,
          price: Big(totalPricePerItem).minus(totalPriceOfItemsAsExtra).toFixed(2),
          chosenExtras: (currentItem?.chosenExtras || []).filter(el => el.type === 'extraItem'),
          chosenOptions: currentItem?.chosenOptions || [],
          isAlcohol: currentItem?.isAlcohol,
          availabilityJson: availabilityJson,
          isIncludeSurcharge: currentItem.isIncludeSurcharge,
          isPreOrder:
            setting?.shouldAcceptPreOrder &&
            !CalculateAvailability(currentItem.specialAvailability) &&
            currentItem.isIncludeSurcharge,
          note,
        },
      },
      ...(currentItem?.chosenExtras || [])
        .filter(el => el.type !== 'extraItem')
        .map(itemAsExtra => ({
          quantity: currentItem.quantity,
          item: {
            ...itemAsExtra,
            id: itemAsExtra.id,
            title: itemAsExtra.title,
            titleFI: itemAsExtra.titleFI, // deprecraing
            titleSE: itemAsExtra.titleSE, // deprecraing
            translations: itemAsExtra.translations,
            image: itemAsExtra.image,
            noTakeAway: itemAsExtra.noTakeAway,
            price: itemAsExtra.price,
            chosenExtras: [],
            chosenOptions: [],
            isAlcohol: itemAsExtra?.isAlcohol,
            availabilityJson: availabilityJson,
            isIncludeSurcharge: itemAsExtra.isIncludeSurcharge,
            isPreOrder:
              setting?.shouldAcceptPreOrder &&
              !CalculateAvailability(itemAsExtra.specialAvailability) &&
              itemAsExtra.isIncludeSurcharge,
            note,
          },
        })),
    ])

    setCurrentItem(null)
  }, [addToCart, addItemsToCart, currentItem, totalPricePerItem, totalPriceOfItemsAsExtra])

  const onClickViewOrder = useCallback(() => {
    // check if submenu has surcharge
    let surcharge = setting?.surcharge
    // check if any item has surcharge
    surcharge = cartList.filter(el => el.item.isIncludeSurcharge).length > 0 ? '0' : surcharge

    if (
      Big(surcharge || '0').gt(0) &&
      cartList.filter(cartItem => cartItem.item.surchargeKey === 'Surcharge').length === 0
    ) {
      addToCart(1, {
        id: uuidv4(),
        surchargeKey: 'Surcharge',
        title: 'Room Service Fee',
        image: null,
        price: surcharge,
        isAlcohol: false,
        chosenExtras: [],
        chosenOptions: [],
      })
    }
    navigate({
      pathname: '/order',
      search: searchParams.toString(),
    })
  }, [addToCart, cartList, setting, searchParams])

  const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera)

  if (!loaded) {
    return (
      <>
        <Loading error={error} />
      </>
    )
  }

  return (
    <>
      <PageLayout type={'home'} bannerSrc={restaurant?.image == undefined ? null : restaurant.image}>
        <div className="menu-container">
          <div className="menu-header">
            {/*<h1 className='headline-1'>STRL</h1>  Disabled //Emil Just to reduce UI bloat*/}
            <div className="description text-13">
              {/* {restaurant?.description} */}
              {inapp.isInApp}
              {/* <btn onClick={() => window.open(window.location.href + "&fake_pdf=1", '_system')} className='readmore'>Test embed view</btn> */}
            </div>
            {loggedIn && token && (
              <>
                <TotalCoin token={token} />
                <br />
              </>
            )}

            <div className="store-list ">
              {restaurant && (
                <StoreItem
                  data={{
                    image: restaurant?.image,
                    name: restaurant?.name,
                    address: restaurant?.address,
                    time: restaurant?.openingTimes,
                    forceClosed: restaurant?.forceClosed,
                  }}
                  key={restaurant?.id}
                />
              )}
            </div>
            <hr className="separate" />
          </div>

          <div className="menu-content">
            <h2 className="headline-2">{t('menuView.title')}</h2>
            <CategoriesBox data={menu} />
            <div className="menu" onClick={() => setShow(!show)}>
              {menu
                .filter(item => !item?.isHidden)
                .map((item, i) => {
                  if (item.type === 'editableText') {
                    return <EditableText item={item} i={i} menu={menu} />
                  }

                  if (shouldAcceptPreOrder && preordersIncart && !item.isIncludeSurcharge) {
                    return null
                  }

                  if (shouldAcceptPreOrder && nonPreOrdersIncart && item.isIncludeSurcharge) {
                    return null
                  }

                  return (
                    <MenuItem
                      onClick={() => {
                        setCurrentItem({
                          ...item,
                          quantity: 1,
                        })
                      }}
                      data={{
                        id: item.id,
                        image: item.image,
                        title: item.title,
                        description: item.description,
                        translations: item.translations,
                        titleFI: item.titleFI, // deprecraing
                        descriptionFI: item.descriptionFI, // deprecraing
                        titleSE: item.titleSE, // deprecraing
                        descriptionSE: item.descriptionSE, // deprecraing
                        price:
                          allSaleTo !== null && typeof allSaleTo !== 'undefined' ? `${allSaleTo}` : `${item.price}`,
                        oldPrice:
                          allSaleTo !== null && typeof allSaleTo !== 'undefined'
                            ? `${item.price}`
                            : item.originalPrice
                            ? `${item.originalPrice}`
                            : '',
                        status: item.outOfStock ? 0 : 1,
                        coin: item?.rewardingCoins,
                        hiddenTime: item?.hiddenTime,
                        hiddenFrom: item?.hiddenFrom,
                        specialAvailability: item?.specialAvailability,
                        forceClosed: restaurant?.forceClosed,
                      }}
                      key={item.id}
                    />
                  )
                })}
            </div>
          </div>
        </div>

        {cartList.length > 0 ? (
          <div className="btn-checkout" onClick={onClickViewOrder}>
            <div className="order-info">
              <div className="amount">{cartList.length < 10 ? '0' + cartList.length : cartList.length}</div>
              <div className="text">{t('menuView.viewOrder')}</div>
            </div>
            <div className="order-price">{withCurrencySymbol(cartContext.totalPrice)}</div>
          </div>
        ) : null}
      </PageLayout>

      {/* POPUP */}
      <div
        className={'popup-overlay' + (currentItem ? ' show' : '')}
        onClick={e => e.target.classList.contains('show') && setCurrentItem(null)}
      >
        <div className="popup-container">
          <a className="btn-close" onClick={() => setCurrentItem(null)}>
            <IconClose />
          </a>
          {currentItem?.id && (
            <MenuItemView
              coupons={coupons}
              item={currentItem}
              allSaleTo={allSaleTo}
              returnItembyId={returnItembyId}
              onChange={(extras, options) => {
                setCurrentItem({
                  ...currentItem,
                  chosenExtras: extras,
                  chosenOptions: options,
                })
              }}
            />
          )}
          <div className={IsiPhone() ? 'popup-action ios' : 'popup-action'}>
            {restaurant?.forceClosed ? (
              <div className="btn-addtocart">Temporary closed for orders</div>
            ) : (
              <>
                <InputAmount
                  onChange={quantity =>
                    setCurrentItem({
                      ...currentItem,
                      quantity,
                    })
                  }
                  key={currentItem?.id}
                />
                <a className="btn-addtocart" onClick={addItemToCart}>
                  <span className="text">{t('menuView.addToOrder')}</span>

                  <span className="amount">
                    {withCurrencySymbol(
                      currentItem?.price && `${new Big(totalPricePerItem).mul(currentItem.quantity).toFixed(2)}`
                    )}
                  </span>
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MenuView
