import React from 'react'

const InputAmount = ({ onChange }) => {
  const [value, setValue] = React.useState(1)

  const onSetValue = React.useCallback(
    newValue => {
      setValue(newValue)
      onChange(newValue)
    },
    [setValue, onChange]
  )

  return (
    <div className="input-amount">
      <a className="btn-adjust">
        <i className="fa-solid fa-minus" onClick={() => onSetValue(value === 1 ? 1 : value - 1)}></i>
      </a>
      <input type="number" name="" id="" value={value} readOnly={true} />
      <a className="btn-adjust">
        <i className="fa-solid fa-plus" onClick={() => onSetValue(value + 1)}></i>
      </a>
    </div>
  )
}

export default InputAmount
