import React, { useState } from 'react'
import InputAmount from './InputAmount'
import { useTranslation } from 'react-i18next'

const AddButton = ({ onClick }) => {
  const { t, i18n } = useTranslation()

  return (
    <div className="add-input-amount">
      <a className="btn-add" onClick={() => onClick()}>
        <i className="fa-solid fa-plus"></i>
        <span>{t('orderView.addButton')}</span>
      </a>
      <InputAmount />
    </div>
  )
}

export default AddButton
