import React from 'react'
import './Carousel.css'

const Carousel = ({}) => {
  return (
    <section className="carousel" aria-label="Gallery">
      <div className="img-box">
        <img src="https://i.imgur.com/0xYhpSe.png" />
      </div>
      <div className="img-box">
        <img src="https://i.imgur.com/jDgQA1l.png" />
      </div>
      <div className="img-box">
        <img src="https://i.imgur.com/0xYhpSe.png" />
      </div>
    </section>
  )
}

export default Carousel
