import React from 'react'
import { getAvailabilityText } from '../../Utils/CalculateAvailability'

export function UnavailableOverlay(props) {
  return (
    <div className="unavailable">
      <p> {getAvailabilityText(props.props?.data?.specialAvailability)}</p>
    </div>
  )
}
