const JAVASCRIPT_CHANNEL = 'FLUTTER'

export const getJsChannel = () => {
  try {
    return eval(JAVASCRIPT_CHANNEL)
  } catch (error) {
    return undefined
  }
}

export const onCallBack = payload => {
  const ch = getJsChannel()
  console.log('callback', payload)
  if (!ch) {
    console.error('Failed to get channel')
    return
  }

  try {
    ch.postMessage(JSON.stringify(payload))
  } catch (error) {
    console.error(error)
  }
}

const flutterExec = payload => {
  onCallBack({
    action: 'ack',
    data: payload,
  })
}

window.flutterExec = flutterExec
