import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import { Link, useNavigate, useSearchParams, generatePath, useLocation, createSearchParams } from 'react-router-dom'
import PageLayout from '../Components/Layout/PageLayout'
import { CartContext, useCartContext } from '../Contexts/CartContext'
import { useAuthContext } from '../Contexts/AuthContext'
import { getJsChannel, onCallBack } from '../Utils/FlutterChannel'
import { getReceipt, getTransactionByToken } from '../Api/MenuApi'
import Loading from '../Components/Loading/Loading'
import { useBackListener } from '../Hooks/UseBlocker'
import { useTranslation, Trans } from 'react-i18next'
import OrderStatusCircle from '../Components/OrderStatusCircle/OrderStatusCircle'
import { useErrorContext } from '../Contexts/ErrorContext'
import useCurrency from '../Hooks/useCurrency'

const PaymentSuccessView = () => {
  const { loggedIn } = useAuthContext()
  const { clearCache } = useCartContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const [transaction, setTransaction] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const [receiptId, setReceiptId] = useState(null)
  const [receipt, setReceipt] = useState(null)
  const [statusChanging, setStatusChanging] = useState(false)
  const { info } = useErrorContext()
  const { currencyCode } = useCurrency()
  // useBackListener(({ location: l }) => {
  //   console.log("Navigated Back", { l });
  //   navigate({
  //     pathname: location.pathname,
  //     search: searchParams.toString(),
  //   }, { replace: true });
  // });

  const printPDF = () => {
    if (getJsChannel()) {
      onCallBack({
        action: 'print',
        data: receipt,
      })
      return
    }

    if (!window?.jspdf?.jsPDF) return
    const jsPDF = window.jspdf.jsPDF
    const printDoc = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'a5',
    })
    console.log('printDoc', printDoc)
    printDoc.html(document.getElementById('receipt-print'), {
      callback: function (doc) {
        doc.save('receipt.pdf')
      },
    })
  }

  useEffect(() => {
    clearCache()
    const transactionToken = searchParams.get('transaction_token') || `vivawallet?t=${searchParams.get('t')}&s=${searchParams.get('s')}`
    const fetchTransaction = async () => {
      try {
        const resp = await getTransactionByToken(transactionToken)

        let plannedAt

        if (resp?.data?.plannedAt) {
          console.log('Planend at: ', resp?.data?.plannedAt)
          plannedAt = new Date(resp?.data?.plannedAt?._seconds * 1000)
        }
        const _tr = {
          orderId: resp?.data?.id,
          status: resp?.data?.status,
          orderNumber: resp?.data?.orderId,
          restaurantName: resp?.data?.restaurant?.name,
          timeReady: resp?.data?.timeReady ? parseInt(resp?.data?.timeReady / 60) : undefined,
          requestType: resp?.data?.requestType,
          plannedAt: plannedAt,
          paymentStatus: resp?.data?.paymentStatus,
          paymentMethods: resp?.data?.paymentMethods,
          // stripe: resp?.data?.,
        }
        if (transaction?.status !== _tr?.status) {
          setStatusChanging(true)
        }
        setTransaction(_tr)
        setReceiptId(resp?.data?.receiptId)
        // console.log('transaction', resp?.data);
        return _tr
      } catch (error) {
        info('error', error.message)
      } finally {
        setTimeout(() => {
          setStatusChanging(false)
        }, 1500)
      }
    }

    const runInterval = async (callback = false) => {
      const data = await fetchTransaction()
      if (callback) {
        onCallBack({
          action: 'order_success',
          data,
        })
      }
    }
    const intervalPID = setInterval(async () => {
      await runInterval()
    }, 10000)
    runInterval(true)
    // fetchTransaction().then((data) => {
    //   onCallBack({
    //     action: 'order_success',
    //     data,
    //   });
    // });
    return () => {
      if (intervalPID) {
        clearInterval(intervalPID)
      }
    }
  }, [])

  useEffect(() => {
    if (!receiptId) return
    getReceipt(receiptId).then(resp => {
      setReceipt(resp?.data?.[0])
    })
  }, [receiptId])

  if (loggedIn) {
    return <Loading />
  }

  return (
    <>
      <PageLayout type={'order'} backTo={''}>
        <div className="payment-status">
          <OrderStatusCircle
            requestType={transaction?.requestType}
            status={transaction?.status}
            orderNumber={transaction?.orderNumber}
            delivery={transaction?.timeReady}
          />
          <h1 className="headline-1">
            {transaction?.requestType == 'PRE ORDER' ? (
              <>Your Pre order has been logged for {transaction?.plannedAt.toString()} </>
            ) : (
              {
                new: t('orderSuccessView.new'),
                inprogress: t('orderSuccessView.inprogress') + ' ' + transaction?.timeReady + ' Minutes',
                ready: t('orderSuccessView.ready'),
                archived: t('orderSuccessView.archived'),
                cancelled: t('orderSuccessView.cancelled'),
                error: t('orderSuccessView.error'),
              }[transaction?.status]
            )}
          </h1>
          <div className="message">
            {transaction?.requestType == 'PRE ORDER' ? (
              <></>
            ) : (
              {
                new: (
                  <>
                    {t('orderSuccessView.orderSentTo')} {transaction?.restaurantName}. <br />
                    {
                      transaction?.paymentStatus === "Incomplete" && (transaction?.paymentMethods || []).includes("cash") ? (
                        <>
                          {t('orderSuccessView.orderByCash')} <br />
                        </>
                      ) : null
                    }
                  </>
                ),
                inprogress: (
                  <>
                    {t('orderSuccessView.orderInProgress')} {transaction?.restaurantName}. <br />
                    {
                      transaction?.paymentStatus === "Incomplete" && (transaction?.paymentMethods || []).includes("cash") ? (
                        <>
                          {t('orderSuccessView.orderByCash')} <br />
                        </>
                      ) : null
                    }
                  </>
                ),
                ready: (<>
                  {t('orderSuccessView.readyDesc')}. <br />
                  {
                      transaction?.paymentStatus === "Incomplete" && (transaction?.paymentMethods || []).includes("cash") ? (
                        <>
                          {t('orderSuccessView.orderByCash')} <br />
                        </>
                      ) : null
                    }
                </>),
                archived: <></>,
                cancelled: (
                  <>
                    {t('orderSuccessView.orderCancelled')} {transaction?.restaurantName} <br />
                    {t('orderSuccessView.orderCancelledUnableToFullfil')} <br />
                    <b>{t('orderSuccessView.orderCancelledRefunded')}</b>
                  </>
                ),
                error: (
                  <>
                    {t('orderSuccessView.orderSentTo')} {transaction?.restaurantName}
                  </>
                ),
              }[transaction?.status]
            )}
          </div>


          {receipt && transaction?.status !== 'new' && transaction?.status !== 'cancelled' && (
            <>
              <div className="receipt-container" id="receipt-print">
                <div className="top">{moment.utc(receipt.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</div>
                <div className="separator"></div>
                <div className="bottom">
                  <div className="order-id">Order ID: {receipt.friendlyId}</div>
                  <div className="rows">
                    <div>
                      {(receipt?.rows || []).map((row, rowIndex) => (
                        <div className="row" key={rowIndex}>
                          <div>{row?.[0]}</div>
                          <div>{row?.[1]}</div>
                        </div>
                      ))}
                    </div>
                    <p>All amounts in {currencyCode}</p>
                  </div>
                </div>
              </div>
              <button className="save-receipt" href="#" onClick={printPDF}>
                {t('orderSuccessView.saveReceipt')}
              </button>
              <br />
              <br />
              <br />
            </>
          )}
        </div>

        <Link
          className="btn-continue"
          to={() => {
            if (transaction) {
              onCallBack({
                action: 'order_success',
                data: transaction,
              })
            }
          }}
        >
          {statusChanging
            ? '...'
            : {
                new: t('orderSuccessView.status.new'),
                inprogress: t('orderSuccessView.status.inprogress'),
                ready: t('orderSuccessView.status.ready'),
                archived: t('orderSuccessView.status.archived'),
                cancelled: t('orderSuccessView.status.cancelled'),
                error: t('orderSuccessView.status.error'),
              }[transaction?.status]}
        </Link>
      </PageLayout>
    </>
  )
}

export default PaymentSuccessView
