import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { onCallBack } from '../../Utils/FlutterChannel'

const HeaderHome = props => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const onBack = async () => {
    if (!props.backTo) {
      onCallBack({
        action: 'back',
        data: {},
      })
      return
    }

    if (props?.backTo)
      navigate({
        pathname: props?.backTo,
        search: searchParams.toString(),
      })
  }

  return (
    <header className="header-home">
      <div className="banner">
        <img src={props.bannerSrc} alt="" />
      </div>
      <div style={{ cursor: 'pointer' }} className="btn-back" onClick={onBack}>
        <i className="fa-solid fa-angle-left"></i>
      </div>
    </header>
  )
}

export default HeaderHome
