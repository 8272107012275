import moment from 'moment'

const TIME_INTERVAL = 30

// Making my own Typescript
function ArgumentChecker(argument, legalArguments) {
  if (!legalArguments.includes(argument))
    console.error(`Illegal argument: ${argument} \n Legal arguments: ${legalArguments.join(', ')}`)
}

/**
 * @param {"today" | "tomorrow" | "now"} day - the availability json of an item
 * @param {*} specialAvailability - the availability json of an item
 * @returns {Boolean}
 */
function isAvailable(query, specialAvailability) {
  const legalArguments = ['now', 'today', 'later-today', 'tomorrow']
  ArgumentChecker(query, legalArguments)

  const targetTime = moment()
  if (query === 'tomorrow') targetTime.add(1, 'day')
  if (query === 'later-today') return isAvailableLaterToday(specialAvailability)
  if (query === 'now') return isAvailableNow(specialAvailability)

  return Boolean(getAvailability(specialAvailability, query).length)
}

/**
 * @param {*} availabilityList - the availability list in format [{ label: "09:00", value: Moment }, ...]
 * @returns {Boolean}
 */
function isAvailableNow(availabilityList) {
  const now = moment()

  const firstTime = availabilityList?.[0]
  const lastTime = availabilityList?.[availabilityList.length - 1]

  if (firstTime == undefined || lastTime == undefined) return false
  if (now.isSameOrAfter(firstTime?.value) && now.isSameOrBefore(lastTime?.value)) return true
  return false
}

function isAvailableLaterToday(specialAvailability) {
  const now = moment()
  const day = now.day()
  let result = false

  const availabilityList = getAvailability(specialAvailability, 'today')

  if (!availabilityList[day]) return false
  availabilityList.forEach(({ value }) => {
    if (value.isAfter(now)) result = true
  })

  return result
}

function getRemainder(date) {
  let remainder = TIME_INTERVAL - (date.minute() % TIME_INTERVAL)
  if (remainder === 0) remainder = TIME_INTERVAL

  return remainder
}

/**
 * @param {*} specialAvailability - the availability json of an item
 * @param {"today" | "tomorrow"} day  - day
 * @returns {{ label: string, value: Moment, isRelevant: Boolean }[]}
 */
function getAvailability(specialAvailability, day) {
  const legalArguments = ['today', 'tomorrow']
  ArgumentChecker(day, legalArguments)

  if (!specialAvailability) return undefined
  const now = moment()

  if (day === 'tomorrow') now.add(1, 'd')

  const availability = specialAvailability[now.get('day')]

  if (!availability) return []

  let startDate = moment(now)
  startDate.hour((availability?.from || "08:00").slice(0, 2))
  startDate.minute((availability?.from || "08:00").slice(3, 5))
  startDate.second(0)
  startDate.millisecond(0)

  let endDate = moment(now)
  endDate.hour((availability?.to || "11:00").slice(0, 2))
  endDate.minute((availability?.from || "11:00").slice(3, 5))
  endDate.second(0)
  endDate.millisecond(0)

  if (startDate.isAfter(endDate)) [startDate, endDate] = [endDate, startDate]

  const result = []

  // in case item has an excluding availability
  if (!availability.available) {
    const dayStart = moment(now).startOf('day')
    const dayEnd = moment(now).endOf('day')

    while (dayStart.isSameOrBefore(startDate)) {
      result.push({
        label: dayStart.format('HH:mm'),
        value: moment(dayStart),
        isRelevant: moment().isBefore(dayStart),
      })

      const remainder = getRemainder(dayStart)

      dayStart.add(remainder, 'm')
    }
    while (endDate.isSameOrBefore(dayEnd)) {
      result.push({
        label: endDate.format('HH:mm'),
        value: moment(endDate),
        isRelevant: moment().isBefore(endDate),
      })

      const remainder = getRemainder(endDate)

      endDate.add(remainder, 'm')
    }
    return result
  }

  while (startDate.isSameOrBefore(endDate)) {
    result.push({
      label: startDate.format('HH:mm'),
      value: moment(startDate),
      isRelevant: moment().isBefore(startDate),
    })

    const remainder = getRemainder(startDate)

    startDate.add(remainder, 'm')
  }
  return result
}

/**
 * @param {*} itemArr - the availability json of an item
 * @param {"today" | "tomorrow"} day  - To day
 * @returns {{ label: string, value: Moment, isRelevant: Boolean }[]}
 */
function getOverlappingAvailability(itemArr, day) {
  const legalArguments = ['today', 'tomorrow']
  ArgumentChecker(day, legalArguments)

  let overlap
  for (const item of itemArr) {
    const avail = getAvailability(item.specialAvailability, day)
    if (avail) {
      overlap = avail
      break
    }
  }

  if (!overlap) return undefined

  for (const { specialAvailability } of itemArr) {
    if (!specialAvailability) continue
    const availability = getAvailability(specialAvailability, day)
    if (!availability[0] || !availability[availability.length - 1]) return []

    while (availability?.[0]?.value?.isAfter(overlap?.[0]?.value)) {
      overlap.shift()
      if (overlap.length === 0) break
    }
    while (availability?.[availability.length - 1]?.value?.isBefore(overlap?.[overlap.length - 1]?.value)) {
      overlap.pop()
      if (overlap.length === 0) break
    }
  }

  return overlap
}

function getAvailabilityFromNow(availabilityList) {
  const now = moment()

  return availabilityList.filter(time => time.value.isAfter(now))
}

/**
 * This function converts times in past
 * from 10:00 10:30 11:00 11:30
 * to 10:00 - 11:30
 */
function trimPast(availabilityList) {
  const pastArr = []

  for (let i = 0; i < availabilityList.length; i++) {
    const timing = availabilityList[i]

    // Has already been trimmed
    if (timing.value === null) return availabilityList
    if (!timing.isRelevant) {
      pastArr.push(timing)
    } else break
  }

  if (pastArr.length === 0) return availabilityList

  const newTiming = {
    value: null,
    isRelevant: false,
    label: `${pastArr[0].label} - ${pastArr[pastArr.length - 1].label}`,
  }

  availabilityList.splice(0, pastArr.length, newTiming)
  return availabilityList
}

export { getAvailability, getOverlappingAvailability, getAvailabilityFromNow, isAvailable, trimPast }
