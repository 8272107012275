import React from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useAuthContext } from '../../Contexts/AuthContext'
import { hasNotch } from '../../Utils/Screen'

const HeaderPage = props => {
  const { isNotch } = useAuthContext()
  const [searchParams, setSearchParams] = useSearchParams()
  // console.log('hasNotch', hasNotch());

  return (
    <header
      className="header-page"
      style={{
        paddingTop: String(isNotch) === 'true' || hasNotch() ? '50px' : '20px',
      }}
    >
      <Link className="btn-back" to={{ pathname: props?.backTo, search: searchParams.toString() }} replace={true}>
        <i className="fa-solid fa-angle-left"></i>
      </Link>
      <h1 className="headline-1 page-name">{props?.pageName}</h1>
    </header>
  )
}

export default HeaderPage
