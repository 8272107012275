import React, { useState, useMemo, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { IconCoinSmall, IconTrash } from '../Icons/Icons'
import CalculateAvailability from '../../Utils/CalculateAvailability'
import { UnavailableOverlay } from './UnavailableOverlay'
import { useSubmenuContext } from '../../Contexts/SubMenuContext'
import { useTranslation } from 'react-i18next'
import { ToLocalizedString } from '../../Utils/ToLocalizedString'
import useCurrency from '../../Hooks/useCurrency'

const MenuItem = props => {
  const itemRef = React.createRef()

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const [isAvailableNow, setIsAvailableNow] = useState(true)
  const { t, i18n } = useTranslation()
  const { shouldAcceptPreOrder } = useSubmenuContext()
  const { withCurrencySymbol } = useCurrency()

  const minSwipeDistance = 30

  const onTouchStart = e => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = e => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) {
      itemRef?.current?.classList.add('active')
    } else if (isRightSwipe) {
      itemRef?.current?.classList?.remove('active')
    } else {
    }
  }

  const hidden = useMemo(() => {
    const hiddenTime = props?.data?.hiddenTime ? props.data.hiddenTime : 0
    const hiddenFrom = props?.data?.hiddenFrom
    if (hiddenTime === -1) {
      return true
    } else if (hiddenTime === 0) {
      return false
    } else {
      if (hiddenFrom) {
        if (moment.unix(hiddenFrom).add(hiddenTime, 'hour').isSameOrBefore(moment())) {
          return false
        }
      }
      return true
    }
  }, [props.data?.hiddenTime, props.data?.hiddenFrom])

  useEffect(() => {
    let avail = CalculateAvailability(props?.data?.specialAvailability)
    setIsAvailableNow(avail)
  })

  let clickHandler = () => {
    if (hidden) {
      return
    }
    if (props?.data?.specialAvailability) {
      if (CalculateAvailability(props?.data?.specialAvailability) == false && !shouldAcceptPreOrder) {
        return
      }
    }
    props?.onClick()
  }

  // If this item is unavailable and the CMS says hide when unavailable, do not render item
  if (props?.data?.specialAvailability?.hideWhenUnavailable) {
    if (!CalculateAvailability(props?.data?.specialAvailability)) {
      return
    }
  }

  return (
    <>
      <div
        ref={itemRef}
        className={`menu-item ${props?.data?.selected ? 'selected' : ''} ${hidden ? 'hidden' : ''} `}
        onTouchStart={props?.data?.swipeAction && onTouchStart}
        onTouchMove={props?.data?.swipeAction && onTouchMove}
        onTouchEnd={props?.data?.swipeAction && onTouchEnd}
        onClick={clickHandler}
      >
        {!CalculateAvailability(props?.data?.specialAvailability) && !shouldAcceptPreOrder && (
          <UnavailableOverlay props={props} />
        )}

        {props?.data?.image ? (
          <div className="item-img">
            <img src={props?.data?.image} alt="" />
          </div>
        ) : (
          <div className="no-image-spacer" />
        )}
        <div
          className={
            ToLocalizedString(props?.data, 'description')?.length > 0 ? 'item-caption' : 'item-caption no-description'
          }
        >
          <h3 className="item-name">{ToLocalizedString(props?.data, 'title')}</h3>
          <div className="item-desc">{ToLocalizedString(props?.data, 'description')}</div>
          <div className="item-price">
            <div className="amount">
              {props?.data?.oldPrice && <span className="old">{withCurrencySymbol(props?.data?.oldPrice)}</span>}
              {withCurrencySymbol(props?.data?.price)}
              {shouldAcceptPreOrder && !isAvailableNow && (
                <span className="pre-order">{t('menuItemView.preOrderChip')}</span>
              )}
            </div>
            <div className="order">
              {props?.data?.status === 0 && <div className="status out-of-order">Out of Order</div>}
              {props?.data?.coin && (
                <a className="coin-bonus">
                  <IconCoinSmall /> <span>+{props?.data?.coin}</span>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

MenuItem.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.string,
    oldPrice: PropTypes.string,
    status: PropTypes.number,
    coin: PropTypes.number,
    selected: PropTypes.bool,
    swipeAction: PropTypes.bool,
  }),
  fnRemove: PropTypes.func,
}

export default MenuItem
