import moment from 'moment'
import './progressBar.scss'

type ProgressBarProps = {
  progress: number
  timeLeft?: number
}

function ProgressBar({ progress, timeLeft }: ProgressBarProps) {
  let color = 'green'
  if (progress < 50) {
    color = 'orange'
  }
  if (progress < 20) {
    color = '#eb4034'
  }

  return (
    <div className="order-status-progress-bar-container">
      <div className="order-status-progress-bar" style={{ width: progress + '%', backgroundColor: color }}></div>
      <div className="countdown">{DisplayCountDown(timeLeft)}</div>
    </div>
  )
}

const DisplayCountDown = (minutes: any) => {
  // Determine the sign and use absolute value for calculations
  const sign = minutes < 0 ? '-' : ''
  const absoluteMinutes = Math.abs(minutes)

  // Calculate whole minutes and remaining seconds
  const wholeMinutes = Math.floor(absoluteMinutes)
  const seconds = Math.round((absoluteMinutes - wholeMinutes) * 60)

  // Format minutes and seconds with leading zeros if needed
  const paddedMinutes = wholeMinutes.toString().padStart(2, '0')
  const paddedSeconds = seconds.toString().padStart(2, '0')

  // Combine sign, minutes, and seconds into the final format
  return `${sign}${paddedMinutes}:${paddedSeconds}`
}

export default ProgressBar
