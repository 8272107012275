const ar = {
  translation: {
    orderSuccessView: {
      status: {
        error: 'خطأ',
        cancelled: 'تم إلغاء الطلب',
        archived: 'شكرًا واستمتع!',
        inprogress: 'الطلب قيد التنفيذ',
        new: 'تم إرسال الطلب',

        ready: 'لقد استلمته!',
      },
      error: 'فشل في معالجة الطلب',
      cancelled: 'تم إلغاء الطلب',
      newShort: 'تم الإرسال',
      preOrdered: 'تم إرسال الطلب',
      orderCancelledUnableToFullfil: 'لم نتمكن من قبول طلبك.',
      orderSentTo: 'تم إرسال الطلب إلى ',
      readyDesc: 'استلم طلبك من الكاونتر',
      orderNumber: 'رقم الطلب',
      deliveryEst: 'الانتهاء المتوقع في',
      yourOrderNumberIs: 'رقم طلبك هو #',
      cancelledShort: 'تم الإلغاء',
      orderCancelled: 'تم إرسال طلبك، ولكن للأسف ',
      archivedShort: 'اكتمل',
      ready: 'طلبك جاهز!',
      orderCancelledRefunded: 'سيتم استرداد أموالك',
      archived: 'تم اكتمال الطلب',
      errorShort: 'خطأ',
      new: 'تم تنفيذ طلبك بنجاح!',
      orderInProgress: 'نحن نعمل على طلبك - ',
      saveReceipt: 'احتفظ بالإيصال',
      inprogress: 'سيتم تجهيز طلبك في',
    },
    orderView: {
      dinein: 'تناول الطعام هنا',
      notePlaceholder: 'أضف طلبات خاصة أو حساسيات إلخ...',
      reccomendations: 'اقتراحات',
      addButton: 'إضافة',
      takeaway: 'طلب للذهاب',
      preorder: 'طلب مسبق للغرفة',
      roomorder: 'طلب للغرفة',
      tableorder: 'طلب للطاولة',
      roomOrderPlaceholder: 'رقم الغرفة',
      tableOrderPlaceholder: 'رقم الطاولة',
      tableFormatError: 'يجب إدخال رقم الطاولة.',
      roomFormatError: 'يجب إدخال رقم الغرفة.',
      forExample: 'مثلاً',
      delivery: 'توصيل',
      consumerName: 'الاسم الأول (اختياري)',
      consumerNameForced: 'يرجى كتابة اسمك...',
      gotoCheckout: 'الانتقال إلى الخروج',
      title: 'طلبك',
      alcoholError: 'عذرًا، لا تسمح القوانين الفنلندية ببيع الكحول للخروج 😔',
      couponCode: 'رمز الكوبون',
      pickTime: 'اختر وقتًا',
      today: 'اليوم',
      tomorrow: 'غدًا',
      required: 'مطلوب',
    },
    paymentView: {
      phoneNumber: 'رقم الهاتف',
      useCoinDesc: 'استخدم عملتك {{symbol}} لدفع طلبك',
      payButtonLoading: 'جاري الدفع...',
      errorNoPhone: 'يرجى إدخال رقم هاتفك',
      balance: 'الرصيد',
      marketingConsent: 'أسمح لـ {{company}} بالاتصال بي بعروض خاصة واتصالات تسويقية أخرى.',
      tosConsent: 'بإدخال رقم هاتفي، أقبل <1>بشروط الخدمة</1>',
      useCoin: 'استخدم العملات',
      payButton: 'تأكيد الطلب',
      getCoin: 'احصل على العملات',
      payNow: 'ادفع الآن',
      payNowDesc: 'ادفع بواسطة البطاقة / Apple pay / Google Pay',
      smartum: 'Smartum',
      title: 'الدفع',
      getCoinDesc: 'احصل على {{percentage}}% من قيمة طلبك مرة أخرى كعملة {{symbol}}',
      alcoholPopUptitle: 'العناصر المقيدة بالعمر',
      alcoholPopUpAgeRestrict: 'أنت على وشك شراء منتج مقيد بالعمر. كن مستعدًا لعرض هويتك للموظفين',
      alcoholPopUpNoRefund: 'لن نسترد مشترياتك في حال عدم تقديمك للموظفين هويتك.',
      alcoholPopUpConfirm: 'الرجاء تأكيد أنك مسموح لك بشراء هذه العناصر بشكل قانوني.',
      alcoholPopUpButtonYesAllowed: 'نعم، مسموح لي',
      alcoholPopUpButtonCancel: 'إلغاء',
      paying: 'جاري الدفع...',
      confirmPayment: 'تأكيد الدفع',
      paymentMethods: {
        cash: {
          title: 'نقدًا',
          description: 'ادفع نقدًا للكاشير',
        },
      },
    },
    menuView: {
      title: 'القائمة',
      viewOrder: 'عرض الطلب',
      restaurantClosed: 'مغلق اليوم',
      addToOrder: 'إضافة إلى الطلب',
    },
    menuItemView: {
      preOrderChip: 'الطلبات المسبقة فقط',
      preOrderExplanation: 'هذا الوجبة يمكن طلبها مسبقًا فقط، يمكنك تحديد متى تريد تسليمها أثناء الخروج.',
      extras: 'إضافات:',
      alcoholWarning:
        'يرجى ملاحظة أنه يمكن بيع الكحول فقط للأشخاص الذين تزيد أعمارهم عن 18 عامًا. كن مستعدًا لعرض هويتك.',
    },
    general: {
      coins: 'عملات',
      coin: 'عملة',
      update: {
        updateTitle: 'تحديث مطلوب',
        updateText: 'أنت تستخدم نسخة أقدم من Mynt، يرجى الضغط على الزر أدناه للتحديث',
        updateButton: 'تحديث',
      },
      downloadApp: {
        downloadTitle: 'متاح فقط على Mynt',
        downloadText: 'للحصول على هذا المكافأة، يجب عليك استخدام تطبيق Mynt 🙂',
        downloadButton: 'تنزيل التطبيق',
      },
    },
    couponView: {
      checkingCode: 'جاري التحقق من الرمز 🤟',
      errorTitle: 'أوه لا 🤨',
      errorText1: 'حدث خطأ ما، ربما تلقيت هذا العرض بالفعل',
      errorText2: 'إذا لم تكن قد تلقيته بعد، يرجى إعادة تشغيل تطبيق Mynt والمحاولة مرة أخرى',
      successTitle: 'لقد حصلت للتو على Myntd!',
      explainerText:
        'عندما تطلب وجبتك من خلال Mynt، يمكنك الحصول على 10% من قيمة طلبك مرة أخرى كعملة، لقد قمنا للتو بترقية طريقة إنفاق أموالك',
      openMenuButton: 'فتح القائمة',
      closeButton: 'إغلاق',
      td: {
        youGot: 'لقد حصلت على',
        coinName: 'عملات TD!',
        bannerText: 'Akseli Herlevi و Iman Gharagozlu',
      },
    },
  },
  Any: 'أي',
  'Not empty': 'غير فارغ',
  '1-4 digits': '1-4 أرقام',
  'One digit and numbers': 'رقم وأرقام',
  'Two digits and numbers': 'رقمين وأرقام',
}

export default ar
