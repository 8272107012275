import { isAvailable } from '../Utils/AvailabilityHelper'
import { getRestaurantMenu } from './MenuApi'
import CalculateAvailability from '../Utils/CalculateAvailability'
import _ from 'lodash'

const isDuplicate = (item, cartList) => {
  for (let element of cartList) {
    if (element?.item && element.item.id === item.id) return true
    if (element?.id === item.id) return true
  }

  return false
}

// Returns array of recommendations
const getRecommendations = async (amount, cartList, preordersIncart, recommendationsList) => {
  const response = await getRestaurantMenu()
  const menu = response.data

  const filteredResult = menu.filter(item => {
    //const isPreOrder = item.specialAvailability

    if (!item.shouldSuggested) return false
    if (isDuplicate(item, cartList)) return false
    if (isDuplicate(item, recommendationsList)) return false
    if (item.specialAvailability?.hideWhenUnavailable && !isAvailable('now', item.specialAvailability)) return false
    const isItemAvailable = CalculateAvailability(item.specialAvailability)
    if (!isItemAvailable) return false
    //if (preordersIncart && isPreOrder) return true
    //if (!preordersIncart && !isPreOrder) return true
    console.log(item)
    //return false
    return true
  })

  const shuffledResult = _.shuffle(filteredResult)

  return shuffledResult.slice(0, amount)
}

export { getRecommendations }
