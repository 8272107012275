import React, { useState, useEffect } from 'react'
import moment from 'moment'
import getLocalizedString from '../../../../Utils/getLocalizedString'
import { isSameExtra } from '../../../../Utils/Misc'
import ProgressBar from '../ProgressBar/ProgressBar'

type OrderCardProps = {
  transaction: any
  consumerView?: boolean
  include?: string[]
  setHasHidden?: any
  canHide?: boolean
  resetHidden?: boolean
  showAsList?: boolean
  classNames?: string
  style?: any
  kitchenClassNames?: string
  restaurant?: any
}

export default function OrderCard({
  transaction,
  consumerView,
  include,
  setHasHidden,
  canHide,
  resetHidden,
  showAsList,
  classNames,
  style,
  kitchenClassNames,
  restaurant,
}: OrderCardProps) {
  let { createdAt, timeReady } = transaction
  timeReady = Number(timeReady)
  const shouldShowTimePrediction = restaurant?.config?.enableProcessingTimeCalculation && !Number.isNaN(timeReady)

  const [clickCounter, setClickCounter] = useState(0)
  const [counter, setCounter] = useState(
    moment().unix() - (createdAt?._seconds ? createdAt._seconds : moment(createdAt).unix())
  )

  const [progress, setProgress] = useState(100)
  // time left in mins
  const [timeLeft, setTimeLeft] = useState(0)

  // todo calculate from paid timestamp
  console.log(transaction)

  const updateClocks = () => {
    const readyMoment = moment(createdAt).add(timeReady, 'minutes')
    const timeLeft = Number((readyMoment.unix() - moment().unix()) / 60)

    console.log(timeReady, timeLeft)

    setTimeLeft(timeLeft)
    setCounter(moment().unix() - (createdAt?._seconds ? createdAt._seconds : moment(createdAt).unix()))
    if (timeLeft <= 0) {
      setProgress(0)
    } else {
      setProgress((timeLeft / timeReady) * 100)
    }
  }
  useEffect(() => {
    updateClocks()
    const id = setInterval(updateClocks, 1000)
    return () => clearInterval(id)
  }, [createdAt, timeReady])

  useEffect(() => {
    setClickCounter(0)
    setHasHidden(false)
  }, [resetHidden])

  const DisplayUrgencyColors = (counter: any) => {
    // prevent flashing before restaurant loads
    if (!restaurant) return ''

    if (shouldShowTimePrediction) {
      if (timeLeft < 0) {
        return 'red'
      }

      if (timeReady / timeLeft > 4) {
        return 'yellow'
      }

      if (counter < 600 || transaction.status === 'ready') {
        return '' //nothing
      }

      return ''
    } else {
      if (counter < 600 || transaction.status === 'ready') {
        return '' //nothing
      }

      if (counter < 1200) {
        console.log('counter', counter)
        // if takes more than 10mintues and less than 20 show yellow
        return 'yellow'
      }
      // If it takes more then 20 mintues make red
      return 'red'
    }
  }

  const DisplayHide = () => {
    if (canHide && clickCounter > 2) {
      setHasHidden(true)
      return 'hide'
    }
    return ''
  }

  if (consumerView) {
    return (
      <div className={`card-consumer ${classNames ? classNames : ''}`}>
        {transaction?.customer?.displayNameOnCustomerScreen ? (
          <h4>{transaction?.customer?.displayNameOnCustomerScreen}</h4>
        ) : transaction?.customer?.displayNameOnScreen ? (
          <h4>{transaction?.customer?.displayNameOnScreen}</h4>
        ) : (
          <h4>{transaction.orderId}</h4>
        )}
      </div>
    )
  }

  let card = showAsList ? 'listElem' : 'card'

  return (
    <div
      className={`card-kitchen ${card} ${DisplayUrgencyColors(counter)} ${DisplayHide()} ${
        kitchenClassNames ? kitchenClassNames : ''
      }`}
      style={style}
      onClick={e => setClickCounter(clickCounter + 1)}
    >
      {transaction?.customer?.displayNameOnScreen ? (
        <h4>{transaction?.customer?.displayNameOnScreen}</h4>
      ) : (
        <h4>#{transaction.orderId}</h4>
      )}
      {transaction.menu
        .map((menuItem: any, menuItemIndex: any) => {
          if (include && !include.includes(menuItem.item.itemType)) {
            return null
          }

          const extras = menuItem.item.chosenExtras
          const extrasMap: any = {}
          for (let i = 0; i < extras.length; i++) {
            if (extrasMap[getLocalizedString(extras[i], 'name', 'en')] !== undefined) continue
            extrasMap[getLocalizedString(extras[i], 'name', 'en')] = 1
            for (let k = i + 1; k < extras.length; k++) {
              if (isSameExtra(extras[i], extras[k])) {
                extrasMap[getLocalizedString(extras[i], 'name', 'en')] += 1
              }
            }
          }

          const extrasArr = Object.keys(extrasMap).map((key: any) => ({
            ...extras.find((extra: any) => getLocalizedString(extra, 'name', 'en') === key),
            amount: extrasMap[key],
          }))

          const extraText = extrasArr
            .filter((ex: any) => ex.type !== 'otherItem')
            .map((ex: any) => `${ex?.amount ? ex?.amount + ' x' : null} ${getLocalizedString(ex, 'name', 'en')}`)

          return (
            <div key={menuItemIndex} className={`order-card-menu-item ${menuItem?.status}`}>
              <p>
                {menuItem.quantity} x {menuItem.item?.translations?.title?.en || menuItem.item.title}
              </p>
              <ul className="options">
                {menuItem.item.chosenOptions.map((option: any, optionIndex: any) =>
                  Boolean(option?.optionList?.length) ? (
                    <li key={optionIndex}>
                      - {option.optionName}:{' '}
                      {option.optionList.map((o: any) => o?.translations?.name?.en || o.name).join(', ')}
                    </li>
                  ) : null
                )}
              </ul>
              <ul className="extras">
                {extraText?.map((extra: any, extraIndex: any) => <li key={extraIndex}>Extra {extra}</li>)}
              </ul>
            </div>
          )
        })
        .filter((x: any) => x)}
      {transaction.note && <p className="note">{transaction.note}</p>}
      {transaction.requestType !== 'EAT HERE' && <p className="takeaway">{transaction.requestType}</p>}
      <div className={`footer ${shouldShowTimePrediction ? 'no-border' : ''}`}>
        {shouldShowTimePrediction && <ProgressBar progress={progress} timeLeft={timeLeft} />}
        <div className="counter">{DisplayTimer(counter)}</div>
      </div>
    </div>
  )
}

const DisplayTimer = (counter: any) => {
  const formatted = moment.utc(counter * 1000).format('HH:mm:ss')

  return <div>{formatted}</div>
}
