import { request } from './base'

export async function getRestaurantInfo(id) {
  const resp = await request(`/getRestaurantInfo`, 'get', {
    params: {
      id,
    },
  })
  return resp?.data
}

export async function getReceiptOnDay(day, restaurantId) {
  const resp = await request(`/monitor/listReceiptsByDay?restaurantId=${restaurantId}&day=${day}`)
  return resp?.data
}

export async function getRestaurantTransactions(id) {
  const resp = await request(`/restaurants/${id}/transactions`, 'get', {
    'axios-retry': {
      retries: 15,
    },
  })
  return resp?.data
}

export async function getDeviceLinkedRestaurant() {
  const resp = await request(`/restaurants/getDeviceLinkedRestaurant`, 'get')
  return resp?.data
}
