import { forwardRef } from 'react'
import './TextField.scss'

function TextField({ error, ...rest }, ref) {
  return (
    <div className="text-field-wrapper">
      <input type="text" className={`text-field ${error && 'error'}`} {...rest} ref={ref} />
      <span className={`text-field-error-label`}>{error}</span>
    </div>
  )
}

export default forwardRef(TextField)
