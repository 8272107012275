import React, { createContext, useContext, useEffect, useState } from 'react'
import { getAuth, connectAuthEmulator, onAuthStateChanged } from 'firebase/auth'
import { useNavigate, useSearchParams } from 'react-router-dom'
import camelCase from 'camelcase'
import { getRestaurantMenu } from '../Api/MenuApi'
import * as cache from '../Utils/Cache'
import * as Rudder from '../Utils/Rudder'
import { getRestaurantInfo } from '../Api/RestaurantApi'
import { listTokens, listTokensByAnonymous } from '../Api/MenuApi'
import { getMe } from '../Api/UserApi'
// import { useSearchParams } from 'react-router-dom';

export const AuthContext = createContext({})

export function AuthProvider(props) {
  const [loggedIn, setLoggedIn] = useState(false)
  const [token, setToken] = useState()
  const [appVersion, setAppVersion] = useState(true) //@Duc this is the state for app version
  const [restaurant, setRestaurant] = useState()
  const [setting, setSetting] = useState()
  // const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const [ctx, setCtx] = useState(null)
  const [user, setUser] = useState(null)

  const logOut = async () => {
    ;(await cache.get('auth_token')) && cache.del('auth_token')
    ;(await cache.get('ctx')) && cache.del('ctx')
    ;(await cache.get('restaurant_id')) && cache.del('restaurant_id')
    ;(await cache.get('sn')) && cache.del('sn')
    setLoggedIn(false)
    // navigate("/", { replace: true });
  }

  useEffect(() => {
    const initState = async () => {
      const cachedRaw = await cache.get('ctx')
      const cachedRestaurantId = await cache.get('restaurantId')
      const cachedCtx = cachedRaw ? JSON.parse(cachedRaw) : {}
      const restaurantId = searchParams.get('restaurant_id') || cachedCtx?.[camelCase('restaurant_id')]
      const preparedCtx = ['auth_token', 'restaurant_id', 'token_id', 'is_notch', 'sn'].map(key => {
        let newVal
        if (key === 'restaurant_id') {
          newVal = searchParams.get(key) || cachedCtx?.[camelCase(key)]
        } else if (key === 'sn') {
          newVal = searchParams.get(key)
            ? searchParams.get(key)
            : window?.SERIAL_NUMBER
            ? window.SERIAL_NUMBER
            : cachedCtx?.[camelCase(key)]
          if (newVal) {
            console.log('restaurantId', restaurantId)
            if (restaurantId === "c60d5d16-e7fa-4858-8b29-41035dd735cc") {
              newVal = (newVal || '').replace(/(\r\n|\n|\r|\s)/gm, '')
              if (newVal === 'k+okousaula' || newVal === 'k okousaula') {
                newVal = 'kokousaula'
              }
            }
          }
          console.log('params:', key, newVal)
        } else {
          newVal = searchParams.get(key)
            ? searchParams.get(key)
            : window?.SERIAL_NUMBER
            ? window.SERIAL_NUMBER
            : cachedCtx?.[camelCase(key)]
        }
        return {
          [camelCase(key)]: newVal || cachedRestaurantId,
        }
      })
      const newCtx = Object.assign({}, ...preparedCtx)
      // console.log('new', newCtx);
      cache.set('ctx', JSON.stringify(newCtx))
      cache.set('auth_token', newCtx?.authToken)
      if (newCtx?.restaurantId) {
        cache.set('restaurant_id', newCtx?.restaurantId)
      }
      if (newCtx?.sn || newCtx?.restaurantId) {
        cache.set('sn', newCtx?.sn || newCtx?.restaurantId)
      }
      setLoggedIn(newCtx?.authToken ? true : false)
      setCtx(newCtx)
    }
    initState()
    // console.log('search query,', searchParams);
  }, [searchParams])

  useEffect(() => {
    console.log('ctx', ctx)
    const restaurantId = ctx?.restaurantId
    const tokenId = ctx?.tokenId
    const refreshToken = async () => {
      try {
        const restaurantResp = await getRestaurantInfo(restaurantId)
        const restaurant = restaurantResp?.data
        setRestaurant(restaurant)
        setSetting(restaurantResp?.device || {})
        const resp = loggedIn ? await listTokens() : await listTokensByAnonymous()
        const tokens = resp?.data || []
        const supportedTokens = restaurant?.supportedTokens || []
        const foundSupportedTokens = tokens.filter(t => supportedTokens.indexOf(t.symbol) >= 0)
        const token = foundSupportedTokens.find(t => t.id === tokenId)
          ? foundSupportedTokens.find(t => t.id === tokenId)
          : foundSupportedTokens?.[0]
        setToken(token)
      } catch (e) {
        console.error(e)
      }
    }

    if (restaurantId) {
      refreshToken()
    }
  }, [ctx?.restaurantId, loggedIn, ctx?.tokenId])

  useEffect(() => {
    // get me
    if (loggedIn) {
      getMe().then(resp => {
        Rudder.identify(resp?.authUid, resp?.phoneNumber)
        setUser(resp)
      })
    }
  }, [loggedIn])

  console.log('token', token)

  return (
    <AuthContext.Provider
      value={{ setLoggedIn, loggedIn, logOut, token, restaurant, appVersion, user, setting, ...ctx }}
      {...props}
    />
  )
}

export function useAuthContext() {
  return useContext(AuthContext)
}
