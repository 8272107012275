const en = {
  translation: {
    orderSuccessView: {
      status: {
        error: 'Error',
        cancelled: 'Order is cancelled',
        archived: 'Thank you and enjoy!',
        inprogress: 'Order in progress',
        new: 'Order Sent',

        ready: 'I got it!',
      },
      error: 'Failed to process the order',
      cancelled: 'Order is cancelled',
      newShort: 'Sent',
      preOrdered: 'Order sent',
      orderCancelledUnableToFullfil: 'was not able to accept your order.',
      orderSentTo: 'Order has been sent to ',
      orderByCash: 'Please go to cashier to pay for your order.',
      readyDesc: 'Pick up your order from the counter',
      orderNumber: 'Order number',
      deliveryEst: 'Est. completion in',
      yourOrderNumberIs: 'Your order number is #',
      cancelledShort: 'Cancelled',
      orderCancelled: 'Your order was sent, but sadly ',
      archivedShort: 'Completed',
      ready: 'Your order is ready!',
      orderCancelledRefunded: 'Your money is being refunded',
      archived: 'Order Is Completed',
      errorShort: 'Error',
      new: 'Your Order Is successful!',
      orderInProgress: 'We are working on your order - ',
      saveReceipt: 'Save Receipt',
      inprogress: 'Your Order Will Be Ready In',
    },
    orderView: {
      dinein: 'Eat here',
      pickup: 'Pickup',
      notePlaceholder: 'Add special requests, allergies etc...',
      reccomendations: 'Recommendations',
      addButton: 'Add',
      takeaway: 'Takeaway',
      preorder: 'Preorder to room',
      roomorder: 'Order to Room',
      tableorder: 'Order to Table',
      roomOrderPlaceholder: 'Room Number',
      tableOrderPlaceholder: 'Table Number',
      tableFormatError: 'Table number is required.',
      roomFormatError: 'Room number is required.',
      forExample: 'E.g',
      delivery: 'Delivery',
      consumerName: 'First Name (Optional)',
      consumerNameForced: 'Please type in your name...',
      gotoCheckout: 'Goto Checkout',
      title: 'Your order',
      alcoholError: "Sorry finnish law doesn't allow fun, no takeaway alcohol 😔",
      couponCode: 'Coupon Code',
      pickTime: 'Pick a time',
      today: 'Today',
      tomorrow: 'Tomorrow',
      required: 'Required',
    },
    paymentView: {
      phoneNumber: 'Phone number',
      useCoinDesc: 'Use your {{symbol}} Coins to pay for your order',
      payButtonLoading: 'Paying...',
      errorNoPhone: 'Please enter your phone number',
      balance: 'Balance',
      marketingConsent: 'I allow {{company}} to contact me with special offers and other marketing communication.',
      tosConsent: 'By Entering my phone number I accept the <1>terms of service</1>',
      useCoin: 'Use coins',
      payButton: 'Confirm Order',
      getCoin: 'Get coins',
      payNow: 'Pay now',
      payNowDesc: 'Pay by Card / Apple pay / Google Pay',
      smartum: 'Smartum',
      title: 'Payment',
      getCoinDesc: 'Get {{percentage}}% of your order value back as {{symbol}} Coin',
      alcoholPopUptitle: 'Age restricted items',
      alcoholPopUpAgeRestrict:
        'You are about to purchase an age-restricted product. Be prepared to show your ID to the staff',
      alcoholPopUpNoRefund: 'We will not refund your purchase in the case you cannot provide the staff with your ID.',
      alcoholPopUpConfirm: 'Please confirm that you are legally allowed to purchase these items.',
      alcoholPopUpButtonYesAllowed: "Yes, I'm allowed",
      alcoholPopUpButtonCancel: 'Cancel',
      paying: 'Paying...',
      confirmPayment: 'Confirm Payment',
      paymentMethods: {
        cash: {
          title: 'Cash/Epassi',
          description: 'Pay by cash or epassi to cashier',
        },
      },
    },
    menuView: {
      title: 'Menu',
      viewOrder: 'View Order',
      restaurantClosed: 'Closed today',
      addToOrder: 'Add to order',
    },
    menuItemView: {
      preOrderChip: 'Preorders only',
      preOrderExplanation:
        'This meal can only preordered, you are able to select during the checkout when you want it to be delivered to you',
      extras: 'Extras:',
      alcoholWarning:
        'Please note that alcohol can only been sold to people above the age of 18. Be prepared to show your ID.',
    },
    general: {
      coins: 'Coins',
      coin: 'Coin',
      update: {
        updateTitle: 'Update needed',
        updateText: 'You are using an older version of Mynt, please press the button below to update',
        updateButton: 'Update',
      },
      downloadApp: {
        downloadTitle: 'Only available in Mynt',
        downloadText: 'To recieve this reward you need to use mynt app 🙂',
        downloadButton: 'Download App',
      },
    },
    couponView: {
      checkingCode: 'Checking your code 🤟',
      errorTitle: 'Oh no 🤨',
      errorText1: 'Something went wrong, perhaps you already recieved this offer',
      errorText2: "If you haven't, please restart Mynt app and try again",
      successTitle: 'You just got Myntd!',
      explainerText:
        'When you order your meal through Mynt, you can earn 10% back as coins, we just upgraded the way you spend your money',
      openMenuButton: 'Open Menu',
      closeButton: 'Close',
      td: {
        youGot: 'You got',
        coinName: 'TD Coins!',
        bannerText: 'Akseli Herlevi & Iman Gharagozlu',
      },
    },
  },
  Any: 'Any',
  'Not empty': 'Not empty',
  '1-4 digits': '1-4 digits',
  'One digit and numbers': 'One digit and numbers',
  'Two digits and numbers': 'Two digits and numbers',
}

export default en
