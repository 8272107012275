import i18next from 'i18next'

function toLocalizedString(jsonObject: any, key: string, language: string) {
  let defaultValue = jsonObject?.translations?.[key]?.en || jsonObject?.[key]
  let localizedValue = jsonObject?.translations?.[key]?.[language] || jsonObject?.[key + language.toUpperCase()]
  return localizedValue || defaultValue
}

function getLocalizedString(target: any, translationsKey: string, language?: string, fallbackKey?: string) {
  const currentLanguage = language || i18next.language
  if (!fallbackKey) fallbackKey = translationsKey

  return (
    target?.translations?.[translationsKey]?.[currentLanguage] ||
    toLocalizedString(target, fallbackKey || '', currentLanguage)
  )
}

export default getLocalizedString
