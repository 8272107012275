import React, { useState, useEffect, useMemo, useCallback, useLayoutEffect } from 'react'
import { ref } from 'firebase/database'
import { useObject, useObjectVal } from 'react-firebase-hooks/database'
import { useTranslation } from 'react-i18next'
import { debounce, throttle } from 'throttle-debounce'
import { useParams } from 'react-router'
import _ from 'lodash'
import OrderCard from './components/OrderCard/OrderCard'
import { getRestaurantInfo, getRestaurantTransactions } from '../../Api/RestaurantApi'
import { useLocation } from 'react-router-dom'
import { database } from '../../Utils/Firebase'
import { usePrevious } from '../../Utils/Misc'
import useNetworkStatus from '../../Hooks/NetworkStatus'
import './orderStatus.scss'
import AllDayReceiptModal from './components/AllDayReceiptModal/AllDayReceiptModal'
import Modal from './components/Modal/Modal'
import OrderStatusScreenSettings from './components/OrderStatusScreenSettings/OrderStatusScreenSettings'
// import { useAuthContext } from '../Contexts/AuthContext'
import * as cache from '../../Utils/Cache'
import * as Sentry from '@sentry/react'
import useInternetConnectionReport from '../../Hooks/useInternetConnectionReport'
import { useAuthContext } from '../../Contexts/AuthContext'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'

const HARD_REFRESH_INTERVAL = 30 * 60 * 1000

const OrderStatusView = () => {
  const [initedAt, setInitedAt] = useState(+new Date())
  const { t, i18n } = useTranslation()
  const { restaurantId } = useParams()
  const [transactions, setTransactions] = useState([])
  const { setting, sn: serialNumber } = useAuthContext()
  const { isOnline } = useNetworkStatus(
    () => {
      debounceRefreshTransactions()
    },
    setting?.wifiId,
    setting?.wifiPassword
  )
  useInternetConnectionReport(isOnline)
  const [snapshotValue, loading, error] = useObjectVal(ref(database, `transactions/${restaurantId}`))
  const statusTextJson = [
    { title: 'Kiosk orders', inprogress: 'In Progress', done: 'Done' },
    { title: 'Kioskitilaukset', inprogress: 'Valmistetaan', done: 'Valmis' },
    { title: 'Kiosk beställningar', inprogress: 'Förbereder', done: 'Färdig' },
  ]

  // console.log("Json: ", statusTextJson[0])

  const [index, setIndex] = useState(0)
  const [hasHidden, setHasHidden] = useState(false)
  const [resetHidden, setResetHidden] = useState(false)
  const [statusText, setStatusText] = useState(statusTextJson[0])
  const [allDayReceiptModalShow, setAllDayReceiptModalShow] = useState(false)
  const [orderStatusSettingsScreenShow, setOrderStatusSettingsScreenShow] = useState(false)
  const [ordersPerRow, setOrdersPerRow] = useState(2)
  const [error404, setError404] = useState(false)
  const [clock, setClock] = useState(moment().format('HH:mm:ss'))

  const [restaurant, setRestaurant] = useState(null)

  const [searchParams, setSearchParams] = useSearchParams()

  let consumerView = searchParams.get('consumer')
  let include = searchParams.get('include')
  let canHide = searchParams.get('canHide')
  let menu_id = searchParams.get('menu_id')
  const shouldHideSettings = searchParams.get('hidesettings')

  // Filter for ValasRanta grilled orders
  const valasRantaFilter = transaction => {
    if (
      transaction.restaurantId === '5da73d40-0ffb-4105-b84c-7f39cfc3d9b9' ||
      transaction.restaurantId === 'b7435a5a-2178-4ef7-9a99-ad64e98dceb3'
    ) {
      const items = transaction.menu.map(item => item?.item)
      console.log(transaction)
      return Boolean(items.some(item => item?.tags?.find(tag => tag?.nameId === 'grilled')))
    }

    return true
  }

  const inProgressTransactions = useMemo(() => {
    return transactions.filter(t => t.status === 'inprogress').filter(valasRantaFilter)
  }, [transactions])

  const readyTransactions = useMemo(() => {
    return transactions.filter(t => t.status === 'ready').filter(valasRantaFilter)
  }, [transactions])

  // UI CUSTOMIZATION
  const [showDoneTab, setShowDoneTab] = useState(true)
  const [showAsList, setShowAsList] = useState(false) // Cards or list view
  const [fontSize, setFontSize] = useState('medium')

  const debounceRefreshTransactions = throttle(5 * 1000, async () => {
    await refreshTransactions()
  })

  const refreshTransactions = async () => {
    try {
      const restaurantResp = await getRestaurantTransactions(restaurantId)

      const transactionData = menu_id
        ? restaurantResp?.data?.filter(item => item.menuId === menu_id)
        : restaurantResp?.data
      setTransactions(transactionData || [])
    } catch (error) {
      console.error(error)
    }
  }

  const defaultTheme = {
    inProgressBackgroundColor: '#000',
    inProgressTextColor: '#FFF',
    readyBackgroundColor: '#11ff0045',
    readyTextColor: '#000',
    theme: 'default',
  }

  const [theme, setTheme] = useState(defaultTheme)

  const setRestaurantInfo = async () => {
    try {
      const restaurantInfoResp = await getRestaurantInfo(restaurantId)
      setRestaurant(restaurantInfoResp?.data)
      if (restaurantInfoResp?.data?.monitorSetting?.theme === 'customized') {
        setTheme(restaurantInfoResp?.data?.monitorSetting)
      } else {
        setTheme(defaultTheme)
      }
      setError404(false)
    } catch (e) {
      if (e?.response?.status === 404) {
        setError404(true)
      }
      console.error(e)
    }
  }

  useEffect(() => {
    debounceRefreshTransactions()
    setRestaurantInfo()
    let pid = setInterval(
      () => {
        debounceRefreshTransactions()
        setRestaurantInfo()
        // refreshTransactions()
      },
      5 * 60 * 1000
    )
    return () => {
      if (pid) {
        clearInterval(pid)
      }
    }
  }, [])

  useEffect(() => {
    Sentry.setTag('Restaurant ID in url', restaurantId)
    Sentry.setTag('Consumer view', consumerView)
  }, [restaurantId])

  useEffect(() => {
    let pid
    if (error) {
      console.log('Firebase error, fallback to interval polling')
      pid = setInterval(() => {
        debounceRefreshTransactions()
      }, 5000)
    }
    return () => {
      if (pid) {
        clearInterval(pid)
      }
    }
  }, [error])

  useEffect(() => {
    setStatusText(statusTextJson[index])
  }, [index])

  const handleShowAsList = () => {
    localStorage.setItem('showAsList', !showAsList)
    setShowAsList(prev => !prev)
  }

  const handleShowDoneTab = () => {
    localStorage.setItem('showDoneTab', !showDoneTab)
    setShowDoneTab(prev => !prev)
  }

  const handleFontSizeChange = size => {
    localStorage.setItem('fontSize', size)
    setFontSize(size)
  }

  useEffect(() => {
    const refreshIntervalPID = setInterval(() => {
      const ts = +new Date()
      if (ts - initedAt > HARD_REFRESH_INTERVAL && isOnline) {
        // only hard refresh when online
        window.forceReloadPage && window.forceReloadPage()
      }
    }, 60 * 1000)

    if (!consumerView) {
      const cacheShowList = searchParams.get('showAsList') || localStorage.getItem('showAsList')
      cacheShowList === 'true' ? setShowAsList(true) : setShowAsList(false)

      const cacheDoneTab = searchParams.get('showDoneTab') || localStorage.getItem('showDoneTab')
      cacheDoneTab === 'false' ? setShowDoneTab(false) : setShowDoneTab(true)

      const cacheFontSize = localStorage.getItem('fontSize')
      setFontSize(cacheFontSize || 'medium')
    }

    return () => {
      if (refreshIntervalPID) {
        clearInterval(refreshIntervalPID)
      }
    }
  }, [])

  useLayoutEffect(() => {
    document.getElementsByTagName('body')[0].classList.add('for-order-status')
  }, [])

  useEffect(() => {
    const interval = setTimeout(() => {
      setIndex(index === statusTextJson.length - 1 ? 0 : index + 1)
    }, 6000)
    return () => {
      clearTimeout(interval)
    }
  }, [statusText])

  useEffect(() => {
    const filteredTransactions = inProgressTransactions.filter(transaction =>
      include ? transaction.menu.find(e => e.item.itemType === include) : true
    )

    if (filteredTransactions.length < 3) {
      setOrdersPerRow(2)
    } else if (filteredTransactions.length < 9) {
      setOrdersPerRow(3)
    } else if (filteredTransactions.length < 16) {
      setOrdersPerRow(4)
    } else {
      setOrdersPerRow(5)
    }
    console.log(ordersPerRow)
  }, [inProgressTransactions])

  const prevSnapshotValue = usePrevious(snapshotValue)

  useEffect(() => {
    const _getStatusMap = snapshot => {
      const transactionIds = Object.keys(snapshot || {})
      return transactionIds.map(id => ({
        [id]: {
          status: (snapshot || {})?.[id]?.status,
          updatedAt: snapshot?.[id]?.updatedAt,
        },
      }))
    }
    const newSnapshot = _getStatusMap(snapshotValue)
    const oldSnapshot = _getStatusMap(prevSnapshotValue)
    const isTransactionChanged = !_.isEqual(oldSnapshot, newSnapshot)
    if (isTransactionChanged) {
      // debounceRefreshTransactions()
      refreshTransactions()
    }
  }, [snapshotValue, prevSnapshotValue, refreshTransactions])
  console.log('view', showAsList)

  useEffect(() => {
    const interval = setInterval(() => {
      setClock(moment().format('HH:mm:ss'))
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="order-status-view">
      {!isOnline && (
        <div className="offlineOverlay">
          <div className="errorMessage">
            <h2>You've lost your internet connection.</h2>
            <p>Please check your connection and try again.</p>
          </div>
        </div>
      )}
      {error404 && (
        <div className="offlineOverlay">
          <div className="errorMessage">
            <h2>Cannot find restaurant info</h2>
            <p>Please check your browser url address and try again.</p>
          </div>
        </div>
      )}
      {consumerView && (
        <div className="order-status-title">
          <div></div>
          <h1>{statusText?.title}</h1>
          <div className="version">
            {serialNumber} {consumerView && `${process.env.REACT_APP_COMMIT_HASH || 'localhost'}`}
          </div>
        </div>
      )}
      {/* <div>{consumerView && <h1 className="order-status-title">{statusText?.title}</h1>}</div> */}
      <div className="order-status-container">
        {!consumerView && (
          <div className="control-bar">
            {!shouldHideSettings && (
              <div onClick={() => setOrderStatusSettingsScreenShow(true)}>
                <img src="/img/settings.png" />
              </div>
            )}
            <div onClick={() => setAllDayReceiptModalShow(true)}>
              <img src="/img/receipt.png" />
            </div>
          </div>
        )}
        <div
          className={consumerView ? 'col' : showDoneTab ? 'col col-75' : 'col col-100'}
          style={{
            background: consumerView ? theme.inProgressBackgroundColor : defaultTheme.inProgressBackgroundColor,
          }}
        >
          <h2
            className="title"
            style={{ color: consumerView ? theme.inProgressTextColor : defaultTheme.inProgressTextColor }}
          >
            {consumerView ? statusText?.inprogress : 'In Progress'}
          </h2>
          <div className="orders-box">
            {inProgressTransactions
              .filter(transaction => (include ? transaction.menu.find(e => e.item.itemType === include) : true))
              .reverse()
              .map(transaction => (
                <OrderCard
                  include={include}
                  classNames={`in-row-${ordersPerRow} `}
                  kitchenClassNames={`font-${fontSize}`}
                  key={transaction.id}
                  transaction={transaction}
                  consumerView={consumerView}
                  canHide={canHide}
                  resetHidden={resetHidden}
                  setHasHidden={setHasHidden}
                  showAsList={showAsList}
                  restaurant={restaurant}
                />
              ))}
          </div>
        </div>
        <div
          className={showDoneTab ? 'col done' : 'col hidden'}
          style={{ background: consumerView ? theme.readyBackgroundColor : defaultTheme.readyBackgroundColor }}
        >
          <h2 className="title" style={{ color: consumerView ? theme.readyTextColor : defaultTheme.readyTextColor }}>
            {consumerView ? statusText?.done : 'Done'}
          </h2>

          <div className="orders-box">
            {readyTransactions
              .filter(transaction => (include ? transaction.menu.find(e => e.item.itemType === include) : true))
              .map(transaction => (
                <OrderCard
                  classNames={`in-row-${ordersPerRow} done`}
                  kitchenClassNames={`font-${fontSize}`}
                  include={include}
                  key={transaction.id}
                  transaction={transaction}
                  consumerView={consumerView}
                  canHide={canHide}
                  resetHidden={resetHidden}
                  setHasHidden={setHasHidden}
                  showAsList={showAsList}
                />
              ))}
          </div>
        </div>
        {hasHidden && (
          <button className="can-hide-button" onClick={() => setResetHidden(!resetHidden)}>
            Show Hidden
          </button>
        )}
      </div>
      {!consumerView && <div className="time">{clock}</div>}

      <AllDayReceiptModal
        show={allDayReceiptModalShow}
        onClose={() => setAllDayReceiptModalShow(false)}
        restaurantId={restaurantId}
      />
      <OrderStatusScreenSettings
        show={orderStatusSettingsScreenShow}
        onClose={() => setOrderStatusSettingsScreenShow(false)}
        showDoneTab={showDoneTab}
        onChangeShowDoneTab={handleShowDoneTab}
        showAsList={showAsList}
        onChangeSetAsList={handleShowAsList}
        fontSize={fontSize}
        onChangeSetFontSize={handleFontSizeChange}
      />
    </div>
  )
}

export default OrderStatusView
