export const STATUS_TEXT = {
  400: 'Error',
  401: 'Unauthorized',
  402: 'Unauthorized',
  403: 'Unauthorized',
  404: 'Resource not found',
  501: 'Server error',
  502: 'Server error',
  503: 'Server error',
}
