import { useEffect, useState } from 'react'
import { getReceiptOnDay } from '../../../../../Api/RestaurantApi'
import './all-day-receipt.scss'

type AllDayReceiptProps = {
  day: string
  restaurantId: string
}

function AllDayReceipt({ day, restaurantId }: AllDayReceiptProps) {
  const [receipt, setReceipt] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    console.log(restaurantId)
    setLoading(true)
    getReceiptOnDay(day, restaurantId).then(res => {
      console.log(res.data)
      setReceipt(res.data)
      setLoading(false)
    })
  }, [day])

  return (
    <div className="all-day-receipt">
      {loading && <span>Loading...</span>}
      {loading ||
        receipt?.rows?.map((row: any, index: any) => {
          return (
            <div className="all-day-receipt-row" key={index}>
              {row?.length === 0 && <span style={{ visibility: 'hidden' }}>Random text to maintain height</span>}
              {row?.map((value: any) => {
                return <span style={{ justifySelf: value.align, fontWeight: value.fontWeight }}>{value.value}</span>
              })}
            </div>
          )
        })}
    </div>
  )
}

export default AllDayReceipt
