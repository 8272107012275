import { request } from './base'

// now use in redeem token reward
export async function redeemCoupon(code) {
  const resp = await request(`/consumer/redeemCode`, 'post', {
    payload: {
      code,
    },
  })
  return resp?.data
}

// use in checkout flowk
export async function validateCoupon(code, cart) {
  const resp = await request(`/consumer/validateCode`, 'post', {
    payload: {
      code,
      cart,
    },
  })
  return resp?.data
}
