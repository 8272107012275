import React, { useEffect, useRef, useState } from 'react'
import { ToLocalizedString } from '../../Utils/ToLocalizedString'
import useOnScreen from '../../Hooks/useOnScreen'

const CaregoriesBox = (props: any) => {
  const ref = useRef(null)
  const isVisible = useOnScreen(ref)

  // if (!Array.isArray(props?.data)) {
  //   return
  // }

  let list = props.data.filter(function (item: any) {
    return item.type == 'editableText'
  })

  // if (list.length == 0) {
  //   return
  // }

  console.log('visible', isVisible)

  const handleCategoryClick = (id: string) => {
    const target = document.getElementById(id)
    target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <>
      <div className="categories-box" ref={ref}>
        {list.map((data: any, i: number) => {
          return (
            <span className="chip" key={i} onClick={() => handleCategoryClick(data.title.replace(/\s/g, ''))}>
              {ToLocalizedString(data, 'title')}
            </span>
          )
        })}
      </div>

      <div className="categories-box fixed" style={{ visibility: `${isVisible ? 'hidden' : 'visible'}` }}>
        {list.map((data: any, i: number) => {
          return (
            <span className="chip" key={i} onClick={() => handleCategoryClick(data.title.replace(/\s/g, ''))}>
              {ToLocalizedString(data, 'title')}
            </span>
          )
        })}
      </div>
    </>
  )
}

export default CaregoriesBox
