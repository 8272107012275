import React, { useState } from 'react'

const Loading = ({ error }) => {
  let errorMessage
  if (error?.response?.data?.error) {
    errorMessage = error.response?.data?.error
  } else {
    errorMessage = error?.message
  }

  return (
    <div className="loading-container">
      {!error && (
        <div className="lds-ring">
          <div />
        </div>
      )}
      {error && <span>{errorMessage} </span>}
    </div>
  )
}

export default Loading
