import axios from 'axios'
import i18n from 'i18next'
import { STATUS_TEXT } from './constant'
import * as cache from '../Utils/Cache'
import axiosRetry from 'axios-retry'

axiosRetry(axios, { retries: 2 })

export const APIEndpoint = process.env.REACT_APP_API_ENDPOINT
  ? String(process.env.REACT_APP_API_ENDPOINT)
  : 'https://europe-west1-pepi-test.cloudfunctions.net/apiTs/v1'

export const request = async (url, method = 'get', data) => {
  const { payload: _payload, data: _data, params, ...rest } = data || {}
  // const auth = getAuth();
  // const authToken = auth?.currentUser ? await auth.currentUser.getIdToken() : '';
  const authToken = await cache.get('auth_token')
  const payload = _payload || _data
  const serialNumber = (await cache.get('sn')) || payload?.serialNumber

  const resp = await axios({
    url: `${APIEndpoint}${url}`,
    method,
    withCredentials: false,
    data: payload,
    params: {
      serialNumber,
      ...(params || {}),
    },
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true,
      'Accept-Language': i18n.language,
      // 'Access-Control-Allow-Origin' : '*',
      // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    ...rest,
  })
  if (STATUS_TEXT[resp.status]) {
    throw Error(`${resp.status}: ${STATUS_TEXT[resp.status]}. ${resp?.data?.error}`)
  }
  return resp
}
