import { useEffect, useRef } from 'react'

export function getQueryParams(qs: any) {
  qs = qs.split('+').join(' ')

  var params: any = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2])
  }

  return params
}

export const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const isSameExtra = (extra1: any, extra2: any) => {
  if (extra1.type === 'otherItem') {
    if (extra2.id === extra1.id) return true
  } else {
    if (
      extra1?.translations?.name?.en !== undefined &&
      extra1?.translations?.name?.en === extra2?.translations?.name?.en
    ) {
      return true
    }
    if (extra1?.name !== undefined && extra1?.name && extra1.name === extra2?.name) {
      return true
    }
  }

  return false
}
