import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IconLocation, IconTime } from '../Icons/Icons'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../Contexts/AuthContext'
import LanguageSelection from '../LanguageSelection/LanguageSlection'

const StoreItem = props => {
  const { t, i18n } = useTranslation()
  const { restaurant } = useAuthContext()

  const d = new Date()
  let day = d.getDay()
  let openTime

  if (day == 0) {
    day = 7
  }

  if (props.data.time[day][2]) {
    openTime = t('menuView.restaurantClosed')
  } else {
    openTime = props.data.time[day][0] + ' - ' + props.data.time[day][1]
  }

  return (
    <>
      <div className="store-item">
        <div className="store-caption">
          <h3 className="store-name">{props?.data?.name}</h3>
          <ul className="store-contact">
            <li>
              <div className="icon">
                <IconLocation />
              </div>
              <span>{props?.data?.address}</span>
            </li>
            <li>
              <div className="icon">
                <IconTime />
              </div>
              <span className={props?.data?.forceClosed ? 'closed' : ''}>
                {props?.data?.forceClosed ? 'Temporary Closed' : openTime}
              </span>
            </li>
          </ul>
          {/* <a className='status out-of-order btn-menu'>Menu</a> */}
        </div>
      </div>
      <LanguageSelection languagesSupported={restaurant?.config?.languagesSupported} />
    </>
  )
}

StoreItem.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    time: PropTypes.string,
  }),
}

export default StoreItem
