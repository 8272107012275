import { useState, useEffect, useCallback } from 'react'
import fetchRetry from '../Utils/FetchRetry'
import moment from 'moment'

const fetch = fetchRetry(window.fetch)

const useNetworkStatus = (cb: any, ssid?: string, password?: string) => {
  const [isOnline, setOnline] = useState(navigator.onLine)
  const [isOnlineAt, setIsOnlineAt] = useState(moment())

  useEffect(() => {
    const interval = setInterval(async () => {
      // avoid CORS errors with a request to your own origin
      const url = new URL(window.location.origin)
      // random value to prevent cached responses
      url.searchParams.set('rand', Math.random().toString(36).substring(2, 15))

      try {
        const response = await fetch(
          url.toString(), {
            method: 'HEAD',
            fetchOptions: {
              timeout: 5000,
              retry: 0,
            },
          }
        )

        if (response.ok) {
          setOnline(true)
          setIsOnlineAt(moment())
        } else setOnline(false)
      } catch {
        setOnline(false)
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (isOnline) cb && cb()
  }, [isOnline])

  useEffect(() => {
    let reconnectingPID: any
    if (moment().subtract(5, 'minutes').isAfter(isOnlineAt) && ssid && password) {
        reconnectingPID = setInterval(() => {
          console.log("reconnecting to: ", ssid)
          // try {
          //   (window as any).TVBro.reconnectWifiTo(
          //     ssid,
          //     password,
          //   )
          // } catch (error) {
          //   console.error(error)
          // }
        }, 2 * 60 * 1000)
    }

    return () => {
      if (reconnectingPID) clearInterval(reconnectingPID)
    }
  }, [isOnlineAt, ssid, password])

  return { isOnline }
}

export default useNetworkStatus
