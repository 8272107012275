import React from 'react'
import HeaderHome from './HeaderHome'
import HeaderPage from './HeaderPage'
import PropTypes from 'prop-types'

const PageLayout = props => {
  const renderHeader = () => {
    switch (props?.type) {
      case 'home':
        return <HeaderHome backTo={props?.backTo} bannerSrc={props?.bannerSrc} />
      case 'page':
        if (props?.backTo && props?.backTo === '#hidden') {
          return <></>
        }
        return <HeaderPage pageName={props?.pageName} backTo={props?.backTo} />
      case 'order':
        return <></>
      default:
        return <HeaderPage pageName={props?.pageName} backTo={props?.backTo} />
    }
  }

  return (
    <>
      <div className="page-layout">
        {renderHeader()}
        <main>{props?.children}</main>
      </div>
    </>
  )
}

PageLayout.propTypes = {
  type: PropTypes.oneOf(['home', 'page']),
  backTo: PropTypes.string,
  pageName: PropTypes.string,
  bannerSrc: PropTypes.string,
}

export default PageLayout
