import _ from 'lodash'
import { useRef, useEffect, useCallback } from 'react'

export function useLazyEffect(effect, deps = [], wait = 300) {
  const cleanUp = useRef()
  const effectRef = useRef()
  const updatedEffect = useCallback(effect, deps)
  effectRef.current = updatedEffect
  const lazyEffect = useCallback(
    _.debounce(() => {
      if (cleanUp.current instanceof Function) {
        cleanUp.current()
      }
      cleanUp.current = effectRef.current?.()
    }, wait),
    []
  )
  useEffect(lazyEffect, deps)
  useEffect(() => {
    return () => {
      // eslint-disable-next-line no-unused-expressions
      cleanUp.current instanceof Function ? cleanUp.current() : undefined
    }
  }, [])
}
