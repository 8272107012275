import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as cache from '../../Utils/Cache'

const LanguageSelection = ({ languagesSupported }: { languagesSupported: string[] }) => {
  const [showModal, setShowModal] = useState(true)
  const { t, i18n } = useTranslation()

  const languageFlags = {
    en: '/img/flags/en.jpg',
    fi: '/img/flags/fi.jpg',
    sv: '/img/flags/sv.jpg',
    et: '/img/flags/et.png',
    es: '/img/flags/es.png',
    de: '/img/flags/de.png',
    ar: '/img/flags/ar.png',
    ru: '/img/flags/ru.png',
  }

  const handleLanguageSelect = (languageCode: string) => {
    i18n.changeLanguage(languageCode)
    cache.set('language', languageCode)
    setShowModal(false)
  }

  const getLanguageName = (code: string) => {
    const lang = new Intl.DisplayNames([code], { type: 'language' })
    return lang.of(code)
  }

  useEffect(() => {
    const cahcedLanguage = cache.get('language')
    if (cahcedLanguage && languagesSupported.includes(cahcedLanguage)) {
      i18n.changeLanguage(cahcedLanguage)
      setShowModal(false)
    }
  }, [])

  if (!languagesSupported) return

  return (
    <>
      {languageFlags[i18n.language as keyof typeof languageFlags] ? (
        <img
          src={languageFlags[i18n.language as keyof typeof languageFlags]}
          className="language-select-flag"
          onClick={() => setShowModal(true)}
          style={{ pointerEvents: 'all' }}
        />
      ) : (
        <div className="language-select-fallback" onClick={() => setShowModal(true)}>
          Current language: {i18n.language}
        </div>
      )}

      {showModal && (
        <div className="updatemodal-background">
          <div className="update-modal">
            <a onClick={() => setShowModal(false)} className="btn-close">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 1L6.5 6.5L12 12"
                  stroke="#272C3F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M1 1L6.5 6.5L1 12"
                  stroke="#272C3F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </a>
            <h3>Pick a Language</h3>
            <br />
            {languagesSupported.map(languageCode => (
              <div onClick={() => handleLanguageSelect(languageCode)} className="language-option">
                <img src={languageFlags[languageCode as keyof typeof languageFlags]} />
                <span>{getLanguageName(languageCode)}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default LanguageSelection
