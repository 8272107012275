const ru = {
  translation: {
    orderSuccessView: {
      status: {
        error: 'Ошибка',
        cancelled: 'Заказ отменен',
        archived: 'Спасибо и приятного аппетита!',
        inprogress: 'Заказ в обработке',
        new: 'Заказ отправлен',

        ready: 'Я забрал!',
      },
      error: 'Не удалось обработать заказ',
      cancelled: 'Заказ отменен',
      newShort: 'Отправлен',
      preOrdered: 'Заказ отправлен',
      orderCancelledUnableToFullfil: 'не смог принять ваш заказ.',
      orderSentTo: 'Заказ отправлен на ',
      readyDesc: 'Заберите заказ у прилавка',
      orderNumber: 'Номер заказа',
      deliveryEst: 'Предполагаемое завершение через',
      yourOrderNumberIs: 'Номер вашего заказа #',
      cancelledShort: 'Отменен',
      orderCancelled: 'Ваш заказ был отправлен, но, к сожалению, ',
      archivedShort: 'Завершено',
      ready: 'Ваш заказ готов!',
      orderCancelledRefunded: 'Ваши деньги возвращаются',
      archived: 'Заказ завершен',
      errorShort: 'Ошибка',
      new: 'Ваш заказ успешно!',
      orderInProgress: 'Мы работаем над вашим заказом - ',
      saveReceipt: 'Сохранить чек',
      inprogress: 'Ваш заказ будет готов в течение',
    },
    orderView: {
      dinein: 'Здесь есть',
      notePlaceholder: 'Добавьте специальные пожелания, аллергии и т. д...',
      reccomendations: 'Рекомендации',
      addButton: 'Добавить',
      takeaway: 'Взять с собой',
      preorder: 'Предварительный заказ в номер',
      roomorder: 'Заказать в номер',
      tableorder: 'Заказать к столику',
      roomOrderPlaceholder: 'Номер номера',
      tableOrderPlaceholder: 'Номер столика',
      tableFormatError: 'Требуется указать номер столика.',
      roomFormatError: 'Требуется указать номер номера.',
      forExample: 'Например',
      delivery: 'Доставка',
      consumerName: 'Имя (необязательно)',
      consumerNameForced: 'Пожалуйста, введите ваше имя...',
      gotoCheckout: 'Перейти к оплате',
      title: 'Ваш заказ',
      alcoholError: 'К сожалению, финско законодательство не позволяет взять алкоголь с собой 😔',
      couponCode: 'Код купона',
      pickTime: 'Выберите время',
      today: 'Сегодня',
      tomorrow: 'Завтра',
      required: 'Обязательно',
    },
    paymentView: {
      phoneNumber: 'Номер телефона',
      useCoinDesc: 'Используйте ваши {{symbol}} монеты для оплаты заказа',
      payButtonLoading: 'Оплата...',
      errorNoPhone: 'Пожалуйста, введите номер телефона',
      balance: 'Баланс',
      marketingConsent:
        'Я разрешаю {{company}} связываться со мной с предложениями и другой маркетинговой коммуникацией.',
      tosConsent: 'Вводя свой номер телефона, я принимаю <1>условия предоставления услуг</1>',
      useCoin: 'Использовать монеты',
      payButton: 'Подтвердить заказ',
      getCoin: 'Получить монеты',
      payNow: 'Оплатить сейчас',
      payNowDesc: 'Оплата картой / Apple Pay / Google Pay',
      smartum: 'Smartum',
      title: 'Оплата',
      getCoinDesc: 'Получите {{percentage}}% стоимости заказа в виде {{symbol}} монет',
      alcoholPopUptitle: 'Товары с ограничением по возрасту',
      alcoholPopUpAgeRestrict:
        'Вы собираетесь приобрести товар, ограниченный по возрасту. Будьте готовы предъявить свой идентификационный документ сотрудникам',
      alcoholPopUpNoRefund:
        'Мы не вернем вам средства за покупку, если вы не сможете предоставить сотрудникам свой идентификационный документ.',
      alcoholPopUpConfirm:
        'Пожалуйста, подтвердите, что у вас есть право на закупку этих товаров в соответствии с законом.',
      alcoholPopUpButtonYesAllowed: 'Да, у меня есть право',
      alcoholPopUpButtonCancel: 'Отмена',
      paying: 'Оплата...',
      confirmPayment: 'Подтвердить оплату',
      paymentMethods: {
        cash: {
          title: 'Наличные',
          description: 'Оплатить наличными у кассира',
        },
      },
    },
    menuView: {
      title: 'Меню',
      viewOrder: 'Просмотр заказа',
      restaurantClosed: 'Сегодня закрыто',
      addToOrder: 'Добавить в заказ',
    },
    menuItemView: {
      preOrderChip: 'Только предварительный заказ',
      preOrderExplanation:
        'Этот прием пищи можно заказать только заранее. Вы можете выбрать время доставки при оформлении заказа.',
      extras: 'Дополнительно:',
      alcoholWarning:
        'Обратите внимание, что алкоголь можно продавать только лицам старше 18 лет. Будьте готовы предъявить свой идентификационный документ.',
    },
    general: {
      coins: 'Монеты',
      coin: 'Монета',
      update: {
        updateTitle: 'Требуется обновление',
        updateText: 'Вы используете более старую версию Mynt. Пожалуйста, нажмите кнопку ниже, чтобы обновиться',
        updateButton: 'Обновить',
      },
      downloadApp: {
        downloadTitle: 'Доступно только в Mynt',
        downloadText: 'Для получения этой награды вам необходимо использовать приложение Mynt 🙂',
        downloadButton: 'Скачать приложение',
      },
    },
    couponView: {
      checkingCode: 'Проверка кода 🤟',
      errorTitle: 'Ой, что-то пошло не так 🤨',
      errorText1: 'Что-то пошло не так, возможно, вы уже получили эту предложение',
      errorText2: 'Если нет, перезапустите приложение Mynt и попробуйте снова',
      successTitle: 'Вы только что получили Myntd!',
      explainerText:
        'Когда вы заказываете еду через Mynt, вы можете получить 10% обратно в виде монет. Мы только что улучшили способ, которым вы тратите свои деньги',
      openMenuButton: 'Открыть меню',
      closeButton: 'Закрыть',
      td: {
        youGot: 'Вы получили',
        coinName: 'Монеты TD!',
        bannerText: 'Аксели Херлеви и Иман Гарагозлу',
      },
    },
  },
  Any: 'Любой',
  'Not empty': 'Не пусто',
  '1-4 digits': '1-4 цифры',
  'One digit and numbers': 'Одна цифра и цифры',
  'Two digits and numbers': 'Две цифры и цифры',
}

export default ru
