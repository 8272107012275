import React from 'react'
const ErrorModal = ({ title, description, buttonText, onClick, showError, setShowError }) => {
  if (showError) {
    return (
      <div className="updatemodal-background">
        <div className="update-modal">
          <a onClick={() => setShowError(false)} className="btn-close">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 1L6.5 6.5L12 12"
                stroke="#272C3F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 1L6.5 6.5L1 12"
                stroke="#272C3F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </a>
          <h3>{title}</h3>
          <p>{description}</p>
          {onClick && <button onClick={onClick}>{buttonText}</button>}
        </div>
      </div>
    )
  }
  return
}

export default ErrorModal
