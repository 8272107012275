import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const OrderStatusCircle = props => {
  const [status, setStatus] = useState({})
  const { t, i18n } = useTranslation()

  if (props.requestType == 'PRE ORDER') {
    return (
      <div className="status-circle-wrapper">
        <div className="outer-circle">
          <div className="mid-circle">
            <div className="inner-circle">{t('orderSuccessView.preOrdered')}</div>
          </div>
        </div>
      </div>
    )
  }

  if (props.status == 'new') {
    return (
      <div className="status-circle-wrapper">
        <div className="outer-circle">
          <div className="mid-circle">
            <div className="inner-circle">{t('orderSuccessView.newShort')}</div>
          </div>
        </div>
      </div>
    )
  }

  if (props.status == 'inprogress') {
    return (
      <div className="status-circle-wrapper">
        <div className="outer-circle">
          <div className="mid-circle">
            <div className="inner-circle">
              <h1>#{props?.orderNumber}</h1>
              <p>{props?.delivery} Min</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (props.status == 'ready') {
    return (
      <div className="status-circle-wrapper">
        <div className="outer-circle">
          <div className="mid-circle">
            <div className="inner-circle">
              <h1>#{props?.orderNumber}</h1>
              <p>{t('orderSuccessView.orderNumber')}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (props.status == 'archived') {
    return (
      <div className="status-circle-wrapper">
        <div className="outer-circle">
          <div className="mid-circle">
            <div className="inner-circle">
              <h1>#{props?.orderNumber}</h1>
              <p>{t('orderSuccessView.archivedShort')}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (props.status == 'error') {
    return (
      <div className="status-circle-wrapper">
        <p>{t('orderSuccessView.errorShort')}</p>
      </div>
    )
  }
  if (props.status == 'cancelled') {
    return (
      <div className="status-circle-wrapper">
        <p>{t('orderSuccessView.cancelledShort')}</p>
      </div>
    )
  }

  return (
    <div className="status-circle-wrapper">
      <div className="outer-circle">
        <div className="mid-circle">
          <div className="inner-circle">...</div>
        </div>
      </div>
    </div>
  )
}

export default OrderStatusCircle
