import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './Loca/en'
import fi from './Loca/fi'
import sv from './Loca/sv'
import et from './Loca/et'
import es from './Loca/es'
import de from './Loca/de'
import ar from './Loca/ar'
import ru from './Loca/ru'
import * as cache from './Utils/Cache'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en,
  fi,
  sv,
  et,
  es,
  de,
  ar,
  ru,
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: true,
    lng: cache.get('language') || 'en',
    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
