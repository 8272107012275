const cache = window.sessionStorage || {}

export const set = (key, value) => {
  cache.setItem(key, value)
}

export const get = key => {
  return cache.getItem(key)
}

export const del = key => {
  return cache.removeItem(key)
}

export const clear = () => {
  return cache.clear()
}
