import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'

// Reports internet connection loss to Sentry
// Logs out starting time of loss and ending time of loss
const useInternetConnectionReport = (isOnline: boolean) => {
  const [startingLossTime, setStartingLossTime] = useState<Date | null>(null)
  const [endingLossTime, setEndingLossTime] = useState<Date | null>(null)

  useEffect(() => {
    // Gain connection
    if (isOnline) {
      if (startingLossTime) {
        setEndingLossTime(new Date())
        console.log('Gained internet connection')
      }
    }
    // Lost connection
    else {
      setStartingLossTime(new Date())
      console.log('Internet connection lost')
    }
  }, [isOnline])

  useEffect(() => {
    // Do nothing if no report
    if (!endingLossTime || !startingLossTime) return
    if (startingLossTime < endingLossTime) {
      Sentry.captureException(new Error('No internet connection'), {
        tags: {
          downtime: ~~((endingLossTime.getTime() - startingLossTime.getTime()) / 1000),
        },
        contexts: {
          'Connection Loss': {
            from: startingLossTime,
            to: endingLossTime,
          },
        },
        level: 'warning',
      })
    }
    setStartingLossTime(null)
    setEndingLossTime(null)
  }, [endingLossTime])
}

export default useInternetConnectionReport
