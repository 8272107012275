import { useTranslation } from 'react-i18next'

type PaymentMethodProps = {
  title: string
  description: string
  icon?: string
  selected: boolean
  onClick: (...args: any[]) => any
}

function PaymentMethod({ title, description, icon, selected, onClick }: PaymentMethodProps) {
  const { t } = useTranslation()

  return (
    <div className="radio payment-option">
      <img
        style={{ float: 'right', height: '15px', width: 'auto', marginRight: '30px', marginTop: '3px' }}
        src={icon}
        alt=""
      />
      <input type="radio" name="payment-option" id="option-3" checked={selected} onClick={onClick} />
      <label htmlFor="option-3">
        <h3 className="option-name">{title}</h3>
        <div className="option-desc">{description}</div>
      </label>
    </div>
  )
}

export default PaymentMethod
