import moment from 'moment'

/**
 * Checks if this item is currently available
 * @returns true / false if available
 */
export default function CalculateAvailability(availability) {
  createAvailabilityJson(availability)
  if (availability == undefined) {
    return true
  }
  if (Object.keys(availability).length === 0) {
    return true
  }
  return returnIsAvailableNow(availability)
}

/**
 * Takes the availability JSON and returns appropirate CSS class to mark the product available if its available at this moment,
 * @param {array} availability
 * @returns
 */
export function AttachClassAvailable(availability) {
  const available = returnIsAvailableNow(availability)

  return available ? '' : 'unavailable'
}

export function returnIsAvailableNow(availability) {
  // TODO disable
  // return true;
  const d = new Date()
  let day = d.getDay()
  let currentHour = d.getHours()
  let currentMinute = d.getMinutes()
  if (day == 0) {
    day = 7
  }
  if (!availability[day]) {
    return false
  }
  if (availability[day].available == false) {
    return false
  }

  let fromTimeStamp = ['00', '00']
  let toTimeStamp = ['23', '59']

  if (availability[day]?.from) {
    fromTimeStamp = availability[day].from.split(':')
  }
  if (availability[day]?.to) {
    toTimeStamp = availability[day].to.split(':')
  }

  if (currentHour < parseInt(fromTimeStamp[0])) {
    return false
  }
  if (currentHour >= parseInt(toTimeStamp[0])) {
    return false
  }
  if (currentHour == parseInt(fromTimeStamp[0])) {
    if (currentMinute < parseInt(fromTimeStamp[1])) {
      return false
    }
  }
  if (currentHour == parseInt(toTimeStamp[0])) {
    if (currentMinute > parseInt(toTimeStamp[1])) {
      return false
    }
  }
  return true
}

export function getAvailabilityText(availability) {
  const d = new Date()
  let day = d.getDay()
  let currentHour = d.getHours()
  let currentMinute = d.getMinutes()
  // TODO disable
  // return true;
  if (day == 0) {
    day = 7
  }
  if (Object.keys(availability).length === 0) {
    return true;
  }
  if (!availability[day]) {
    return 'Not Available today'
  }
  if (availability[day].available == false) {
    return 'Not Available today'
  }

  let fromTimeStamp = ['00', '00']
  let toTimeStamp = ['23', '59']

  if (availability[day]?.from) {
    fromTimeStamp = availability[day].from.split(':')
  }
  if (availability[day]?.to) {
    toTimeStamp = availability[day].to.split(':')
  }

  if (currentHour < parseInt(fromTimeStamp[0])) {
    return 'Available at ' + fromTimeStamp[0] + ':' + fromTimeStamp[1]
  }
  if (currentHour >= parseInt(toTimeStamp[0])) {
    return 'Not Available anymore (Since ' + toTimeStamp[0] + ':' + toTimeStamp[1] + ')'
  }
  if (currentHour == parseInt(fromTimeStamp[0])) {
    if (currentMinute < parseInt(fromTimeStamp[1])) {
      let dif = parseInt(fromTimeStamp[1]) - currentMinute
      return 'Available in ' + dif + 'Minutes'
    }
  }
  if (currentHour == parseInt(toTimeStamp[0])) {
    if (currentMinute > parseInt(toTimeStamp[1])) {
      return 'Not Available anymore (Since ' + toTimeStamp[0] + ':' + toTimeStamp[1] + ')'
    }
  }
  return true
}

/**
 *
 * @param {*} availability - the availability json of an item
 * @param {*} overrideDay  - To specify other day
 * @returns
 */
const availabelDay = (availability, overrideDay) => {
  const d = new Date()
  let day = d.getDay()
  let currentHour = d.getHours()
  let currentMinute = d.getMinutes()

  if (overrideDay) {
    if (overrideDay > 7) {
      day = 1
    } else {
      day = overrideDay
    }
  }

  if (day == 0) {
    day = 7
  }

  if (availability) {
    if (!availability[day]) {
      return false
    }
    if (availability[day]?.available == false) {
      return false
    }

    if (availability[day]) {
      return true
    }
  }

  return false
}

/**
 *
 * @param {*} availability
 */
export const createAvailabilityJson = availability => {
  const d = new Date()
  let day = d.getDay()
  let currentHour = d.getHours() + ''
  let currentMinute = d.getMinutes() + ''

  let timestamp =
    (currentHour.length == 1 ? '0' + currentHour : currentHour) +
    ':' +
    (currentMinute.length == 1 ? '0' + currentMinute : currentMinute)

  if (day == 0) {
    day = 7
  }

  let tomorrowDay = day + 1

  if (tomorrowDay > 7) {
    tomorrowDay = 1
  }

  let json = {
    availableNow: false,
    isavailabelDay: false,
    isAvailableTomorrow: false,
    from: '00:00',
    to: '23:59',
  }

  if (availabelDay(availability)) {
    if (returnIsAvailableNow(availability)) {
      // Case It is available to order right now (No pre order)
      return {
        availableNow: true,
        isavailabelToday: true,
        isAvailableTomorrow: false,
        from: availability[day]?.from,
        to: availability[day]?.to,
      }
    } else {
      // You can pre order
      if (currentHour > availability[day].to) {
        // Case; No longer available today check tomorrow

        var date = new Date()
        date.setDate(date.getDate() + 1)

        if (availabelDay(availability, tomorrowDay)) {
          // Gonna be available tomorrow

          return {
            availableNow: false,
            isavailabelToday: false,
            isAvailableTomorrow: true,
            from: availability[tomorrowDay]?.from,
            to: availability[tomorrowDay]?.to,
          }
        } else {
          return {
            availableNow: false,
            isavailabelToday: false,
            isAvailableTomorrow: false,
            from: availability[tomorrowDay]?.from,
            to: availability[tomorrowDay]?.to,
          }
        }
      } else {
        // case: available today but not yet

        return {
          availableNow: false,
          isavailabelToday: true,
          isAvailableTomorrow: false,
          from: availability[day]?.from,
          to: availability[day]?.to,
        }
      }
    }
  } else if (availabelDay(availability, tomorrowDay)) {
    return {
      availableNow: false,
      isavailabelToday: false,
      isAvailableTomorrow: true,
      from: availability[tomorrowDay]?.from,
      to: availability[tomorrowDay]?.to,
    }
  }
}

/**
 *
 * @param {*} day
 */
let dayConverter = day => {
  if (day == 0) {
    day = 7
  }
  return day
}
