import React from 'react'
import { Link } from 'react-router-dom'
import PageLayout from '../Components/Layout/PageLayout'

const ErrorView = () => {
  return (
    <>
      <PageLayout type={'page'} backTo={'/'}>
        <div className="payment-status">
          <div className="img">
            <img src="/img/completed.png" alt="" />
          </div>
          <h1 className="headline-1 text-error">An error occurred!</h1>
          <div className="message">Your order has been canceled</div>
        </div>
        <Link className="btn-continue" to={'/'}>
          Back to menu
        </Link>
      </PageLayout>
    </>
  )
}

export default ErrorView
