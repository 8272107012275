import { useEffect, useState } from 'react'
import AllDayReceipt from './components/AllDayReceipt'
import Modal from '../Modal/Modal'
import { useDebounce } from 'usehooks-ts'

type AllDayReceiptModalProps = {
  show: boolean
  onClose: (...args: any) => any
  restaurantId: string
}

function AllDayReceiptModal({ show, onClose, restaurantId }: AllDayReceiptModalProps) {
  const [day, setDay] = useState(new Date().toISOString().split('T')[0])
  const debouncedDay = useDebounce(day, 1000)

  return (
    <Modal onClose={onClose} show={show}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          className="alldayreceipt-header"
          style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}
        >
          <h2 style={{ margin: 0 }}>All day receipt</h2>
          <h3>Choose day</h3>
          <input type="date" style={{ fontSize: '1rem' }} onChange={e => setDay(e.target.value)} value={day} />
        </div>
        <AllDayReceipt restaurantId={restaurantId} day={debouncedDay} />
      </div>
    </Modal>
  )
}

export default AllDayReceiptModal
