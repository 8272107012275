import React from 'react'
import { Link } from 'react-router-dom'
import PageLayout from '../Components/Layout/PageLayout'

const PaymentCompletedView = () => {
  return (
    <>
      <PageLayout type={'page'} backTo={'/menu'}>
        <div className="payment-status">
          <div className="img">
            <img src="/img/completed.png" alt="" />
          </div>
          <h1 className="headline-1">Your Order Is Completed</h1>
          <div className="message">Pick up order in the bar</div>
          <div className="note">Your order number is # 1391</div>
        </div>
        <Link className="btn-continue" to={'/menu'}>
          I've picked up
        </Link>
      </PageLayout>
    </>
  )
}

export default PaymentCompletedView
