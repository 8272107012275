import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const DownloadAppModal = () => {
  const { t, i18n } = useTranslation()

  return (
    <div className="updatemodal-background">
      <div className="update-modal">
        <h3>{t('general.downloadApp.downloadTitle')}</h3>
        <p>{t('general.downloadApp.downloadText')}</p>
        <a href="https://app.mynt.fi/" className="btn">
          {t('general.downloadApp.downloadButton')}
        </a>
      </div>
    </div>
  )
}

export default DownloadAppModal
