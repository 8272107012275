const de = {
  translation: {
    orderSuccessView: {
      status: {
        error: 'Fehler',
        cancelled: 'Bestellung wurde storniert',
        archived: 'Vielen Dank und genießen Sie!',
        inprogress: 'Bestellung in Bearbeitung',
        new: 'Bestellung gesendet',

        ready: 'Ich habe es!',
      },
      error: 'Verarbeitung der Bestellung fehlgeschlagen',
      cancelled: 'Bestellung wurde storniert',
      newShort: 'Gesendet',
      preOrdered: 'Bestellung gesendet',
      orderCancelledUnableToFullfil: 'konnte Ihre Bestellung nicht annehmen.',
      orderSentTo: 'Die Bestellung wurde an ',
      readyDesc: 'Holen Sie Ihre Bestellung am Schalter ab',
      orderNumber: 'Bestellnummer',
      deliveryEst: 'Voraussichtliche Fertigstellung in',
      yourOrderNumberIs: 'Ihre Bestellnummer lautet #',
      cancelledShort: 'Storniert',
      orderCancelled: 'Ihre Bestellung wurde gesendet, aber leider ',
      archivedShort: 'Abgeschlossen',
      ready: 'Ihre Bestellung ist bereit!',
      orderCancelledRefunded: 'Ihr Geld wird zurückerstattet',
      archived: 'Bestellung ist abgeschlossen',
      errorShort: 'Fehler',
      new: 'Ihre Bestellung war erfolgreich!',
      orderInProgress: 'Wir arbeiten an Ihrer Bestellung - ',
      saveReceipt: 'Quittung speichern',
      inprogress: 'Ihre Bestellung wird in Kürze fertig sein in',
    },
    orderView: {
      dinein: 'Hier essen',
      notePlaceholder: 'Besondere Wünsche, Allergien usw. hinzufügen...',
      reccomendations: 'Empfehlungen',
      addButton: 'Hinzufügen',
      takeaway: 'Mitnehmen',
      preorder: 'Vorbestellung auf Zimmer',
      roomorder: 'Bestellung aufs Zimmer',
      tableorder: 'Bestellung an den Tisch',
      roomOrderPlaceholder: 'Zimmernummer',
      tableOrderPlaceholder: 'Tischnummer',
      tableFormatError: 'Tischnummer erforderlich.',
      roomFormatError: 'Zimmernummer erforderlich.',
      forExample: 'z.B.',
      delivery: 'Lieferung',
      consumerName: 'Vorname (optional)',
      consumerNameForced: 'Bitte geben Sie Ihren Namen ein...',
      gotoCheckout: 'Zur Kasse gehen',
      title: 'Ihre Bestellung',
      alcoholError: 'Leider erlaubt das finnische Gesetz keine Mitnahme von Alkohol 😔',
      couponCode: 'Gutscheincode',
      pickTime: 'Wählen Sie eine Zeit',
      today: 'Heute',
      tomorrow: 'Morgen',
      required: 'Erforderlich',
    },
    paymentView: {
      phoneNumber: 'Telefonnummer',
      useCoinDesc: 'Verwenden Sie Ihre {{symbol}} Coins, um Ihre Bestellung zu bezahlen',
      payButtonLoading: 'Zahle...',
      errorNoPhone: 'Bitte geben Sie Ihre Telefonnummer ein',
      balance: 'Guthaben',
      marketingConsent:
        'Ich erlaube {{company}}, mich mit Sonderangeboten und anderen Marketingkommunikationen zu kontaktieren.',
      tosConsent: 'Durch die Eingabe meiner Telefonnummer akzeptiere ich die <1>Servicebedingungen</1>',
      useCoin: 'Coins verwenden',
      payButton: 'Bestellung bestätigen',
      getCoin: 'Coins erhalten',
      payNow: 'Jetzt bezahlen',
      payNowDesc: 'Bezahlen Sie mit Karte / Apple Pay / Google Pay',
      smartum: 'Smartum',
      title: 'Zahlung',
      getCoinDesc: 'Erhalten Sie {{percentage}}% des Wertes Ihrer Bestellung als {{symbol}} Coin zurück',
      alcoholPopUptitle: 'Altersbeschränkte Artikel',
      alcoholPopUpAgeRestrict:
        'Sie sind dabei, ein altersbeschränktes Produkt zu kaufen. Seien Sie bereit, Ihren Ausweis dem Personal vorzulegen',
      alcoholPopUpNoRefund:
        'Wir erstatten Ihren Kauf nicht zurück, falls Sie dem Personal keinen Ausweis vorlegen können.',
      alcoholPopUpConfirm: 'Bitte bestätigen Sie, dass Sie gesetzlich berechtigt sind, diese Artikel zu kaufen.',
      alcoholPopUpButtonYesAllowed: 'Ja, ich bin berechtigt',
      alcoholPopUpButtonCancel: 'Abbrechen',
      paying: 'Zahle...',
      confirmPayment: 'Zahlung bestätigen',
      paymentMethods: {
        cash: {
          title: 'Barzahlung',
          description: 'Zahlen Sie bar beim Kassierer',
        },
      },
    },
    menuView: {
      title: 'Speisekarte',
      viewOrder: 'Bestellung anzeigen',
      restaurantClosed: 'Heute geschlossen',
      addToOrder: 'Zur Bestellung hinzufügen',
    },
    menuItemView: {
      preOrderChip: 'Nur Vorbestellungen',
      preOrderExplanation:
        'Diese Mahlzeit kann nur im Voraus bestellt werden. Sie können während des Checkouts auswählen, wann sie geliefert werden soll.',
      extras: 'Zusatzoptionen:',
      alcoholWarning:
        'Bitte beachten Sie, dass Alkohol nur an Personen über 18 Jahren verkauft werden darf. Seien Sie bereit, Ihren Ausweis vorzulegen.',
    },
    general: {
      coins: 'Coins',
      coin: 'Coin',
      update: {
        updateTitle: 'Aktualisierung erforderlich',
        updateText:
          'Sie verwenden eine ältere Version von Mynt. Bitte drücken Sie die Schaltfläche unten, um ein Update durchzuführen',
        updateButton: 'Aktualisieren',
      },
      downloadApp: {
        downloadTitle: 'Nur in Mynt verfügbar',
        downloadText: 'Um diese Belohnung zu erhalten, müssen Sie die Mynt-App verwenden 🙂',
        downloadButton: 'App herunterladen',
      },
    },
    couponView: {
      checkingCode: 'Code wird überprüft 🤟',
      errorTitle: 'Oh nein 🤨',
      errorText1: 'Etwas ist schief gelaufen, vielleicht haben Sie dieses Angebot bereits erhalten',
      errorText2: 'Wenn nicht, starten Sie bitte die Mynt-App neu und versuchen Sie es erneut',
      successTitle: 'Du hast gerade Myntd erhalten!',
      explainerText:
        'Wenn Sie Ihre Mahlzeit über Mynt bestellen, können Sie 10% Ihres Bestellwerts als Coins zurückerhalten. Wir haben gerade die Art und Weise, wie Sie Ihr Geld ausgeben, verbessert',
      openMenuButton: 'Menü öffnen',
      closeButton: 'Schließen',
      td: {
        youGot: 'Du hast erhalten',
        coinName: 'TD Coins!',
        bannerText: 'Akseli Herlevi & Iman Gharagozlu',
      },
    },
  },
  Any: 'Beliebig',
  'Not empty': 'Nicht leer',
  '1-4 digits': '1-4 Ziffern',
  'One digit and numbers': 'Eine Ziffer und Zahlen',
  'Two digits and numbers': 'Zwei Ziffern und Zahlen',
}

export default de
