import { forwardRef } from 'react'

function Radio({ label, onClick, disabled, ...rest }, ref) {
  const handleClick = e => {
    if (!disabled) onClick(e)
  }

  return (
    <div className={`radio ${disabled ? 'disabled' : ''}`} onClick={handleClick}>
      <input type="radio" {...rest} ref={ref} readOnly={true} />
      <label>{label}</label>
    </div>
  )
}

export default forwardRef(Radio)
