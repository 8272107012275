import React, { useState, useEffect } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { debounce } from 'throttle-debounce'
import { IconCoinSmall } from '../../Components/Icons/Icons'
import AddInputAmount from '../../Components/Input/AddInputAmount'
import PageLayout from '../../Components/Layout/PageLayout'
import { useCartContext } from '../../Contexts/CartContext'
import CartItem from '../../Components/Cart/CartItem'
import { useTranslation } from 'react-i18next'
import { getRestaurantMenu } from '../../Api/MenuApi'
import { useAuthContext } from '../../Contexts/AuthContext'
import _ from 'lodash'
import '../../Styles/datepicker-override.scss'

import { addDays } from 'date-fns'
import { useSubmenuContext } from '../../Contexts/SubMenuContext'
import { validateCoupon } from '../../Api/CouponApi'
import { useLazyEffect } from '../../Hooks/LazyEffect'
import TextField from '../../UI/TextField/TextField'
import Radio from '../../Components/Radio/Radio'
import Recommendations from './components/Recommendations/Recommendations'
import { getRecommendations } from '../../Api/RecommendationsApi'
import TimePicker from './components/TimePicker/TimePicker'
import {
  getAvailability,
  getAvailabilityFromNow,
  getOverlappingAvailability,
  isAvailable,
  trimPast,
} from '../../Utils/AvailabilityHelper'
import { RECOMMENDATIONS_AMOUT } from '../../Constants'
import { object } from 'yup'
import useCurrency from '../../Hooks/useCurrency'

const schema = object()

const OrderView = () => {
  const {
    addToCart,
    totalPrice,
    cartList,
    tagAlong,
    setTagAlong,
    removeFromCart,
    adjustAmount,
    tableNumber,
    setTableNumber,
    takeAwayAvailable,
    preordersIncart,
    alcoholInCart,
    metadata,
    setMetadata,
    couponCode,
    setCartList,
  } = useCartContext()

  const { t, i18n } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const urlRoom = searchParams.get('room')

  const { restaurantId, loggedIn, restaurant, setting } = useAuthContext()
  const { orderingMode, nameOrdering, forcedNameOrdering, subMenu } = useSubmenuContext()
  const { withCurrencySymbol } = useCurrency()

  const [showAlcoholError, setShowAlcoholError] = useState(undefined)
  const [recommendations, setRecommendations] = useState([null, null])
  const [requireInput, setRequireInput] = useState(false)
  const [tableFormatError, setTableFormatError] = useState(null)
  const [customerNameError, setCustomerNameError] = useState(null)

  const [availabilityToday, setAvailabilityToday] = useState([])
  const [availabilityTomorrow, setAvailabilityTomorrow] = useState([])
  const [orderAvailableNow, setOrderAvailableNow] = useState(false)

  const removeItem = id => {
    removeFromCart(id)
  }

  const changeAmountItem = (index, amount) => {
    adjustAmount(index, amount)
  }

  const onAddRecommendation = index => {
    const item = recommendations[index]

    addToCart(1, {
      ...item,
      upselling: 'reccomended-qr',
      chosenExtras: [],
      chosenOptions: [],
    })

    // Same as: setRecommendations(prev => prev.with(index, null))
    const recommendationsCopy = [...recommendations]
    recommendationsCopy[index] = null
    setRecommendations(recommendationsCopy)
  }

  const handleTimePick = data => {
    if (tagAlong.requestType === 'PRE ORDER') setTagAlong({ ...tagAlong, plannedAt: data })
  }

  console.log(cartList)
  console.log(tagAlong)

  useEffect(() => {
    const validRecommendataions = recommendations.filter(rec => rec !== null)
    if (validRecommendataions.length < RECOMMENDATIONS_AMOUT) {
      getRecommendations(
        RECOMMENDATIONS_AMOUT - validRecommendataions.length,
        cartList,
        preordersIncart,
        recommendations
      ).then(newRecommendations => {
        let index = -1
        const updatedRecommendations = recommendations
          .map(value => {
            if (value === null) {
              index++
              return newRecommendations[index]
            }
            return value
          })
          .filter(value => value)

        console.log('upd', updatedRecommendations)
        setRecommendations(updatedRecommendations)
      })
    }
    console.log(cartList)
    console.log(preordersIncart)
    if (preordersIncart) {
      const todayAvailList = getOverlappingAvailability(
        cartList.map(el => el.item),
        'today'
      )
      const tomorrowAvailList = getOverlappingAvailability(
        cartList.map(el => el.item),
        'tomorrow'
      )

      setOrderAvailableNow(isAvailable('now', todayAvailList))
      // TODO disable later
      // setOrderAvailableNow(true)
      setAvailabilityToday(todayAvailList)
      setAvailabilityTomorrow(tomorrowAvailList)

      // If user picked time is still relevant with new item
      const newOverlapHasPickedTime =
        todayAvailList?.some(time => time.value.isSame(tagAlong?.plannedAt?.value)) ||
        tomorrowAvailList?.some(time => time.value.isSame(tagAlong?.plannedAt?.value))

      if (!newOverlapHasPickedTime) {
        setTagAlong({ ...tagAlong, plannedAt: null })
      }
    }
  }, [cartList, preordersIncart])

  useEffect(() => {
    if (urlRoom) {
      setTableNumber(urlRoom)
    }
  }, [urlRoom])

  useLazyEffect(
    () => {
      const checkCoupon = async () => {
        try {
          const response = await validateCoupon(couponCode, cartList)
          console.log('code validation response', response)
          const { success } = response
          const { code, cart } = response?.data || {}
          if (success && cart) {
            // Handle the response here, e.g., apply the discount, show a success message, etc.
            setCartList(cart)
          } else {
            throw new Error(response?.error || 'Failed to validate coupon')
          }
        } catch (error) {
          // Handle the error here, e.g., show an error message to the user
          console.error(error)
        }
      }
      checkCoupon()
    },
    [couponCode],
    1000
  )
  console.log(tagAlong.plannedAt)

  // Basically validation
  useEffect(() => {
    if (forcedNameOrdering) {
      if (tagAlong.customerName.length === 0) {
        setRequireInput(true)
        setCustomerNameError(t('orderView.required'))
        return
      } else {
        setRequireInput(false)
        setCustomerNameError(null)
      }
    }
    console.log(orderingMode, restaurant?.config?.enableTableNumberInput)
    if (
      tagAlong.requestType === 'ROOM ORDER' ||
      tagAlong.requestType === 'TABLE ORDER' ||
      tagAlong.requestType === 'PRE ORDER' ||
      restaurant?.config?.enableTableNumberInput
    ) {
      console.log(setting.tableFormat)
      if (setting.tableFormat === undefined) return
      console.log(tableNumber, setting.tableFormat.regex)
      if (tableNumber.match(setting.tableFormat.regex)) {
        setRequireInput(false)
        setTableFormatError(null)
      } else {
        setRequireInput(true)
        setTableFormatError(setting.tableFormat.name)
        if (tagAlong.requestType === 'ROOM ORDER' || tagAlong.requestType === 'PRE ORDER') {
          const example = setting.tableFormat.example
          if (example) {
            setTableFormatError(`${t('orderView.roomFormatError')} ${t('orderView.forExample')}: ${example}`)
          } else {
            setTableFormatError(t('orderView.roomFormatError'))
          }
        }
        if (tagAlong.requestType === 'TABLE ORDER') {
          const example = setting.tableFormat.example
          if (example) {
            setTableFormatError(`${t('orderView.tableFormatError')} ${t('orderView.forExample')}: ${example}`)
          } else {
            setTableFormatError(t('orderView.tableFormatError'))
          }
        }

        return
      }
    }

    if (tagAlong.requestType === 'EAT HERE' && orderingMode === 'table-ordering') {
      setRequireInput(true)
    }

    if (
      orderingMode === 'room-ordering' &&
      !['ROOM ORDER', 'TABLE ORDER', 'PRE ORDER'].includes(tagAlong.requestType) &&
      !(
        // hardcoded for scandic tampere
        (restaurantId === 'bf77ba85-02f7-4f2e-b845-8ac9c1b694e6' && subMenu?.id === 'room-order')
      )
    ) {
      setRequireInput(true)
    }

    if (subMenu?.shouldAcceptPreOrder && tagAlong.requestType === 'PRE ORDER') {
      if (tagAlong.plannedAt) {
        setRequireInput(false)
      } else {
        setRequireInput(true)
        return
      }
    }
  }, [tableNumber, tagAlong, restaurant, subMenu])

  console.log('ordering-mode', orderingMode)
  console.log('ordering-pre-ordering-in-cart', preordersIncart)
  console.log('order-menu-is-accepting-pre-order', subMenu?.shouldAcceptPreOrder)

  return (
    <PageLayout type={'page'} pageName={t('orderView.title')} backTo={'/'}>
      <div className="order-container">
        <div className="order-list">
          {cartList.map(({ item, quantity }, index) => (
            <CartItem
              data={{ ...item, quantity, swipeAction: true }}
              key={index}
              fnRemove={() => removeItem(index)}
              index={index}
              fnAdjustAmount={changeAmountItem}
            />
          ))}
        </div>

        <div className="order-shipping">
          <div className="radio-list shipping-options">
            {orderingMode == 'room-ordering' && !preordersIncart && (
              <Radio
                checked={tagAlong.requestType === 'ROOM ORDER'}
                onClick={() => setTagAlong({ ...tagAlong, requestType: 'ROOM ORDER' })}
                label={t('orderView.roomorder')}
              />
            )}
            {orderingMode == 'room-ordering' &&
              restaurantId === 'bf77ba85-02f7-4f2e-b845-8ac9c1b694e6' &&
              subMenu?.id === 'room-order' && (
                <Radio
                  checked={tagAlong.requestType === 'EAT HERE'}
                  onClick={() => setTagAlong({ ...tagAlong, requestType: 'EAT HERE' })}
                  label={t('orderView.pickup')}
                />
              )}

            {orderingMode == 'table-ordering' && (
              <Radio
                checked={tagAlong.requestType === 'TABLE ORDER'}
                onClick={() => setTagAlong({ ...tagAlong, requestType: 'TABLE ORDER' })}
                label={t('orderView.tableorder')}
              />
            )}

            {subMenu?.shouldAcceptPreOrder && preordersIncart && (
              <Radio
                checked={tagAlong.requestType === 'PRE ORDER'}
                onClick={() => setTagAlong({ ...tagAlong, requestType: 'PRE ORDER' })}
                label={t('orderView.preorder')}
              />
            )}

            {orderingMode === 'no-special-ordering' && (
              <>
                {!preordersIncart || (preordersIncart && orderAvailableNow) ? (
                  <Radio
                    checked={tagAlong.requestType === 'EAT HERE'}
                    onClick={() => setTagAlong({ ...tagAlong, requestType: 'EAT HERE' })}
                    label={
                      restaurantId === 'bf77ba85-02f7-4f2e-b845-8ac9c1b694e6' && subMenu?.id === 'room-order'
                        ? t('orderView.pickup')
                        : t('orderView.dinein')
                    }
                  />
                ) : null}

                {restaurant?.config?.allowedRequestTypes?.includes('TAKEAWAY') &&
                (!preordersIncart || (preordersIncart && orderAvailableNow)) &&
                !(restaurantId === 'bf77ba85-02f7-4f2e-b845-8ac9c1b694e6' && subMenu?.id === 'room-order') ? (
                  <Radio
                    checked={tagAlong.requestType === 'TAKEAWAY'}
                    onClick={() => {
                      setTagAlong({
                        ...tagAlong,
                        requestType: 'TAKEAWAY',
                      })
                      if (alcoholInCart) setShowAlcoholError(true)
                    }}
                    disabled={!takeAwayAvailable}
                    label={t('orderView.takeaway')}
                  />
                ) : null}
              </>
            )}
          </div>

          {showAlcoholError && <div className="alcohol-warning">{t('orderView.alcoholError')}</div>}

          {nameOrdering && (
            <TextField
              placeholder={forcedNameOrdering ? t('orderView.consumerNameForced') : t('orderView.consumerName')}
              value={tagAlong.customerName}
              onChange={e => {
                if (e.target.value.length < 15) {
                  setTagAlong({
                    ...tagAlong,
                    customerName: e.target.value,
                  })
                }
              }}
              error={customerNameError}
            />
          )}

          {tagAlong.requestType === 'EAT HERE' &&
            restaurantId === 'bf77ba85-02f7-4f2e-b845-8ac9c1b694e6' &&
            subMenu?.id === 'room-order' && (
              <TextField
                placeholder={`${t('orderView.roomOrderPlaceholder')} (${setting?.tableFormat.name || ''})`}
                error={tableFormatError}
                value={tableNumber}
                onChange={e => setTableNumber(e.target.value)}
              />
            )}

          {tagAlong.requestType === 'EAT HERE' &&
            restaurant?.config?.enableTableNumberInput &&
            !(restaurantId === 'bf77ba85-02f7-4f2e-b845-8ac9c1b694e6' && subMenu?.id === 'room-order') && (
              <TextField
                placeholder={`${t('orderView.tableOrderPlaceholder')} (${setting?.tableFormat.name || ''})`}
                error={tableFormatError}
                value={tableNumber}
                onChange={e => setTableNumber(e.target.value)}
              />
            )}

          {(tagAlong.requestType === 'PRE ORDER' ||
            tagAlong.requestType === 'ROOM ORDER' ||
            tagAlong.requestType === 'TABLE ORDER') && (
            // (tagAlong.requestType === 'EAT HERE' && orderingMode === 'room-ordering')
            <TextField
              placeholder={
                (tagAlong.requestType !== 'TABLE ORDER'
                  ? t('orderView.roomOrderPlaceholder')
                  : t('orderView.tableOrderPlaceholder')) + ` (${setting?.tableFormat.name || ''})`
              }
              error={tableFormatError}
              value={tableNumber}
              onChange={e => setTableNumber(e.target.value)}
            />
          )}

          <textarea
            cols="30"
            rows={tagAlong.note.length == 0 ? '1' : '3'}
            className="order-note"
            placeholder={t('orderView.notePlaceholder')}
            value={tagAlong.note}
            onChange={e => {
              if (e.target.value.length < 180) {
                setTagAlong({
                  ...tagAlong,
                  note: e.target.value,
                })
              }
            }}
          />

          {subMenu?.shouldAcceptPreOrder && tagAlong.requestType === 'PRE ORDER' && preordersIncart && (
            <div className="date-override">
              <p>When do you want it delivered?</p>
              <TimePicker
                today={trimPast(availabilityToday)}
                tomorrow={availabilityTomorrow}
                onChange={handleTimePick}
                value={tagAlong.plannedAt}
              />
            </div>
          )}

          {(restaurant?.config?.transactionMetadataKeys || []).map(field => (
            <TextField
              placeholder={field.name}
              value={metadata[field.key]}
              onChange={e => {
                setMetadata({
                  ...metadata,
                  [field.key]: e.target.value,
                })
              }}
            />
          ))}
        </div>

        <Recommendations recommendations={recommendations} onAdd={onAddRecommendation} />
      </div>

      <Link
        className={requireInput ? 'btn-checkout disabled' : 'btn-checkout'}
        to={{ pathname: '/payment', search: searchParams.toString() }}
      >
        <div className="order-info">
          <div className="amount">{cartList.length < 10 ? '0' + cartList.length : cartList.length}</div>
          <div className="text">{t('orderView.gotoCheckout')} →</div>
        </div>
        <div className="order-price">{withCurrencySymbol(totalPrice)}</div>
      </Link>
    </PageLayout>
  )
}

export default OrderView
