import { useEffect, useState } from 'react'
import Modal from '../Modal/Modal'

type OrderStatusScreenSettingsProps = {
  show: boolean
  onClose: (...args: any) => any
  restaurantId: string
  showDoneTab: boolean
  onChangeShowDoneTab: (...args: any) => any
  showAsList: boolean
  onChangeSetAsList: (...args: any) => any
  fontSize: 'small' | 'medium' | 'large'
  onChangeSetFontSize: (...args: any) => any
}

function OrderStatusScreenSettings({
  show,
  onClose,
  restaurantId,
  showDoneTab,
  onChangeShowDoneTab,
  showAsList,
  onChangeSetAsList,
  fontSize,
  onChangeSetFontSize,
}: OrderStatusScreenSettingsProps) {
  console.log('settings', showAsList)
  return (
    <Modal onClose={onClose} show={show}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h1>Settings</h1>
        <h2>Visual Settings</h2>

        <p>
          <label>
            <input
              type="checkbox"
              className="filled-in checkbox"
              checked={showDoneTab}
              onChange={onChangeShowDoneTab}
            />
            <span>Show Done tab</span>
          </label>
        </p>
        <p>
          <label>
            <input type="checkbox" className="filled-in checkbox" checked={showAsList} onChange={onChangeSetAsList} />
            <span>Show Orders as a list</span>
          </label>
        </p>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>Text size</span>
          <select value={fontSize} onChange={e => onChangeSetFontSize(e.target.value)}>
            <option value="small">Small</option>
            <option value="medium">Medium</option>
            <option value="large">Large</option>
          </select>
        </div>
      </div>
    </Modal>
  )
}

export default OrderStatusScreenSettings
