import AddButton from '../../../../Components/Input/AddButton'
import Skeleton from 'react-loading-skeleton'
import useCurrency from '../../../../Hooks/useCurrency'

const RecommendItem = ({ data, onAdd, index }) => {
  const { withCurrencySymbol } = useCurrency()

  return (
    <div className="recommend-item">
      <div className="item-info">
        {data?.image ? (
          <div className="item-img">
            <img src={data?.image} alt="" />
          </div>
        ) : (
          <Skeleton className="item-img" />
        )}
        <div className="item-caption">
          <h4 className="item-name">{data?.title ? data.title : <Skeleton className="item-name" width="100px" />}</h4>
          <div className="item-price">
            {data?.price ? withCurrencySymbol(data.price) : <Skeleton className="item-price" width="40px" />}
          </div>
        </div>
      </div>
      {data?.title && <AddButton onClick={() => onAdd(index)} />}
    </div>
  )
}

export default RecommendItem
