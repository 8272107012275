import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PageLayout from '../Components/Layout/PageLayout'
import { useAuthContext } from '../Contexts/AuthContext'
import { getDeviceLinkedRestaurant } from '../Api/RestaurantApi'

const PairingView = () => {
  const navigate = useNavigate()
  const { sn } = useAuthContext()

  useEffect(() => {
    const pid = setInterval(() => {
      getDeviceLinkedRestaurant().then(resp => {
        const restaurantId = resp?.data?.id
        const deviceType = resp?.device?.type
        if (restaurantId && deviceType) {
          if (deviceType === 'kitchen_monitor') {
            navigate(`/order-status/${restaurantId}/`, { replace: true })
          } else if (deviceType === 'customer_monitor') {
            navigate(`/order-status/${restaurantId}/?consumer=true`, { replace: true })
          }
        }
      })
    }, 5000)
    return () => {
      if (pid) clearInterval(pid)
    }
  }, [])

  return (
    <>
      <PageLayout type={'page'} backTo={'#hidden'}>
        <div className="payment-status">
          <div className="img">
            <img src="/img/completed.png" alt="" />
          </div>
          <h1 className="headline-1 text-error">{sn || 'Serial number not found!'}</h1>
          <div className="message">Here is your device serial number</div>
          <div className="message">
            Screen will get auto refreshed when serial number of the device is registered at
            https://partners.myntapp.io/devices
          </div>
          <div className="message">
            <button
              onClick={() => {
                navigate(`/order-status/99c810f9-eeeb-4a09-aa01-c91b2f3e5e6e/`, { replace: true })
              }}
            >
              Test the view
            </button>
          </div>
          <div className="message" style={{ display: 'none' }}>
            Setup auto-launch for monitor at: <a href="/news.androidtv.launchonboot_12.apk">download</a>
          </div>
        </div>
        {/* <Link className='btn-continue' to={'/'}>
          Back to menu
        </Link> */}
      </PageLayout>
    </>
  )
}

export default PairingView
