import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import { IconCoin, IconCoin18, IconCoinSmall, IconMenuInvert } from '../../Components/Icons/Icons'
import { useCartContext } from '../../Contexts/CartContext'
import { useAuthContext } from '../../Contexts/AuthContext'
import PageLayout from '../../Components/Layout/PageLayout'
import { listTokens, startPayment, getToken, startPaymentAsUser } from '../../Api/MenuApi'
import Big from 'big.js'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import SwipableButton from '../../Components/SwipableButton'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as cache from '../../Utils/Cache'
import { getQueryParams } from '../../Utils/Misc'
import { useErrorContext } from '../../Contexts/ErrorContext'
import useCurrency from '../../Hooks/useCurrency'
import PaymentMethod from './components/PaymentMethod'

const LUNCH_LIMITS = ['7.15', '11.3']

const PaymentView = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { totalPrice, cartList, tagAlong, tableNumber, metadata, alcoholInCart } = useCartContext()
  const { restaurantId, authToken, token, loggedIn, restaurant, setting } = useAuthContext()
  const { info } = useErrorContext()

  let phone = cache.get('phonenumber')
  console.log('phone: ', phone)
  console.log(setting)

  phone = phone ? phone : ''

  // const [token, setToken] = useState();
  const [errorNophone, setErrorNoPhone] = useState(false)
  const [consent, setConsent] = useState(false)
  const [showAlcoholPopUp, setShowAlcoholPopUp] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(process.env.REACT_APP_FIREBASE_ENV !== 'pepi-test' ? phone : phone)
  const [paymentMethods, setPaymentMethods] = useState(['get_coin'])
  const [coinRewardPercentage, setCoinRewardPercentage] = useState('10')
  const [paying, setPaying] = useState(false)
  const { withCurrencySymbol } = useCurrency()
  const { t } = useTranslation()
  const [epassiForm, setEpassiForm] = useState({})
  const epassiRef = useRef(null)

  const onAlcoholPay = () => {
    setShowAlcoholPopUp(true)
  }

  const onStartPayment = async () => {
    setPaying(true)
    setShowAlcoholPopUp(false)

    try {
      const func = loggedIn ? startPaymentAsUser : startPayment
      // if (loggedIn && !phoneNumber) {
      //     setErrorNoPhone(true)
      //     return
      // }

      if (cartList.length === 0) {
        // Cart empty !?
        // @Duc how do we handle this
        setPaying(false)
        return
      }
      const serialNumber = await cache.get('sn')
      const resp = await func({
        ...(!loggedIn
          ? {
              phoneNumber,
              marketingConsent: consent,
            }
          : {}),
        restaurantId: restaurantId || searchParams.get('restaurant_id'),
        serialNumber: serialNumber || searchParams.get('sn'),
        tokenId: token?.id,
        paymentMethods: paymentMethods.map(method =>
          method === 'get_coin' ? (restaurant.config?.vivawallet_merchant_id ? 'vivawallet' : 'stripe') : method
        ),
        amount: totalPrice,
        menu: cartList,
        tableNumber,
        ...tagAlong,
        plannedAt: tagAlong.requestType === 'PRE ORDER' && tagAlong?.plannedAt ? tagAlong.plannedAt.value.unix() : null,
        metadata,
      })
      const { error, success, data } = resp
      if (success) {
        if ((data.url || '').match(/https:\/\/.*.epassi.fi\//g)) {
          const epassiEndpoint = data.url.split('?')?.[0]
          const payload = getQueryParams(data.url.split('?')?.[1])
          // delete payload["method"];
          setEpassiForm({
            url: epassiEndpoint,
            ...payload,
          })
          setTimeout(() => {
            console.log('epassi', epassiRef.current)
            epassiRef.current.click()
            // epassiRef.current.dispatchEvent(
            //     new Event("submit", { cancelable: true, bubbles: true })
            // )
            setPaying(false)
          }, 1000)
        } else {
          window.location = data.url
          setPaying(false)
        }
      } else {
        info('Error', error)
        setPaying(false)
      }
    } catch (error) {
      info('Error', error.message)
      setPaying(false)
    } finally {
    }
  }

  useEffect(() => {
    if (errorNophone) {
      setErrorNoPhone(false)
    }
    cache.set('phonenumber', phoneNumber)
  }, [phoneNumber])

  const paidBy = useCallback(
    pm => {
      const _paidByCoin = () => {
        if (pm === 'get_coin') {
          return Big('0').toFixed(2)
        }

        const tokenBalance = token?.balance ? new Big(token.balance) : new Big(0)
        if (tokenBalance.gte(totalPrice)) {
          return Big(totalPrice).toFixed(2)
        } else {
          return tokenBalance.toFixed(2)
        }
      }

      const _paidByFiat = () => {
        const _byCoin = _paidByCoin()
        const _byBenefitCard = _paidByBenefitCard()
        return new Big(totalPrice).minus(_byCoin).minus(_byBenefitCard).toFixed(2)
      }

      const _paidByBenefitCard = () => {
        if (pm === 'smartum' || pm === 'epassi') {
          if (Big(totalPrice).gt(LUNCH_LIMITS[1])) {
            return Big(LUNCH_LIMITS[1]).toFixed(2)
          } else if (Big(totalPrice).lt(LUNCH_LIMITS[0])) {
            return Big('0').toFixed(2)
          } else {
            return Big(totalPrice).toFixed(2)
          }
        } else {
          return Big('0').toFixed(2)
        }
      }

      let alcoholSum = Big(0)
      for (let i = 0; i < cartList.length; i++) {
        if (cartList[i].item.isAlcohol) {
          alcoholSum = alcoholSum.plus(Big(cartList[i].item.price).mul(cartList[i].quantity))
        }
      }

      return {
        fiat: _paidByFiat(),
        coin: _paidByCoin(),
        inBenefitCard: _paidByBenefitCard(),
        rewardingCoin: Big(_paidByFiat())
          .plus(_paidByBenefitCard())
          .minus(alcoholSum)
          .mul(Big(coinRewardPercentage).div(100))
          .toFixed(2),
      }
    },
    [coinRewardPercentage, token?.balance, totalPrice, cartList]
  )

  const asSmartum = paidBy('smartum')
  const asEpassi = paidBy('epassi')
  const asFiat = paidBy('get_coin')
  const asCoin = paidBy('use_coin')

  useEffect(() => {
    if (restaurant?.config?.coinRewardingPercentage) {
      setCoinRewardPercentage(restaurant.config.coinRewardingPercentage)
    }
  }, [restaurant])

  return (
    <>
      <PageLayout type={'page'} pageName={t('paymentView.title')} backTo={'/order'}>
        <div className={showAlcoholPopUp ? 'alcohol-popup show' : 'alcohol-popup'}>
          <div className="popup-box">
            <h3>{t('paymentView.alcoholPopUptitle')}</h3>
            <p>{t('paymentView.alcoholPopUpAgeRestrict')}</p>
            <p>{t('paymentView.alcoholPopUpNoRefund')}</p>
            <p>{t('paymentView.alcoholPopUpConfirm')}</p>
            <button className="confirm" onClick={onStartPayment}>
              {' '}
              {t('paymentView.alcoholPopUpButtonYesAllowed')}
            </button>
            <button
              className="cancel"
              onClick={() => {
                setShowAlcoholPopUp(false)
                navigate('/order')
              }}
            >
              {t('paymentView.alcoholPopUpButtonCancel')}
            </button>
          </div>
        </div>
        <br />
        <div className="payment-container">
          {token &&
            (loggedIn ? (
              <div className="total-coin">
                <div className="label">
                  {/* <div className='icon-menu'> */}
                  {/* <IconMenuInvert /> */}
                  {/* </div> */}
                  <div className="text">{t('paymentView.balance')}</div>
                </div>
                <div className="value">
                  <div className="icon">
                    <IconCoin />
                  </div>
                  <div className="text">{token.balance}</div>
                </div>
              </div>
            ) : (
              /* String(process.env.REACT_APP_FIREBASE_ENV) !== 'pepi-test' disabled by emil for internal scandic testing */ true && (
                <div className="phone-container">
                  <div className="phone-input">
                    <h3>{t('paymentView.phoneNumber')}</h3>
                    <PhoneInput
                      international={false}
                      defaultCountry={restaurant?.config?.operatingCountryCode ?? "FI"}
                      placeholder="040 00.."
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                    />
                    <div>
                      <p className="consent">
                        <div className="radio-list">
                          <div className="checkbox">
                            <input
                              id="checkbox-consent"
                              type="checkbox"
                              className="filled-in checkbox-mynt"
                              checked={consent}
                            />
                            <label onClick={() => setConsent(!consent)} htmlFor="checkbox-consent">
                              <Trans i18nKey="paymentView.marketingConsent">
                                I allow {{ company: restaurant.name }} to contact me for marketing purposes
                              </Trans>
                            </label>
                            <p>
                              <Trans i18nKey="paymentView.tosConsent">
                                I accept the{' '}
                                <a href="https://myntapp.io/terms-of-use/" target="_blank" rel="noopener noreferrer">
                                  terms of service
                                </a>
                              </Trans>
                            </p>
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              )
            ))}

          <div className="payment-options">
            <div className="radio payment-option">
              <input
                type="radio"
                name="payment-option"
                id="option-2"
                checked={paymentMethods.find(p => p === 'get_coin')}
                onClick={() => setPaymentMethods(['get_coin'])}
              />
              <label htmlFor="option-2">
                {token?.isPlaceholder ? (
                  <>
                    <h3 className="option-name">{t('paymentView.payNow')}</h3>
                    <div className="option-desc">{t('paymentView.payNowDesc')}</div>
                  </>
                ) : (
                  <>
                    <h3 className="option-name">{t('paymentView.getCoin')}</h3>
                    <div className="option-desc">
                      <Trans i18nKey="paymentView.getCoinDesc">
                        Get {{ percentage: coinRewardPercentage }}% of your order value back as{' '}
                        {{ symbol: token?.symbol }} Coin
                      </Trans>
                    </div>
                    <div className="option-amount">
                      <div className="get-coin">
                        <div className="icon">
                          <IconCoin18 />
                        </div>
                        <div className="text">
                          {token?.symbol} {t('general.coins')} +{asFiat.rewardingCoin}
                        </div>
                      </div>
                      <div className="option-price">{withCurrencySymbol(totalPrice)}</div>
                    </div>
                  </>
                )}
              </label>
            </div>

            {loggedIn && (
              <div className="radio payment-option">
                <input
                  type="radio"
                  name="payment-option"
                  id="option-1"
                  checked={paymentMethods.find(p => p === 'use_coin')}
                  onClick={() => setPaymentMethods(['use_coin'])}
                />
                <label htmlFor="option-1">
                  <h3 className="option-name">{t('paymentView.useCoin')}</h3>
                  <div className="option-desc">
                    <Trans i18nKey="paymentView.useCoinDesc">
                      Use your {{ symbol: token?.symbol }}Coin to pay for your order
                    </Trans>
                  </div>
                  <div className="option-amount">
                    <span>
                      -{asCoin.coin} {token?.symbol} {t('general.coin')}
                    </span>
                    <div className="option-price">{withCurrencySymbol(asCoin.fiat)}</div>
                  </div>
                </label>
              </div>
            )}

            {(restaurant?.config?.acceptedPaymentMethods || []).indexOf('epassi') >= 0 && (
              <div className="radio payment-option payment-option--epassi">
                <img
                  style={{ float: 'right', height: '25px', width: 'auto', marginRight: '30px', marginTop: '-1px' }}
                  src="/img/epassi.png"
                  alt=""
                />
                <input
                  type="radio"
                  name="payment-option"
                  id="option-epassi"
                  checked={paymentMethods.find(p => p === 'epassi')}
                  onClick={() => setPaymentMethods(['epassi'])}
                />
                <label htmlFor="option-epassi">
                  <h3 className="option-name">Use Epassi</h3>
                  <div className="option-desc">
                    {/* Paying with your Smartum Account. Minimum value of order is {LUNCH_LIMITS[0]} and maximum value of order is {LUNCH_LIMITS[1]}.
                                            <br /> */}
                    By Epassi: {asEpassi.inBenefitCard}
                    <br />
                    By Card: {asEpassi.fiat}
                  </div>
                  <div className="option-amount">
                    {/* <div className='option-desc'>
                                              By Smartum: {asSmartum.smartum}
                                              <br />
                                              By Card: {asSmartum.fiat}
                                          </div> */}
                    <div className="get-coin">
                      <div className="icon">
                        <IconCoin18 />
                      </div>
                      <div className="text">
                        {token?.symbol} {t('general.coins')} +{asEpassi.rewardingCoin}
                      </div>
                    </div>
                    <div className="option-price">{withCurrencySymbol(totalPrice)}</div>
                  </div>
                </label>

                <form action={epassiForm.url} method={epassiForm.method} style={{ display: 'none' }}>
                  <input type="hidden" name="STAMP" value={epassiForm.STAMP} />
                  <input type="hidden" name="SITE" value={epassiForm.SITE} />
                  <input type="hidden" name="AMOUNT" value={epassiForm.AMOUNT} />
                  <input type="hidden" name="REJECT" value={epassiForm.REJECT} />
                  <input type="hidden" name="CANCEL" value={epassiForm.CANCEL} />
                  <input type="hidden" name="RETURN" value={epassiForm.RETURN} />
                  <input type="hidden" name="NOTIFY" value={epassiForm.NOTIFY} />
                  <input type="hidden" name="MAC" value={epassiForm.MAC} />
                  <input ref={epassiRef} type="submit" value="Pay by ePassi" />
                </form>
              </div>
            )}

            {(restaurant?.config?.acceptedPaymentMethods || []).indexOf('smartum') >= 0 && (
              <div className="radio payment-option payment-option--smartum">
                <img
                  style={{ float: 'right', height: '15px', width: 'auto', marginRight: '30px', marginTop: '3px' }}
                  src="/img/smartum-logo.svg"
                  alt=""
                />
                <input
                  type="radio"
                  name="payment-option"
                  id="option-3"
                  checked={paymentMethods.find(p => p === 'smartum')}
                  onClick={() => setPaymentMethods(['smartum'])}
                />
                <label htmlFor="option-3">
                  <h3 className="option-name">Use Smartum</h3>
                  <div className="option-desc">
                    {/* Paying with your Smartum Account. Minimum value of order is {LUNCH_LIMITS[0]} and maximum value of order is {LUNCH_LIMITS[1]}.
                                            <br /> */}
                    By Smartum: {asSmartum.inBenefitCard}
                    <br />
                    By Card: {asSmartum.fiat}
                  </div>
                  <div className="option-amount">
                    {/* <div className='option-desc'>
                                              By Smartum: {asSmartum.smartum}
                                              <br />
                                              By Card: {asSmartum.fiat}
                                          </div> */}
                    <div className="get-coin">
                      <div className="icon">
                        <IconCoin18 />
                      </div>
                      <div className="text">
                        {token?.symbol} {t('general.coins')} +{asSmartum.rewardingCoin}
                      </div>
                    </div>
                    <div className="option-price">{withCurrencySymbol(totalPrice)}</div>
                  </div>
                </label>
              </div>
            )}

            {setting?.isCashSupported && (
              <PaymentMethod
                title={t('paymentView.paymentMethods.cash.title')}
                description={t('paymentView.paymentMethods.cash.description')}
                onClick={() => setPaymentMethods(['cash'])}
                selected={paymentMethods.includes('cash')}
              />
            )}
          </div>

          {/* Display Error message if no phone number in input */}
          {errorNophone && (
            <>
              <div className="no-phone-error">{t('paymentView.errorNoPhone')}</div>
            </>
          )}

          {/* <div className='pos2' style={{
            width: 'calc(100% - 30px)',
            height: '50px',
            position: 'fixed',
            bottom: '22px',
          }}>
            <SwipableButton
              onSuccess={onStartPayment}
              text="Swipe to Pay"
              text_unlocked=""
              color='#232988'
            />
          </div> */}
          <div
            className="btn-checkout pos2"
            onClick={!paying ? (alcoholInCart ? onAlcoholPay : onStartPayment) : () => {}}
          >
            <div className="order-info confirm">
              <div className="text ">{paying ? t('paymentView.paying') : t('paymentView.confirmPayment')}</div>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  )
}

export default PaymentView
