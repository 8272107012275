const sv = {
  translation: {
    orderSuccessView: {
      status: {
        error: 'Fel',
        cancelled: 'Beställningen är avbruten',
        archived: 'Tack och njut!',
        inprogress: 'Beställning pågår',
        new: 'Beställning skickad',

        ready: 'Jag har det!',
      },
      error: 'Det gick inte att bearbeta beställningen',
      cancelled: 'Beställningen är avbruten',
      newShort: 'Skickad',
      preOrdered: 'Beställning skickad',
      orderCancelledUnableToFullfil: 'kunde inte acceptera din beställning.',
      orderSentTo: 'Beställning har skickats till ',
      readyDesc: 'Hämta din beställning vid disken',
      orderNumber: 'Beställningsnummer',
      deliveryEst: 'Beräknad färdigställande om',
      yourOrderNumberIs: 'Ditt beställningsnummer är #',
      cancelledShort: 'Avbruten',
      orderCancelled: 'Din beställning skickades, men tyvärr ',
      archivedShort: 'Avslutad',
      ready: 'Din beställning är redo!',
      orderCancelledRefunded: 'Dina pengar återbetalas',
      archived: 'Beställningen är avslutad',
      errorShort: 'Fel',
      new: 'Din beställning lyckades!',
      orderInProgress: 'Vi arbetar med din beställning - ',
      saveReceipt: 'Spara kvitto',
      inprogress: 'Din beställning kommer att vara klar om',
    },
    orderView: {
      dinein: 'Ät här',
      notePlaceholder: 'Lägg till speciella önskemål, allergier etc...',
      reccomendations: 'Rekommendationer',
      addButton: 'Lägg till',
      takeaway: 'Takeaway',
      preorder: 'Förbeställning till rummet',
      roomorder: 'Beställning till rummet',
      tableorder: 'Beställning till bordet',
      roomOrderPlaceholder: 'Rumsnummer',
      tableOrderPlaceholder: 'Bordnummer',
      delivery: 'Leverans',
      consumerName: 'Förnamn (valfritt)',
      consumerNameForced: 'Vänligen skriv ditt namn...',
      gotoCheckout: 'Gå till kassan',
      title: 'Din beställning',
      alcoholError: 'Tyvärr tillåter inte finsk lag utlämning av alkohol 😔',
      couponCode: 'Kupongkod',
      pickTime: 'Välj en tid',
      today: 'Idag',
      tomorrow: 'Imorgon',
      required: 'Obligatoriskt',
    },
    paymentView: {
      phoneNumber: 'Telefonnummer',
      useCoinDesc: 'Använd dina {{symbol}} Coins för att betala din beställning',
      payButtonLoading: 'Betalar...',
      errorNoPhone: 'Vänligen ange ditt telefonnummer',
      balance: 'Saldo',
      marketingConsent:
        'Jag tillåter {{company}} att kontakta mig med specialerbjudanden och annan marknadsföringskommunikation.',
      tosConsent: 'Genom att ange mitt telefonnummer godkänner jag <1>användarvillkoren</1>',
      useCoin: 'Använd coins',
      payButton: 'Bekräfta beställning',
      getCoin: 'Få coins',
      payNow: 'Betala nu',
      payNowDesc: 'Betala med kort / Apple Pay / Google Pay',
      smartum: 'Smartum',
      title: 'Betalning',
      getCoinDesc: 'Få {{percentage}}% av din beställningsvärde tillbaka som {{symbol}} Coin',
      alcoholPopUptitle: 'Åldersbegränsade artiklar',
      alcoholPopUpAgeRestrict:
        'Du håller på att köpa en åldersbegränsad produkt. Var beredd att visa ditt ID till personalen',
      alcoholPopUpNoRefund: 'Vi återbetalar inte ditt köp om du inte kan visa ditt ID till personalen.',
      alcoholPopUpConfirm: 'Var god bekräfta att du lagligt får köpa dessa artiklar.',
      alcoholPopUpButtonYesAllowed: 'Ja, jag får',
      alcoholPopUpButtonCancel: 'Avbryt',
      paying: 'Betalar...',
      confirmPayment: 'Bekräfta betalning',
      paymentMethods: {
        cash: {
          title: 'Betala med kontanter',
          description: 'Betala i kassan',
        },
      },
    },
    menuView: {
      title: 'Meny',
      viewOrder: 'Visa beställning',
      restaurantClosed: 'Stängt idag',
      addToOrder: 'Lägg till i beställningen',
    },
    menuItemView: {
      preOrderChip: 'Endast förbeställning',
      preOrderExplanation: 'Denna rätt kan endast förbeställas, du kan välja önskad leveranstid vid kassan',
      extras: 'Extra:',
      alcoholWarning: 'Observera att alkohol endast får säljas till personer över 18 år. Var beredd att visa ditt ID.',
    },
    general: {
      coins: 'Coins',
      coin: 'Coin',
      update: {
        updateTitle: 'Uppdatering krävs',
        updateText: 'Du använder en äldre version av Mynt, vänligen tryck på knappen nedan för att uppdatera',
        updateButton: 'Uppdatera',
      },
      downloadApp: {
        downloadTitle: 'Endast tillgängligt i Mynt',
        downloadText: 'För att ta del av denna belöning måste du använda Mynt-appen 🙂',
        downloadButton: 'Ladda ner appen',
      },
    },
    couponView: {
      checkingCode: 'Kontrollerar din kod 🤟',
      errorTitle: 'Åh nej 🤨',
      errorText1: 'Något gick fel, kanske har du redan erhållit detta erbjudande',
      errorText2: 'Om du inte har det, starta om Mynt-appen och försök igen',
      successTitle: 'Du har precis fått Myntd!',
      explainerText:
        'När du beställer din mat via Mynt kan du få 10% tillbaka som coins, vi har precis uppgraderat hur du spenderar dina pengar',
      openMenuButton: 'Öppna meny',
      closeButton: 'Stäng',
      td: {
        youGot: 'Du fick',
        coinName: 'TD Coins!',
        bannerText: 'Akseli Herlevi & Iman Gharagozlu',
      },
    },
  },
  Any: 'Valfritt',
  'Not empty': 'Ej tom',
  '1-4 digits': '1-4 siffror',
  'One digit and numbers': 'En siffra och siffror',
  'Two digits and numbers': 'Två siffror och siffror',
}

export default sv
