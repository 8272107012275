import React, { createContext, useContext, useState } from 'react'
import ErrorModal from '../Components/ErrorModal/ErrorModal'

export const ErrorContext = createContext({})

export function ErrorProvider(props) {
  const [title, setTitle] = useState()
  const [description, setDescription] = useState()
  const [onClick, setOnClick] = useState()
  const [buttonText, setButtonText] = useState()
  const [showError, setShowError] = useState(false)

  const info = (title, description) => {
    setTitle(title)
    setDescription(description)
    setShowError(true)
  }

  const error = (title, description, buttonText, onClick) => {
    setTitle(title)
    setDescription(description)
    setOnClick(onClick)
    setButtonText(buttonText)
    setShowError(true)
  }

  return (
    <>
      <ErrorContext.Provider value={{ info, error }} {...props} />
      <ErrorModal
        title={title}
        description={description}
        buttonText={buttonText}
        onClick={onClick}
        showError={showError}
        setShowError={setShowError}
      />
    </>
  )
}

export function useErrorContext() {
  return useContext(ErrorContext)
}
